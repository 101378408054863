import {
  find,
  isEmpty,
  uniq,
  isArray,
  every,
  findIndex,
  cloneDeep,
  isEqual,
  size,
  isUndefined,
  gte,
  gt
} from "lodash-es";
import Fuse from "fuse.js";
import { Optional } from "model/types";
import { isGreaterThan0 } from "./number";

export const addOrReplace = <V>(
  arr: V[],
  item: V,
  condition: (item: V) => boolean
): V[] => {
  if (isEmpty(arr)) return [item];
  const itemToReplace = find<V>(arr, arrItem => condition(arrItem));
  if (!itemToReplace) return [...arr, item];
  return arr.map(arrItem => (condition(arrItem) ? item : arrItem));
};

export const isItemPresent = <V, R>(
  item: V,
  arr: R[],
  areEqual: (item1: V, item2: R) => boolean
): boolean => {
  return arr.some(listItem => areEqual(item, listItem));
};

export const isArrayOfSingleType = (array: unknown[]): boolean =>
  uniq(array.map(element => typeof element)).length === 1;

export const isSingleValueArray = (array: unknown[]): boolean =>
  size(array) === 1;

export const arrayIncludesNestedArrays = (array: unknown[]): boolean =>
  array.some(element => Array.isArray(element));

export const isArrayOf = <T>(
  arr: unknown,
  isOfType: (element: T) => element is T
): arr is T[] => isArray(arr) && every(isOfType);

export const filterListByFuzzySearch = <T>(
  procedureCodeConcepts: T[],
  keys: string[],
  searchText?: string
): T[] => {
  if (searchText === undefined) return procedureCodeConcepts;
  const fuse = new Fuse(procedureCodeConcepts, {
    keys,
    threshold: 0.2
  });
  return fuse.search(searchText).map(a => a.item);
};

export const replaceItem = <V>(
  itemList: V[],
  item: V,
  compareFn = isEqual
): Optional<V[]> => {
  const clonedList = cloneDeep(itemList);
  const indexToReplace = findIndex(itemList, v => compareFn(item, v));
  if (indexToReplace !== -1) {
    clonedList.splice(indexToReplace, 1, item);
  }
  return clonedList;
};

export const replaceOrPushItem = <V>(
  itemList: V[],
  item: V,
  compareFn = isEqual
): Optional<V[]> => {
  const clonedList = cloneDeep(itemList);
  const indexToReplace = findIndex(itemList, v => compareFn(item, v));
  if (indexToReplace !== -1) {
    clonedList.splice(indexToReplace, 1, item);
  } else {
    clonedList.push(item);
  }
  return clonedList;
};

export const listSizeHasReachedMax = <T>(
  values: T[],
  maxElements?: number,
  isGreaterThanOrEqual = true
): boolean => {
  if (isUndefined(maxElements)) return false;
  const compareFn = isGreaterThanOrEqual ? gte : gt;
  return compareFn(size(values), maxElements);
};

export const listSizeIsGreaterThan0 = <T>(values?: T[]): boolean =>
  isGreaterThan0(size(values));
