import { FC } from "@laba/react-common";
import { SvgIcon, SvgIconProps } from "@material-ui/core";
import React from "react";

export const ArticleIcon: FC<SvgIconProps> = props => {
  return (
    <SvgIcon
      {...props}
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.6294 14.5H4.62939V12.5H11.6294M14.6294 10.5H4.62939V8.5H14.6294M14.6294 6.5H4.62939V4.5H14.6294M16.6294 0.5H2.62939C1.51939 0.5 0.629395 1.39 0.629395 2.5V16.5C0.629395 17.0304 0.840108 17.5391 1.21518 17.9142C1.59025 18.2893 2.09896 18.5 2.62939 18.5H16.6294C17.1598 18.5 17.6685 18.2893 18.0436 17.9142C18.4187 17.5391 18.6294 17.0304 18.6294 16.5V2.5C18.6294 1.96957 18.4187 1.46086 18.0436 1.08579C17.6685 0.710714 17.1598 0.5 16.6294 0.5Z" />
    </SvgIcon>
  );
};
