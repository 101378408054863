import { ResourceType, ModelReference } from "model/primitives";
import { getKeyObj, KeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import {
  AccountStatus,
  AccountType,
  BaseAccount,
  Organization,
  Patient as PatientModel
} from "model/resource";

export interface Account extends BaseAccount<ResourceType.Account> {
  payer?: ModelReference<Organization>;
  patient?: ModelReference<PatientModel>;
}

export const AccountKey = getKeyObj<Account>(createHydratedMock<Account>());

interface CreateBaseAccountProps {
  type?: AccountType;
  organization?: Organization;
  name?: string;
  payer?: Organization;
}

export const createBaseAccount = ({
  name,
  organization,
  type,
  payer
}: CreateBaseAccountProps): Account => ({
  resourceType: ResourceType.Account,
  status: AccountStatus.Active,
  name,
  organization,
  payer,
  type
});

export const AccountParamsKey: KeyObj<Account> = getKeyObj(
  createHydratedMock<Account>()
);
