import React from "react";
import { ReactElement } from "@laba/react-common";
import { BaseAutocompleteOption } from "../BaseAutocompleteOption/BaseAutocompleteOption";
import { AutocompleteOptionWrapperProps } from "./types";

export const AutocompleteTitleWithSubtextOptionWrapper = <V,>({
  children,
  option,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled,
  fullWidth,
  isSelectedOption,
  isMobile,
  hasBackgroundColor,
  actionControlComponent,
  withPadding
}: AutocompleteOptionWrapperProps<V>): ReactElement => {
  const { text: titleText, primaryText, subtext, title, value } = option;
  return (
    <BaseAutocompleteOption
      optionValue={value}
      onRemoveSelectedOption={onRemoveSelectedOption}
      removeIconStyle={removeIconStyle}
      disabled={disabled}
      fullWidth={fullWidth}
      isSelectedOption={isSelectedOption}
      hasBackgroundColor={hasBackgroundColor}
      isMobile={isMobile}
      title={title}
      titleText={titleText}
      primaryText={primaryText}
      subtext={subtext}
      actionControlComponent={actionControlComponent}
      withPadding={withPadding}
    >
      {children}
    </BaseAutocompleteOption>
  );
};
