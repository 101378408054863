export enum FiltersHeaderInputGrow {
  Grow1 = "grow1",
  Grow2 = "grow2",
  Grow3 = "grow3",
  Grow4 = "grow4",
  Grow5 = "grow5"
}

export enum FiltersHeaderInputRow {
  Zero = "Zero",
  First = "First",
  Second = "Second",
  Third = "Third",
  Fourth = "Fourth",
  Fifth = "Fifth",
  Sixth = "Sixth"
}
