import { isEmpty, isUndefined, startsWith } from "lodash-es";
import { notEmpty, Optional } from "model/types";

export const joinText = (
  textList?: Optional<string>[],
  separator = " | ",
  upperCaseText = false
): Optional<string> => {
  const filteredList = textList?.filter(notEmpty);
  const str = isEmpty(filteredList) ? undefined : filteredList?.join(separator);
  return upperCaseText ? str?.toUpperCase() : str;
};

export const joinTextNotUndefined = (
  textList?: Optional<string>[],
  separator = " | "
): string => {
  return joinText(textList, separator) ?? "";
};

// German uses commas as decimal separator and points for miles
export const formatPrice = (
  price?: number,
  decimalDigits?: number
): Optional<string> =>
  price?.toLocaleString("de-DE", { minimumFractionDigits: decimalDigits });

export const formatCurrencyString = (
  currency?: string,
  price?: number,
  showSignLeft?: boolean,
  decimalDigits?: number
): Optional<string> => {
  const sign = price ? (price > 0 ? "+ " : price < 0 ? "- " : "") : undefined;
  return joinText(
    [
      showSignLeft ? sign : undefined,
      currency,
      showSignLeft
        ? !isUndefined(price)
          ? formatPrice(Math.abs(price), decimalDigits)
          : " -"
        : formatPrice(price, decimalDigits) ?? " -"
    ],
    " "
  );
};

// Remove any whitespace from text (spaces, tabs or newlines)
export const removeSpacesFromText = (text: string): string =>
  text.replace(/\s/g, "");

const removeRepeatedSpaces = (text: string): string => text.replace(/ +/g, " ");
const removeRepeatedTabs = (text: string): string => text.replace(/\t+/g, "\t");
const removeRepeatedNewLine = (text: string): string =>
  text.replace(/\n+/g, "\n");
const removeRepeatedReturn = (text: string): string =>
  text.replace(/\r+/g, "\r");

export const removeRepeatedFormatCharactersFromText = (
  text: string
): string => {
  const textWithoutRepeatedSpaces = removeRepeatedSpaces(text);
  const textWithoutRepeatedTabs = removeRepeatedTabs(textWithoutRepeatedSpaces);
  const textWithoutRepeatedNewLines = removeRepeatedNewLine(
    textWithoutRepeatedTabs
  );
  return removeRepeatedReturn(textWithoutRepeatedNewLines);
};

export const removeSpacesFromTextOrUndefined = (
  text?: string
): Optional<string> => {
  return text ? removeSpacesFromText(text) : undefined;
};

export const removeSymbolsFromTextExceptPlus = (text: string): string => {
  return text.replace(/[^a-zA-Z0-9+]/g, "");
};

export const isHtmlString = (text?: string): boolean => {
  return startsWith(text?.trim(), "<");
};

export const cleanHtmlAndLineBreaksText = (
  htmlString?: string
): Optional<string> => {
  return htmlString
    ?.replace(/<[^>]*>/g, "") // clean HTML tags
    ?.replace(/\n+/g, " ") // replace line breaks with space
    ?.trim();
};
