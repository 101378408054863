import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  KnownIdentifierSystem,
  Practitioner,
  ResourceType
} from "@laba/nexup-api";
import {
  getDefaultResourceTransform,
  ResourceForm
} from "components/generic/ResourceForm/ResourceForm";
import { tkCP } from "translation/i18n";
import { PractitionerQualificationTabContent } from "components/pages/NexupAdmin/resources/practitioner/PractitionerQualificationTabContent";
import { PractitionerRoleTabContent } from "components/pages/NexupAdmin/resources/practitioner/PractitionerRoleTabContent";
import { PractitionerUserTabContent } from "components/pages/NexupAdmin/resources/practitioner/PractitionerUserTabContent";
import { PractitionerPersonalContent } from "components/pages/NexupAdmin/resources/practitioner/PractitionerPersonalContent";
import {
  getDefaultJsonToModel,
  JsonToModel
} from "components/generic/ResourceAction/ResourceAction";
import {
  useUserHasPractitionerRolePermission,
  useUserHasPractitionerUserPermission
} from "store/workspace/events";

export const practitionerTransform = getDefaultResourceTransform<Practitioner>(
  ResourceType.Practitioner
);
export const practitionerJsonToModel: JsonToModel<Practitioner> =
  getDefaultJsonToModel(draft => {
    draft.federationId = undefined;
    draft.organization = undefined;
    if (draft.personalData) {
      draft.personalData.identifierList =
        draft.personalData.identifierList?.filter(
          x => x?.system !== KnownIdentifierSystem.Nexup
        );
    }
  });
export const tk = tkCP.adminPage[ResourceType.Practitioner];

export const PractitionerForm: FC = () => {
  const { t } = useI18n();

  const hasPractitionerRolePermission = useUserHasPractitionerRolePermission();
  const hasPractitionerUserPermission = useUserHasPractitionerUserPermission();

  const tabs = [
    {
      label: t(tk.tabs.personal),
      content: <PractitionerPersonalContent />
    },
    {
      label: t(tk.tabs.qualification),
      content: <PractitionerQualificationTabContent />
    }
  ];

  if (hasPractitionerRolePermission) {
    tabs.push({
      label: t(tk.tabs.rol),
      content: <PractitionerRoleTabContent />
    });
  }
  if (hasPractitionerUserPermission) {
    tabs.push({
      label: t(tk.tabs.user),
      content: <PractitionerUserTabContent />
    });
  }
  return <ResourceForm tabsConfig={tabs} />;
};
