import {
  ChargeItemStatus,
  ConsumptionGroupWithExtraData,
  CurrentConsumptionGroupWithExtraData
} from "model/resource/finance/consumption/consumption";
import {
  ApiPageRequestResponse,
  ApiRequestFileResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, HttpMethod, ModelId, OpenCode } from "@laba/ts-common";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { Organization } from "model/resource/entities/organization/organization";
import { Code } from "model/resource/entities/codeSystem";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { FinanceStatistics } from "model/resource/finance/common";
import { Invoice } from "model/resource";
import { downloadFileRequest } from "api/file";

const ConsumptionGroupWithExtraDataBasePath = "/consumption-group";

export interface ConsumptionGroupWithExtraDataQueryParams {
  withHealthCarePayerProvider?: boolean;
  withLocation?: boolean;
  withOrganization?: boolean;
  withPatient?: boolean;
  withPerformer?: boolean;
  withPractitioner?: boolean;
  withConsumptionItem?: boolean;
  withProduct?: boolean;
  withEncounter?: boolean;
  withInvoice?: boolean;
  withTransaction?: boolean;
  withProviderAccount?: boolean;
}

export interface CurrentConsumptionGroupWithExtraDataQueryParams
  extends ConsumptionGroupWithExtraDataQueryParams {
  organization?: ModelId;
  patient?: ModelId;
  encounter?: ModelId;
  endDate?: ApiDate;
  startDate?: ApiDate;
}

export interface ConsumptionGroupListWithExtraDataQueryParams
  extends ConsumptionGroupWithExtraDataQueryParams,
    ListQueryParams {
  coveragePaymentStatus?: Code[];
  endDate?: ApiDate;
  invoice?: ModelReference<Invoice>;
  organization?: ModelReference<Organization>;
  patient?: ModelId[];
  patientPaymentStatus?: Code[];
  payer?: ModelReference<Organization>;
  practitioner?: ModelReference<Practitioner>;
  startDate?: ApiDate;
  status?: OpenCode<ChargeItemStatus>[];
  patientPaymentMethod?: string[];
}

export interface ConsumptionGroupStatisticsQueryParams {
  organization?: ModelId;
  debtEndDate?: ApiDate;
  debtStartDate?: ApiDate;
  patient?: ModelId;
  paymentsEndDate?: ApiDate;
  paymentsStartDate?: ApiDate;
  totalEndDate?: ApiDate;
  totalStartDate?: ApiDate;
}

export const getConsumptionGroupStatistics = (
  params?: ConsumptionGroupStatisticsQueryParams
): Promise<ApiRequestResponse<FinanceStatistics>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ConsumptionGroupWithExtraDataBasePath}/statistics`),
    params
  });

export const getCurrentConsumptionGroupWithExtraData = (
  params?: CurrentConsumptionGroupWithExtraDataQueryParams
): Promise<ApiRequestResponse<CurrentConsumptionGroupWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      `${ConsumptionGroupWithExtraDataBasePath}/extra/current`
    ),
    params
  });

export const getConsumptionGroupWithExtraData = (
  id: ModelId,
  params?: ConsumptionGroupWithExtraDataQueryParams
): Promise<ApiRequestResponse<ConsumptionGroupWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ConsumptionGroupWithExtraDataBasePath}/${id}/extra`),
    params
  });

export const getConsumptionGroupListWithExtraData = (
  params?: ConsumptionGroupListWithExtraDataQueryParams
): Promise<ApiPageRequestResponse<ConsumptionGroupWithExtraData>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${ConsumptionGroupWithExtraDataBasePath}/extra`),
    params
  });
};

export const createConsumptionGroupWithExtraData = (
  data: ConsumptionGroupWithExtraData
): Promise<ApiRequestResponse<ConsumptionGroupWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${ConsumptionGroupWithExtraDataBasePath}/extra`),
    data
  });

export const cancelConsumptionGroupWithExtraData = (
  id: ModelId
): Promise<ApiRequestResponse<ConsumptionGroupWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${ConsumptionGroupWithExtraDataBasePath}/${id}/extra/cancel`
    )
  });

export const editConsumptionGroupWithExtraData = (
  data: ConsumptionGroupWithExtraData
): Promise<ApiRequestResponse<ConsumptionGroupWithExtraData>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      `${ConsumptionGroupWithExtraDataBasePath}/${data.consumptionGroup?.id}/extra`
    ),
    data
  });
};

export const calculateConsumptionGroupWithExtraDataPrice = async (
  data: ConsumptionGroupWithExtraData
): Promise<ApiRequestResponse<ConsumptionGroupWithExtraData>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${ConsumptionGroupWithExtraDataBasePath}/extra/price`),
    data
  });
};

export interface ConsumptionGroupBudgetFileParams {
  mailReceivers?: string[];
  phoneReceivers?: string[];
}

export const sendConsumptionGroupBudgetFile = (
  id: ModelId,
  data?: ConsumptionGroupBudgetFileParams
): Promise<ApiRequestResponse<undefined>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(
      `${ConsumptionGroupWithExtraDataBasePath}/${id}/send-budget-file`
    ),
    data
  });

export const getConsumptionGroupBudgetFile = (
  id: ModelId
): Promise<ApiRequestFileResponse> =>
  downloadFileRequest(
    getFrontApiUrl(
      `${ConsumptionGroupWithExtraDataBasePath}/${id}/get-budget-file`
    )
  );
