import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getFullNameFromPerson,
  KnownIdentifierSystem,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  Practitioner,
  PractitionerKey,
  PractitionerListQueryParamsKey,
  PractitionerRoleKey,
  PractitionerRoleListQueryParamsKey,
  QualificationValidationStatus,
  ResourceType,
  User,
  UserKey,
  ValidationStatus
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { isEmpty } from "lodash-es";
import { DateField } from "components/generic/DateField/DateField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { practitionerJsonToModel } from "components/pages/NexupAdmin/resources/practitioner/PractitionerForm";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { userOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { ReferenceManyResourceField } from "components/generic/ReferenceManyResourceField/ReferenceManyResourceField";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { ArrayField, ChipField, SingleFieldList } from "react-admin";
import { useGetPractitionerRoleBasedOnPermissions } from "components/hook/useGetPractitionerRoleBasedOnPermissions";
import {
  useUserHasPractitionerRolePermission,
  useUserHasPractitionerUserPermission
} from "store/workspace/events";

const tk = tkCP.adminPage[ResourceType.Practitioner];

export const PractitionerList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const roleSystem = useGetPractitionerRoleBasedOnPermissions(
    workspaceOrganizationId
  );
  const exportedFileName = useListExportedFileName(t(tk.title));

  const hasPractitionerRolePermission = useUserHasPractitionerRolePermission();
  const hasPractitionerUserPermission = useUserHasPractitionerUserPermission();
  return (
    <List
      resource={ResourceType.Practitioner}
      exportedFileName={exportedFileName}
      jsonToModel={practitionerJsonToModel}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={PractitionerListQueryParamsKey.organization}
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
            alwaysOn
          />
        ) : undefined,
        <EnumSelectorInput
          source={PractitionerListQueryParamsKey.registrationValidationStatus}
          label={t(tk.filters.registrationValidationStatus)}
          enumValue={QualificationValidationStatus}
          alwaysOn
          resettable
        />,
        hasPractitionerUserPermission ? (
          <ReferenceResourceInput<User>
            source={PractitionerListQueryParamsKey.userId}
            label={t(tk.filters.user)}
            resourceType={UserResourceType}
            optionTextKey={userOptionText}
            createPopupTitle={t(tk.fields.user)}
            alwaysOn
          />
        ) : undefined,
        hasPractitionerRolePermission ? (
          <CodeSystemSelectorInput
            source={PractitionerListQueryParamsKey.role}
            label={t(tk.filters.role)}
            codeSystem={roleSystem}
            organizationId={workspaceOrganizationId}
            alwaysOn
            resettable
          />
        ) : undefined,
        <EnumSelectorInput
          source={PractitionerListQueryParamsKey.validationStatus}
          label={t(tk.filters.validationStatus)}
          enumValue={ValidationStatus}
          resettable
        />
      ]}
    >
      <Datagrid>
        <TextField source={PractitionerKey.id} label={t(tk.fields.id)} />
        <FunctionField<Practitioner>
          label={t(tk.fields.name)}
          render={getFullNameFromPerson}
        />
        <DateField
          label={t(tk.fields.birthDate)}
          source={PractitionerKey.personalData.birthDate}
        />
        <FunctionField<Practitioner>
          label={t(tk.fields.identifier)}
          render={record => {
            const identifierList = record?.personalData.identifierList ?? [];
            const dni = identifierList.find(
              i => i.system === KnownIdentifierSystem.Dni
            )?.value;
            if (dni != null) return t(tk.fields.identifierDni, { value: dni });
            const passport = identifierList.find(
              i => i.system === KnownIdentifierSystem.Passport
            )?.value;
            if (passport != null)
              return t(tk.fields.identifierPassport, { value: passport });
            return t(tk.fields.identifierNull);
          }}
        />
        <FunctionField<Practitioner>
          label={t(tk.fields.contact)}
          render={record => {
            const phoneList =
              record?.contactData?.phoneList?.map(value => value.value) ?? [];
            const emailLIst =
              record?.contactData?.emailList?.map(value => value.value) ?? [];
            return [...phoneList, ...emailLIst]
              .filter(value => !isEmpty(value))
              .join(" ");
          }}
        />
        <FunctionField<Practitioner>
          label={t(tk.fields.registration)}
          render={record => {
            const registrationList =
              record?.healthcareProfessionalData?.registrationQualification ??
              [];
            return registrationList
              .map(value => `${value.type} ${value.identifier?.value}`)
              .join(" | ");
          }}
        />
        <ArrayField
          source={PractitionerKey.organization}
          label={t(tk.fields.organization)}
        >
          <SingleFieldList>
            <ChipField source={OrganizationKey.name} />
          </SingleFieldList>
        </ArrayField>
        {hasPractitionerUserPermission && (
          <ReferenceResourceField
            resourceType={UserResourceType}
            label={t(tk.fields.user)}
            source={PractitionerKey.user}
            optionTextKey={UserKey.username}
          />
        )}
        {hasPractitionerRolePermission && (
          <ReferenceManyResourceField
            resourceType={ResourceType.PractitionerRole}
            label={t(tk.fields.role)}
            resourceSource={PractitionerRoleListQueryParamsKey.practitioner}
            extraFilters={{
              [PractitionerRoleListQueryParamsKey.active]: true
            }}
            optionTextKey={PractitionerRoleKey.role}
          />
        )}
      </Datagrid>
    </List>
  );
};
