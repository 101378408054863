import { ResourceType } from "model/primitives";
import { getPractitionerMock } from "model/resource/person";
import {
  KnownProviderAccountTransactionStatus,
  KnownProviderAccountTransactionType,
  ProviderAccountTransaction
} from "../providerAccountTransaction";

export const transactionMock1: ProviderAccountTransaction = {
  resourceType: ResourceType.ProviderAccountTransaction,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "25",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  type: KnownProviderAccountTransactionType.Payment,
  status: KnownProviderAccountTransactionStatus.Active,
  amount: { currency: "dolar", value: 123 },
  details: "Detalle de transaction",
  paymentMethod: "efectivo",
  originalPractitioner: getPractitionerMock(),
  lastEditor: getPractitionerMock(),
  account: { resourceType: ResourceType.ProviderAccount, name: "caja 1" }
};
