import {
  borderMixin,
  Color,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  paddingMixin,
  pxToRem,
  StyleVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { getThemeStyleColor, getThemeStyleState } from "model/themeUtils";

const useCurrencyInputStaticStyles = createStyle(
  (_theme: Theme) => ({
    container: {
      ...flexColumnMixin({ gap: 4 })
    },
    fullWidth: {
      width: "100%"
    },
    staticWidth: {
      width: pxToRem(346)
    },
    select: {
      ...flexItemMixin({ shrink: 0 })
    },
    selectWithChevron: {
      width: pxToRem(92)
    },
    selectWithoutChevron: {
      width: pxToRem(75)
    },
    quantity: {
      ...flexItemMixin({ grow: 1 })
    }
  }),
  "CurrencyInputStatic"
);

const getCurrencyColor = (theme: Theme, props: CurrencyInputStyles): Color => {
  return props.hasError
    ? getThemeStyleColor(theme, StyleVariant.Danger)
    : props.disabled
    ? getThemeStyleState(theme, StyleVariant.Primary).disabled
    : getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      );
};

const useCurrencyInputDynamicClasses = createStyle(
  (theme: Theme) => ({
    rootInput: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center
      })
    },
    separatorContainer: (props: CurrencyInputStyles) => ({
      ...paddingMixin({ topBottom: 5 }),
      ...borderMixin({
        style: "solid",
        color: getCurrencyColor(theme, props),
        side: { topBottom: 1, leftRight: 0 }
      })
    }),
    quantity: (_props: CurrencyInputStyles) => ({
      ...flexItemMixin({ grow: 1 })
    }),
    separator: (props: CurrencyInputStyles) => ({
      ...flexItemMixin({ shrink: 0 }),
      height: pxToRem(28),
      width: 1,
      backgroundColor: getCurrencyColor(theme, props)
    })
  }),
  "CurrencyInputDynamic"
);

interface CurrencyInputStyles {
  fullWidth?: boolean;
  hasError: boolean;
  disabled: boolean;
  hasChevron: boolean;
}

interface CurrencyInputStylesClassNames {
  rootInput?: string;
  container?: string;
  select?: string;
  quantity?: string;
  separator?: string;
  errorText?: string;
  separatorContainer?: string;
}
export const useCurrencyInputStyles = (
  props: CurrencyInputStyles
): CurrencyInputStylesClassNames => {
  const classes = useCurrencyInputStaticStyles();
  const dynamicClasses = useCurrencyInputDynamicClasses(props);
  return {
    container: getClassName(
      classes.container,
      props.fullWidth ? classes.fullWidth : classes.staticWidth
    ),
    rootInput: dynamicClasses.rootInput,
    select: getClassName(
      classes.select,
      props.hasChevron
        ? classes.selectWithChevron
        : classes.selectWithoutChevron
    ),
    quantity: classes.quantity,
    separator: dynamicClasses.separator,
    separatorContainer: dynamicClasses.separatorContainer
  };
};
