import React from "react";
import { FC, getClassName } from "@laba/react-common";
import {
  dateAreEqualExceptMs,
  optionalDateInputToDateTime
} from "@laba/ts-common";
import { getDateText, ResourceDateAndPractitioner } from "./utils";
import { useHeaderCreationOrLastEditDateTextStyle } from "./HeaderCreationOrLastEditDateTextStyle";

interface Props {
  creationData?: ResourceDateAndPractitioner;
  lastEditData?: ResourceDateAndPractitioner;
  showEditTextOnly?: boolean;
  onlyEffectiveData?: boolean;
  className?: string;
}

export const HeaderCreationOrLastEditDateText: FC<Props> = ({
  creationData,
  lastEditData,
  showEditTextOnly,
  className,
  onlyEffectiveData
}) => {
  const classes = useHeaderCreationOrLastEditDateTextStyle();
  const resourceLastEditData = getDateText(lastEditData, onlyEffectiveData);
  const resourceCreationData = getDateText(creationData, onlyEffectiveData);
  const creationDate = optionalDateInputToDateTime(creationData?.date);
  const lastEditDate = optionalDateInputToDateTime(lastEditData?.date);

  const content =
    creationDate && !lastEditDate
      ? resourceCreationData
      : creationDate &&
        lastEditDate &&
        dateAreEqualExceptMs(creationDate, lastEditDate) &&
        !showEditTextOnly &&
        resourceCreationData
      ? resourceCreationData
      : resourceLastEditData;

  return content ? (
    <p className={getClassName(classes.root, className)} title={content}>
      {content}
    </p>
  ) : null;
};
