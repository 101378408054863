import React, { KeyboardEvent, useCallback } from "react";
import {
  FC,
  IconC,
  SizeVariant,
  withMemo,
  stopPropagAndPrevDef,
  KeyboardKeys,
  getClassName,
  TextAlign,
  OnBlurEvent
} from "@laba/react-common";
import {
  InputAdornment,
  TextField,
  FormControl,
  FormHelperText
} from "@material-ui/core";
import { Noop } from "@laba/ts-common";
import { ThemeProvider } from "@material-ui/core/styles";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { useMuiTheme } from "model/useMuiTheme";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon } from "components/icons";
import { isUndefined, size } from "lodash-es";
import {
  commonTextInputStyles,
  useInputAdornmentStyle,
  useInputLabelStyle,
  useNumericStyle,
  useOutlinedInputStyleClasses
} from "./TextInputStyle";

type FocusHandler =
  | OnBlurEvent<HTMLInputElement | HTMLTextAreaElement>
  | undefined;

export enum InputType {
  Text = "text",
  Password = "password",
  Number = "number",
  Telephone = "tel"
}

export enum TextInputAutoCompleteType {
  Off = "off",
  On = "on",
  UserName = "username",
  CurrentPassword = "current-password",
  NewPassword = "new-password",
  Email = "email",
  Telephone = "tel",
  CreditCards = "cc-csc",
  AddressLine1 = "address-line1",
  AddressLine2 = "address-line2",
  AddressLine3 = "address-line3",
  AddressLevel1 = "address-level1",
  AddressLevel2 = "address-level2",
  AddressLevel3 = "address-level3",
  AddressLevel4 = "address-level4",
  StreetAddress = "street-address",
  Country = "country",
  CountryName = "country-name",
  PostalCode = "postal-code",
  Name = "name",
  AdditionalName = "additional-name",
  FamilyName = "family-name",
  GivenName = "given-name",
  HonorificPrefix = "honoric-prefix",
  HonorificSuffix = "honoric-suffix",
  Nickname = "nickname",
  OrganizationTitle = "organization-title",
  Bday = "bday",
  BdayDay = "bday-day",
  BdayMonth = "bday-month",
  BdayYear = "bday-year",
  Sex = "sex",
  OneTimeCode = "one-time-code",
  Organization = "organization",
  CcName = "cc-name",
  CcGivenName = "cc-given-name",
  CcAdditionalName = "cc-additional-name",
  CcFamilyName = "cc-family-name",
  CcNumber = "cc-number",
  CcExp = "cc-exp",
  CcExpMonth = "cc-exp-month",
  CcExpYear = "cc-exp-year",
  CcType = "cc-type",
  TransactionCurrency = "transaction-currency",
  TransactionAmount = "transaction-amount",
  Language = "language",
  Url = "url",
  Photo = "photo",
  TelCountryCode = "tel-country-code",
  TelNational = "tel-national",
  TelAreaCode = "tel-area-code",
  TelLocal = "tel-local",
  TelLocalPrefix = "tel-local-prefix",
  TelLocalSuffix = "tel-local-suffix",
  TelExtension = "tel-extension",
  Impp = "impp"
}

export type TextInputTypeVariant = TypeVariant.Outlined | TypeVariant.Contained;

export interface TextInputProps {
  autoComplete?: TextInputAutoCompleteType;
  className?: string;
  disabled?: boolean;
  EndIcon?: FC | IconC;
  onEndIconClick?: Noop;
  errorText?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  helperText?: string;
  id?: string;
  label?: string;
  maxRows?: number;
  minRows?: number;
  multiline?: boolean;
  name?: string;
  onBlur?: FocusHandler;
  onChange?: (value: string) => void;
  placeholder?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  showHelperOrErrorTextSpace?: boolean;
  type?: InputType;
  value?: string;
  autoFocus?: boolean;
  minValue?: number;
  maxValue?: number;
  step?: number;
  variant?: TextInputTypeVariant;
  disableAllOutlines?: boolean;
  clearable?: boolean;
  clearText?: string;
  disableEnterKeyDefaultAction?: boolean;
  disabledEndIcon?: boolean;
  onEnterKeyPress?: (isEndIconDisabled?: boolean) => void;
  showNumberArrows?: boolean;
  noPadding?: boolean;
  textAlign?: TextAlign;
  showLeftBorder?: boolean;
  showFocusBorder?: boolean;
  charLimit?: number;
}

const numericInputRegex =
  /(^[.0-9-+]*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/;

export const TextInputInt: FC<TextInputProps> = ({
  autoComplete = TextInputAutoCompleteType.OneTimeCode,
  className,
  EndIcon,
  onEndIconClick,
  errorText,
  fullWidth,
  fullHeight = false,
  helperText,
  id,
  label,
  maxRows,
  minRows,
  name,
  onBlur,
  onChange,
  placeholder,
  value,
  minValue,
  maxValue,
  step,
  autoFocus,
  disabled = false,
  multiline = false,
  showError = true,
  showHelperOrErrorText = true,
  showHelperOrErrorTextSpace = false,
  type = InputType.Text,
  variant = TypeVariant.Outlined,
  disableAllOutlines = false,
  clearable = false,
  clearText,
  disableEnterKeyDefaultAction = false,
  disabledEndIcon = false,
  onEnterKeyPress,
  showNumberArrows = true,
  noPadding = false,
  textAlign = TextAlign.Left,
  showLeftBorder = true,
  showFocusBorder,
  charLimit
}) => {
  const hasError = showError && Boolean(errorText);
  const showableHelperText =
    (hasError ? errorText : helperText) ||
    (showHelperOrErrorTextSpace ? " " : "");
  const inputLabelClasses = useInputLabelStyle();
  const outlinedInputClasses = useOutlinedInputStyleClasses({
    disabled,
    multiline,
    fullHeight,
    variant,
    disableAllOutlines,
    noPadding,
    textAlign,
    showLeftBorder
  });
  const inputAdornmentClasses = useInputAdornmentStyle();
  const numericClasses = useNumericStyle();
  const theme = useMuiTheme(StyleVariant.Primary);

  const valueHasReachedCharLimit = size(value) === charLimit;

  const classes = commonTextInputStyles({
    hasError,
    fullWidth,
    valueHasReachedCharLimit
  });

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (
        e.key === KeyboardKeys.Enter &&
        !multiline &&
        disableEnterKeyDefaultAction
      ) {
        stopPropagAndPrevDef(e);
        onEnterKeyPress?.(disabledEndIcon);
      }
    },
    [disableEnterKeyDefaultAction, multiline, disabledEndIcon, onEnterKeyPress]
  );

  const numericProps =
    type === InputType.Number
      ? { min: minValue, max: maxValue, step }
      : undefined;

  const checkIfNumber = useCallback((event: KeyboardEvent) => {
    return !event.key.match(numericInputRegex) && event.preventDefault();
  }, []);

  const sizeInputTextValue = size(value);

  const charLimitText = charLimit
    ? `${sizeInputTextValue}/${charLimit}`
    : undefined;

  const wrappedOnChange = (v: string) => {
    if (!isUndefined(charLimit)) {
      size(v) <= charLimit && onChange?.(v);
      return;
    }
    onChange?.(v);
  };

  return (
    <ThemeProvider theme={theme}>
      <FormControl className={getClassName(classes.container, className)}>
        <TextField
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          className={!showNumberArrows ? numericClasses.input : undefined}
          color="primary"
          disabled={disabled}
          error={hasError || valueHasReachedCharLimit}
          id={id}
          InputLabelProps={{ classes: inputLabelClasses }}
          InputProps={{
            ...(EndIcon && {
              endAdornment: (
                <InputAdornment position="end" classes={inputAdornmentClasses}>
                  {clearable && value && !disabled && (
                    <BaseIconButton
                      title={clearText}
                      Icon={CloseIcon}
                      onClick={() => wrappedOnChange("")}
                      size={SizeVariant.Small}
                    />
                  )}
                  {onEndIconClick ? (
                    <BaseIconButton
                      Icon={EndIcon}
                      onClick={onEndIconClick}
                      disabled={disabledEndIcon}
                    />
                  ) : (
                    <EndIcon />
                  )}
                </InputAdornment>
              )
            }),
            classes: outlinedInputClasses,
            inputProps: { ...numericProps, autoFocus: false }
          }}
          label={label}
          maxRows={maxRows}
          minRows={minRows}
          multiline={multiline}
          name={name}
          onBlur={onBlur}
          onChange={e => wrappedOnChange(e.target.value)}
          onKeyPress={handleEnter}
          onKeyDown={e => type === InputType.Number && checkIfNumber(e)}
          placeholder={placeholder}
          size="small"
          type={type}
          value={value ?? ""}
          variant="outlined"
          focused={showFocusBorder}
        />
        {showHelperOrErrorText && (
          <FormHelperText
            className={classes.formHelperTextContainer}
            margin="dense"
          >
            {showableHelperText && (
              <p className={classes.showableHelperText}>{showableHelperText}</p>
            )}
            {charLimitText && (
              <p className={classes.charsLimit}>{charLimitText}</p>
            )}
          </FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  );
};

export const TextInput = withMemo(TextInputInt);
