import {
  FC,
  getClassName,
  IconC,
  OnBlurEvent,
  ReactElement
} from "@laba/react-common";
import React from "react";
import {
  Autocomplete,
  ListBoxComponentMuiType
} from "components/autocomplete/Autocomplete/Autocomplete";
import { useTagListInputStyles } from "components/tags/TagListInput/useTagListInputStyles";
import { TagInput } from "components/tags/TagInput/TagInput";
import { ClearIcon, TagIcon } from "components/icons";
import { Noop } from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import {
  TagListAutocompleteOptionConfig,
  TagListAutocompleteOptionProps
} from "../utils";

export interface TagListInputProps<V> {
  className?: string;
  onChange?: (val?: V) => void;
  selectedOptions: TagListAutocompleteOptionConfig<V>[];
  options?: TagListAutocompleteOptionConfig<V>[];
  compareValues: (v1: V, v2: V) => boolean;
  openText: string;
  closeText: string;
  loadingText: string;
  noOptionsText: string;
  onClickOnRemoveOption: (option: TagListAutocompleteOptionConfig<V>) => void;
  inputValue?: string;
  setInputValue: (val: string) => void;
  placeholder?: string;
  errorText?: string;
  excludedValues?: V[];
  onClickCustomClickableOption?: Noop;
  customOptionText?: string;
  showCustomOption?: boolean;
  showIcon?: boolean;
  EndIcon?: IconC;
  onBlur?: OnBlurEvent;
  CustomHelperComponent?: ReactElement;
  disabled?: boolean;
  moreCharsText?: string;
}

export const TagListInput = <V,>({
  className,
  onChange,
  selectedOptions,
  options = [],
  compareValues,
  openText,
  closeText,
  loadingText,
  noOptionsText,
  onClickOnRemoveOption,
  inputValue,
  setInputValue,
  placeholder,
  errorText,
  excludedValues,
  customOptionText,
  onClickCustomClickableOption,
  showCustomOption,
  showIcon = true,
  EndIcon = TagIcon,
  onBlur,
  CustomHelperComponent,
  disabled,
  moreCharsText
}: TagListInputProps<V>): ReactElement => {
  const classes = useTagListInputStyles();

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomOption: FC<TagListAutocompleteOptionProps<V>> = ({ option }) => {
    return (
      <TagInput
        text={option.text}
        checked={selectedOptions.some(v =>
          compareValues(v.value, option.value)
        )}
        backgroundColor={option.color}
      />
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListBoxComponent: ListBoxComponentMuiType = props => {
    return <div {...props} className={classes.listBoxComponent} />;
  };

  return (
    <div className={getClassName(classes.root, className)}>
      <Autocomplete<V>
        disabled={disabled}
        value={undefined}
        options={options}
        openText={openText}
        closeText={closeText}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        AutocompleteOptionC={CustomOption}
        inputValue={inputValue}
        onInputChange={(val: string, optionChanged: boolean) =>
          !optionChanged && setInputValue(val)
        }
        ListBoxComponentC={ListBoxComponent}
        disableCloseOnSelect
        withoutOptionClassName
        onChange={onChange}
        EndIcon={showIcon ? EndIcon : undefined}
        endIconShouldNotRotate={showIcon}
        label={placeholder}
        fullWidth
        errorText={errorText}
        excludedValues={excludedValues}
        showCustomOption={showCustomOption}
        customOptionText={customOptionText}
        onClickCustomClickableOption={onClickCustomClickableOption}
        customClickableOptionClassName={classes.customClickableOption}
        onBlur={onBlur}
        moreCharsText={moreCharsText}
      />
      {CustomHelperComponent}
      {!isEmpty(selectedOptions) && (
        <div className={classes.selectedOptionsContainer}>
          {selectedOptions.map(v => {
            return (
              <TagInput
                text={v.text}
                onClick={() => onClickOnRemoveOption(v)}
                uncheckedIcon={ClearIcon}
                backgroundColor={v.color}
                checked={false}
                key={v.text}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
