import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { RequestFailureStatus } from "@laba/ts-common";
import { size } from "lodash-es";

export const getMockedSuccessPaginatedRequest = <T>(
  data: T[],
  delay = 0
): Promise<ApiPageRequestResponse<T>> => {
  const dataLength = size(data);
  return new Promise<ApiPageRequestResponse<T>>(resolve => {
    setTimeout(() => {
      resolve({
        data: {
          entries: data,
          page: {
            pageSize: dataLength,
            pageEntries: 1,
            totalEntries: dataLength,
            page: 1
          }
        },
        failureStatus: RequestFailureStatus.Success,
        status: 200
      });
    }, delay);
  });
};

export const getMockedSuccessRequest = <T>(
  data: T,
  delay = 0
): Promise<ApiRequestResponse<T>> => {
  return new Promise<ApiRequestResponse<T>>(resolve => {
    setTimeout(() => {
      resolve({
        data,
        failureStatus: RequestFailureStatus.Success,
        status: 200
      });
    }, delay);
  });
};
