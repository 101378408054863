import { Theme } from "model/theme";
import {
  createStyle,
  destyledListMixin,
  flexColumnMixin,
  getClassName,
  paddingMixin
} from "@laba/react-common";

interface InfiniteListStylesProps {
  gap: number;
}

interface InfiniteListClassNames {
  list: string;
  accordeonList: string;
}

const useInfiniteListStaticStyle = createStyle(
  (_theme: Theme) => ({
    list: {
      ...destyledListMixin()
    }
  }),
  "InfiniteListStatic"
);

const useInfiniteListDynamicStyle = createStyle(
  (_theme: Theme) => ({
    list: (props: InfiniteListStylesProps) => ({
      ...flexColumnMixin({ gap: props.gap })
    }),
    accordeonList: (props: InfiniteListStylesProps) => ({
      ...flexColumnMixin({ gap: props.gap }),
      ...paddingMixin({ top: props.gap })
    })
  }),
  "InfiniteListDynamic"
);

export const useInfiniteListStyle = (
  props: InfiniteListStylesProps
): InfiniteListClassNames => {
  const { list } = useInfiniteListStaticStyle();
  const { list: listDynamic, accordeonList } =
    useInfiniteListDynamicStyle(props);

  return {
    list: getClassName(list, listDynamic),
    accordeonList
  };
};
