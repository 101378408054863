import { ApiPageRequestResponse, ApiRequestResponse } from "request/types";
import { ModelId } from "model/primitives/model/model";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { HttpMethod } from "@laba/ts-common";
import {
  QuestionnaireResponseListQueryParams,
  QuestionnaireResponseQueryParams
} from "api/evolution/questionnaireResponse";
import { QuestionnaireResponseWithExtraData } from "model/resource/evolution/questionnaireResponseWithExtraData/questionnaireResponseWithExtraData";
import { urlJoin } from "url-join-ts";

const questionnaireResponseBasePath = "/questionnaire-response";
const withExtraDataBasePath = "/extra";

const questionnaireResponseWithExtraDataBasePath = urlJoin(
  questionnaireResponseBasePath,
  withExtraDataBasePath
);

export interface QuestionnaireResponseWithExtraDataQueryParams
  extends QuestionnaireResponseQueryParams {
  withConsumptionWithExtraData?: boolean;
  withConsumptionPayer?: boolean;
  withProduct?: boolean;
  withConsumptionGroupInvoice?: boolean;
  withConsumptionGroupTransaction?: boolean;
  withConsumptionGroupProviderAccount?: boolean;
  withOdontogram?: boolean;
}

export interface QuestionnaireResponseListWithExtraDataQueryParams
  extends QuestionnaireResponseListQueryParams,
    QuestionnaireResponseWithExtraDataQueryParams {}

export const getQuestionnaireResponseWithExtraDataList = (
  params: QuestionnaireResponseListWithExtraDataQueryParams
): Promise<ApiPageRequestResponse<QuestionnaireResponseWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(questionnaireResponseWithExtraDataBasePath),
    params
  });

export const getQuestionnaireResponseWithExtraData = (
  id: ModelId,
  params: QuestionnaireResponseWithExtraDataQueryParams
): Promise<ApiRequestResponse<QuestionnaireResponseWithExtraData>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(questionnaireResponseBasePath, id, withExtraDataBasePath)
    ),
    params
  });

export const createQuestionnaireResponseWithExtraData = (
  data: QuestionnaireResponseWithExtraData
): Promise<ApiRequestResponse<QuestionnaireResponseWithExtraData>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(questionnaireResponseWithExtraDataBasePath),
    data,
    useLongTimeout: false
  });

export const editQuestionnaireResponseWithExtraData = (
  data: QuestionnaireResponseWithExtraData
): Promise<ApiRequestResponse<QuestionnaireResponseWithExtraData>> =>
  request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(
      urlJoin(
        questionnaireResponseBasePath,
        data.questionnaireResponse.id,
        withExtraDataBasePath
      )
    ),
    data,
    useLongTimeout: false
  });

export const saveQuestionnaireResponseWithExtraData = (
  response: QuestionnaireResponseWithExtraData
): Promise<ApiRequestResponse<QuestionnaireResponseWithExtraData>> => {
  if (response.questionnaireResponse.id) {
    return editQuestionnaireResponseWithExtraData(response);
  }
  return createQuestionnaireResponseWithExtraData(response);
};
