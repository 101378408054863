import { Optional } from "@laba/ts-common";
import { isEqual } from "lodash-es";
import { Model, ModelId } from "../model/model";
import { ModelReference } from "./modelReference";

export const referenceIsModel = <T extends Model>(
  modelReference?: ModelReference<T>
): modelReference is T => {
  return typeof modelReference === "object";
};
export const getModelReferenceId = <T extends Model>(
  modelReference?: ModelReference<T>
): ModelId | undefined => {
  if (referenceIsModel(modelReference)) {
    return modelReference.id;
  }
  return modelReference;
};
export const referenceIsModelId = <T extends Model>(
  maybeReference?: ModelReference<T>
): maybeReference is ModelId => {
  return typeof maybeReference === "string";
};
export const modelReferencesAreModel = <T extends Model>(
  modelReferenceArray: ModelReference<T>[]
): modelReferenceArray is T[] => modelReferenceArray.every(referenceIsModel);
/* Serves to prevent you from accessing a field from a ModelId.
 * This allows more comfortable optional chaining of field accesses.
 * Example:
 * foo: ModelReference<Foo>
 * foo?.bar?.maz // Error: property "bar" doesn't exist on type "string"
 * getModelOrUndefined(foo)?.bar?.maz?.mat // OK!
 */
export const getModelOrUndefined = <T extends Model>(
  modelReference?: ModelReference<T>
): Optional<T> => {
  return referenceIsModel(modelReference) ? modelReference : undefined;
};

export const modelIdsAreEqual = <T extends Model>(
  modelReference1?: ModelReference<T>,
  modelReference2?: ModelReference<T>
): boolean =>
  isEqual(
    getModelReferenceId(modelReference1),
    getModelReferenceId(modelReference2)
  );
