import { joinText, Model, notUndefined, Optional } from "@laba/ts-common";
import { head, isEmpty } from "lodash-es";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { getModelOrUndefined } from "model/primitives/modelReference/utils";

const getAmountText = <T>(list: T[]): Optional<string> => {
  const amount = list.length > 1 ? list.length - 1 : 0;
  return amount <= 0 ? undefined : `(+${amount})`;
};

const getText = <T>(
  list: T[],
  getItemText: (item: T) => string
): Optional<string> => {
  const firstItem = head(list);
  if (firstItem === undefined) return;

  const amountText = getAmountText(list);

  return [getItemText(firstItem), amountText].filter(notUndefined).join(" ");
};

export const getResourceListTitleText = <R extends Model>(
  itemList: ModelReference<R>[],
  getItemText: (item: R) => string
): Optional<string> => {
  const modelItemList = itemList.map(getModelOrUndefined).filter(notUndefined);
  return joinText(modelItemList.map(getItemText), "\n");
};

export const getResourceListText = <R extends Model>(
  itemList: ModelReference<R>[],
  getItemText: (item: R) => string
): Optional<string> => {
  const modelItemList = itemList.map(getModelOrUndefined).filter(notUndefined);
  return getText(modelItemList, getItemText);
};

export const getCodeListText = (itemList?: string[]): Optional<string> => {
  if (!itemList || isEmpty(itemList)) return;
  return getText(itemList, item => item);
};

export const getCodeListTitle = (itemList?: string[]): Optional<string> => {
  if (!itemList || isEmpty(itemList)) return;
  return joinText(itemList, "\n");
};
