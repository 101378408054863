import { createStyle, getClassName } from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import { themeTextMixin, getThemeStyleColor } from "model/themeUtils";
import { TextVariant, StyleVariant } from "model/themeVariant";

interface UrlLinkTextStylesProps {
  textVariant: TextVariant;
  disabled: boolean;
  multiline: boolean;
  textUnderline: boolean;
  textStyleVariant?: StyleVariant;
}

interface UrlLinkTextClassNames {
  text: string;
}

const useUrlLinkTextStaticStyles = createStyle(
  (theme: Theme) => ({
    underlined: {
      textDecoration: "underline"
    },
    cursorEnabled: {
      cursor: "pointer"
    },
    cursorDisabled: {
      cursor: "default"
    },
    colorDisabled: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    }
  }),
  "UrlLinkTextStatic"
);

const useUrlLinkTextDynamicStyles = createStyle(
  (theme: Theme) => ({
    text: (props: UrlLinkTextStylesProps) => ({
      ...themeTextMixin(
        theme,
        props.textVariant,
        props.multiline ? undefined : 1
      )
    }),
    colorEnabled: (props: UrlLinkTextStylesProps) => ({
      color: getThemeStyleColor(
        theme,
        props.textStyleVariant ?? StyleVariant.Primary,
        ThemeStyleColorVariant.Main
      )
    })
  }),
  "UrlLinkTextDynamic"
);

export const useUrlLinkTextStyle = (
  props: UrlLinkTextStylesProps
): UrlLinkTextClassNames => {
  const staticClasses = useUrlLinkTextStaticStyles();
  const dynamicClasses = useUrlLinkTextDynamicStyles(props);

  return {
    text: getClassName(
      dynamicClasses.text,
      props.textUnderline ? staticClasses.underlined : undefined,
      props.disabled
        ? staticClasses.colorDisabled
        : dynamicClasses.colorEnabled,
      props.disabled
        ? staticClasses.cursorDisabled
        : staticClasses.cursorEnabled
    )
  };
};
