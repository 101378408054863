import { AxiosError } from "axios";
import {
  RequestFailureResponse,
  RequestFailureStatus,
  RequestSuccessResponse
} from "request/models/request";
import { get } from "lodash-es";

export type DefaultError = unknown;
export type RequestError<T> = AxiosError<T>;

export const isAxiosError = (error: unknown): error is AxiosError => {
  if (typeof error === "object" && error != null) {
    return (error as AxiosError).isAxiosError || false;
  }
  return false;
};

export const isAxiosUnknownRequestError = <T>(
  error: RequestError<T>
): boolean => {
  if (!isAxiosError(error)) return false;
  return error.response?.status === undefined;
};

export const isRequestFailureResponse = <E = DefaultError>(
  response?: unknown
): response is RequestFailureResponse<E> => {
  if (!response || typeof response !== "object") return false;
  const failureStatus = get(response, "failureStatus");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return failureStatus === RequestFailureStatus.Failure;
};

export const isRequestSuccessResponse = <V>(
  response?: unknown
): response is RequestSuccessResponse<V> => {
  if (!response || typeof response !== "object") return false;
  const failureStatus = get(response, "failureStatus");
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return failureStatus === RequestFailureStatus.Success;
};
