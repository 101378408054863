/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  getClassName,
  ReactElement,
  ReactElementOrNull
} from "@laba/react-common";
import { notNull } from "@laba/ts-common";
import { groupBy, isEmpty, isEqual, isNil, size, times } from "lodash-es";
import {
  Accordion as NexupAccordion,
  AccordionSummaryMinHeight
} from "components/accordion";
import { useInfiniteListStyle } from "./InfiniteListStyle";

export enum InfiniteListMode {
  Default = "Default",
  Accordion = "Accordion"
}

export interface InfiniteListProps<T, V = string> {
  children: (item: T, index: number) => ReactElementOrNull | false;
  items: T[];
  loadMore: () => Promise<void> | void;
  totalSize?: number;
  maxLoaderItems?: number;
  LoaderComponent?: ReactElement;
  className?: string;
  gap?: number;
  hasNoResult?: boolean;
  NoResultsPlaceholder?: ReactElement;
  hasErrorLoadingList?: boolean;
  errorPlaceholder?: ReactElement;
  dividerGetter?: (item: T, prevItem?: T) => ReactElementOrNull;
  getItemCategory?: (item?: T) => V;
  listMode?: InfiniteListMode;
}

export const InfiniteList = <T, V = string>({
  className,
  children,
  items,
  totalSize,
  maxLoaderItems = 5,
  LoaderComponent,
  loadMore,
  hasNoResult,
  NoResultsPlaceholder,
  gap = 0,
  hasErrorLoadingList,
  errorPlaceholder,
  dividerGetter,
  getItemCategory,
  listMode = InfiniteListMode.Default
}: InfiniteListProps<T, V>): ReactElementOrNull => {
  const currentListSize = size(items);

  const hasMore = totalSize !== undefined && currentListSize < totalSize;
  const placeholderItemsSize =
    hasMore || totalSize === undefined ? maxLoaderItems : 0;
  const classes = useInfiniteListStyle({ gap });
  const [showingLastElement, setShowingLastElement] = useState(false);
  useEffect(() => {
    const downloadMore = async () => {
      if (hasMore && showingLastElement && currentListSize !== 0) {
        await loadMore();
      }
    };
    downloadMore();
  }, [loadMore, hasMore, showingLastElement, currentListSize]);
  const hasOnlyNullItems =
    totalSize !== undefined && isEmpty(items.filter(notNull));

  const getShowDividerComponent = (item: T, previousItem?: T): boolean => {
    const itemCategory = getItemCategory?.(item);
    const prevItemCategory = getItemCategory?.(previousItem);
    return !isEqual(itemCategory, prevItemCategory);
  };

  const groupedItems = useMemo(
    () => groupBy(items, getItemCategory),
    [items, getItemCategory]
  );

  return (
    <>
      {(hasNoResult || hasOnlyNullItems) && NoResultsPlaceholder}
      {hasErrorLoadingList && errorPlaceholder}
      {!hasNoResult && !hasErrorLoadingList && (
        <ul className={getClassName(classes.list, className)}>
          {isEqual(listMode, InfiniteListMode.Default)
            ? items.map((item, index) => {
                const prevItem = items[index - 1];
                const showDividerComponent = getShowDividerComponent(
                  item,
                  prevItem
                );

                const childrenNode = children(item, index);
                const dividerNode = showDividerComponent
                  ? dividerGetter?.(item, prevItem)
                  : null;
                if (!childrenNode && !dividerNode) return null;
                return (
                  <li key={index}>
                    {dividerNode}
                    {childrenNode}
                  </li>
                );
              })
            : Object.entries(groupedItems).map(([category, groupItems]) => {
                const firstItem = groupItems[0];
                if (!firstItem) return null;

                const groupDivider = dividerGetter?.(firstItem);

                return (
                  <NexupAccordion
                    detailsClassName={classes.accordeonList}
                    summaryChildren={
                      isNil(groupDivider) ? undefined : groupDivider
                    }
                    isSelfControlled
                    key={category}
                    showArrowLeft
                    defaultExpanded
                    hasBackgroundColor
                    withMarginSummary={false}
                    accordionSummaryMinHeightVariant={
                      AccordionSummaryMinHeight.Small
                    }
                  >
                    {groupItems.map((item, index) => {
                      const childrenNode = children(item, index);
                      if (!childrenNode) return null;
                      return <li key={index}>{childrenNode}</li>;
                    })}
                  </NexupAccordion>
                );
              })}
          {hasMore ? (
            <VisibilitySensor
              active
              partialVisibility
              onChange={setShowingLastElement}
            >
              {LoaderComponent ? (
                <>
                  {times(placeholderItemsSize).map(index => (
                    <li key={index}>{LoaderComponent}</li>
                  ))}
                </>
              ) : (
                <div>&nbsp;</div>
              )}
            </VisibilitySensor>
          ) : (
            times(placeholderItemsSize).map(index => (
              <li key={index}>{LoaderComponent}</li>
            ))
          )}
        </ul>
      )}
    </>
  );
};
