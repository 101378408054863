import {
  ApiErrorResponse,
  CodeSystem,
  CodeSystemGroup,
  CodeSystemsRecord,
  CodeSystemSystem,
  getCodeSystemGroupList,
  getModelReferenceId,
  getPractitionerRoleList,
  getWorkspaceList,
  KnownCodeSystemSystem,
  ModelId,
  ModelReference,
  Organization,
  Practitioner,
  PractitionerRole,
  Workspace
} from "@laba/nexup-api";
import { Event } from "store/store";
import {
  getAsArray,
  isArray,
  notUndefined,
  Optional,
  RequestFailureStatus
} from "@laba/ts-common";
import { workspaceSlice } from "store/workspace/slice";
import {
  codeSystemRecordSelector,
  defaultWorkspaceSelector,
  organizationWorkspacesPermissionListSelector,
  practitionerRoleSelector,
  shouldDownloadSystemSelector,
  workspaceListSelector,
  workspaceOrganizationOrDefaultSelector,
  workspaceSelector
} from "store/workspace/selectors";
import { isEmpty } from "lodash-es";
import {
  AdminPanelPermissions,
  AdminPanelSpecialPermissions,
  AdminPanelSpecificPermissions,
  getAdminPanelPermissionsFromPractitionerRoles
} from "models/permissions/adminPanelPermissions";
import { useHasPermission } from "components/hook/UseHasPermission";

const { actions } = workspaceSlice;

export const cleanWorkspaceData = (): Event => async dispatch => {
  dispatch(actions.clean());
};

export const updateWorkspaceList =
  (onError?: (error: ApiErrorResponse) => void): Event =>
  async dispatch => {
    const workspacesResponse = await getWorkspaceList();

    if (workspacesResponse.failureStatus === RequestFailureStatus.Failure) {
      onError?.(workspacesResponse);
      return;
    }
    dispatch(
      actions.updateWorkspaceList({ workspaceList: workspacesResponse.data })
    );
  };

export const onSelectWorkspace =
  (workspace?: Workspace): Event =>
  async dispatch => {
    dispatch(actions.updateCurrentWorkspace({ currentWorkspace: workspace }));
  };

export const onSelectOrganizationWorkspace =
  (organizationId?: ModelId): Event =>
  async (dispatch, getState) => {
    const selectedWorkspace = workspaceListSelector(getState()).find(
      value => value.organization?.id === organizationId
    );
    const defaultWorkspace = defaultWorkspaceSelector(getState());
    const workspace = selectedWorkspace ?? defaultWorkspace;
    if (workspace) {
      await dispatch(onSelectWorkspace(workspace));
    }
  };

export const downloadOrganizationCodeSystemGroup =
  (
    systemStringOrArray: CodeSystemSystem | CodeSystemSystem[],
    organization?: ModelReference<Organization>,
    onError?: (system: CodeSystemSystem[]) => void
  ): Event =>
  async (dispatch, getState) => {
    const workspaceOrganization = workspaceOrganizationOrDefaultSelector(
      getState()
    );
    const organizationId = getModelReferenceId(
      organization || workspaceOrganization
    );
    if (!organizationId) return;

    const systemArray = isArray(systemStringOrArray)
      ? systemStringOrArray
      : [systemStringOrArray];
    systemArray.forEach(s =>
      dispatch(
        actions.initSystemDownload({ system: s, organization: organizationId })
      )
    );

    const codeSystemRequest = await getCodeSystemGroupList({
      organization: organizationId,
      system: systemArray,
      pageSize: systemArray.length
    });

    if (codeSystemRequest.failureStatus === RequestFailureStatus.Failure) {
      onError?.(systemArray);
      systemArray.forEach(system =>
        dispatch(
          actions.setSystemDownloadFailed({
            system,
            organization: organizationId
          })
        )
      );
    } else {
      systemArray.forEach(system => {
        const codeSystem = codeSystemRequest.data.entries.find(
          e => e.system === system
        );
        const codeSystemOrDefault: CodeSystemGroup = codeSystem ?? {
          system,
          concept: []
        };
        dispatch(
          actions.finishSystemDownload({
            system,
            codeSystem: codeSystemOrDefault,
            organization: organizationId
          })
        );
      });
    }
  };

export const updateOrganizationCodeSystem =
  (system: CodeSystem | CodeSystem[]): Event =>
  async dispatch => {
    getAsArray(system).forEach(value => {
      const organization = getModelReferenceId(value.organization);

      organization &&
        dispatch(
          downloadOrganizationCodeSystemGroup(
            getAsArray(system).map(x => x.system),
            organization
          )
        );
    });
  };

export const getOrganizationCodeSystem =
  (
    systemStringOrArray: CodeSystemSystem | CodeSystemSystem[],
    organization?: ModelReference<Organization>
  ): Event<CodeSystemsRecord> =>
  async (dispatch, getState) => {
    const codeSystemList = getAsArray(systemStringOrArray);
    const shouldDownloadSystem = shouldDownloadSystemSelector(getState());
    const neededCodeSystems: CodeSystemSystem[] = codeSystemList.filter(
      system => shouldDownloadSystem(system, organization)
    );
    if (!isEmpty(neededCodeSystems)) {
      await dispatch(
        downloadOrganizationCodeSystemGroup(neededCodeSystems, organization)
      );
    }
    const codeSystemRecord = codeSystemRecordSelector(getState());
    return Object.fromEntries(
      Object.entries(codeSystemRecord(organization)).filter(value =>
        codeSystemList.includes(value[0])
      )
    );
  };

export const downloadPractitionerRoleList =
  (
    practitioner?: ModelReference<Practitioner>,
    organization?: ModelReference<Organization>,
    defaultOrganizationPractitioner?: ModelReference<Practitioner>,
    defaultOrganization?: ModelReference<Organization>
  ): Event<Optional<PractitionerRole[]>> =>
  async (dispatch, getState) => {
    const practitionerId = getModelReferenceId(practitioner);
    const organizationId = getModelReferenceId(organization);
    const defaultOrganizationId = getModelReferenceId(defaultOrganization);
    const defaultOrganizationPractitionerId = getModelReferenceId(
      defaultOrganizationPractitioner
    );
    if (!practitionerId || !organizationId) return;
    const initialRoleListGetter = practitionerRoleSelector(getState());
    if (initialRoleListGetter(practitionerId) === undefined) {
      const response = await getPractitionerRoleList({
        organization: organizationId,
        practitioner: practitionerId,
        pageSize: 100
      });
      if (response.failureStatus === RequestFailureStatus.Success) {
        const practitionerRoleList = response.data.entries;
        // This supposes that default Organization is nexup which is not guaranteed
        if (
          defaultOrganizationId != null &&
          defaultOrganizationPractitionerId != null &&
          defaultOrganizationId !== organizationId
        ) {
          const defaultResponse = await getPractitionerRoleList({
            organization: defaultOrganizationId,
            practitioner: defaultOrganizationPractitionerId,
            pageSize: 100
          });
          if (defaultResponse.failureStatus === RequestFailureStatus.Success) {
            practitionerRoleList.push(...defaultResponse.data.entries);
          }
        }
        dispatch(
          actions.updatePractitionerRole({
            practitionerId,
            practitionerRoleList
          })
        );
      }
    }
    const roleListGetter = practitionerRoleSelector(getState());
    return roleListGetter(practitionerId);
  };

export const getAdminPanelPermissions =
  (): Event<Optional<AdminPanelPermissions[]>> =>
  async (dispatch, getState) => {
    const workspace = workspaceSelector(getState());
    const defaultWorkspace = defaultWorkspaceSelector(getState());
    if (!workspace) return;

    const codeSystemRecord = await dispatch(
      getOrganizationCodeSystem(
        KnownCodeSystemSystem.PractitionerRole,
        workspace.organization
      )
    );
    const practitionerRoleSystem =
      codeSystemRecord[KnownCodeSystemSystem.PractitionerRole]?.system;
    if (!practitionerRoleSystem) return [];

    const practitionerRoleList = await dispatch(
      downloadPractitionerRoleList(
        workspace.practitioner,
        workspace.organization,
        defaultWorkspace?.practitioner,
        defaultWorkspace?.organization
      )
    );
    const practitionerRoles =
      practitionerRoleList
        ?.map(practitionerRole => practitionerRole.role)
        .filter(notUndefined) ?? [];

    return getAdminPanelPermissionsFromPractitionerRoles(
      practitionerRoles,
      practitionerRoleSystem as CodeSystem
    );
  };

export const userHasAdminPermission =
  (): Event<boolean> => async (_dispatch, getState) => {
    const workspacePermissionList =
      organizationWorkspacesPermissionListSelector(getState());

    return (
      workspacePermissionList.find(
        p => p === AdminPanelSpecialPermissions.Admin
      ) !== undefined
    );
  };

export const useUserHasPractitionerUserPermission = (): boolean => {
  return useHasPermission(AdminPanelSpecificPermissions.PractitionerUser);
};

export const useUserHasPractitionerRolePermission = (): boolean => {
  return useHasPermission(
    AdminPanelSpecificPermissions.PractitionerPractitionerRole
  );
};
