import React from "react";
import {
  Children,
  FC,
  FCC,
  getClassName,
  IconC,
  ReactElement,
  stopPropagAndPrevDef
} from "@laba/react-common";
import { Noop, StyleVariant, TextVariant } from "@laba/ts-common";
import { MenuItem, Popover } from "@material-ui/core";
import {
  MenuItemHeight,
  useMenuItemStyle,
  useMenuItemTextStyle,
  usePaperStyle
} from "../MenuStyle";
import { MenuDefaultOption } from "./MenuDefaultOption/MenuDefaultOption";

export interface MenuItemConfig<V> {
  itemId: string;
  text: string;
  title?: string;
  value: V;
  Icon?: IconC;
  disabled?: boolean;
  color?: string;
  pendingActions?: number;
}

export enum HorizontalPosition {
  Left = "left",
  Center = "center",
  Right = "right"
}

export enum VerticalPosition {
  Bottom = "bottom",
  Center = "center",
  Top = "top"
}

export interface MenuOptionProps<V> {
  text: string;
  textClassName?: string;
  value?: V;
  Icon?: IconC;
  color?: string;
  pendingActions?: number;
}

export interface MenuProps<V> {
  anchorOriginHorizontal?: HorizontalPosition | number;
  anchorOriginVertical?: VerticalPosition | number;
  className?: string;
  menuItems: MenuItemConfig<V>[];
  noOptionsText?: string;
  onOptionSelected?: (itemId: V) => void;
  selectedItem?: V;
  selectedItemTextVariant?: TextVariant;
  selectedItemStyleVariant?: StyleVariant;
  transformOriginHorizontal?: HorizontalPosition | number;
  transformOriginVertical?: VerticalPosition | number;
  width?: number;
  id?: string;
  anchorEl?: HTMLElement;
  open?: boolean;
  onClose?: Noop;
  onMouseLeave?: React.MouseEventHandler<HTMLElement>;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  disableRestoreFocus?: boolean;
  CustomOption?: FC<MenuOptionProps<V>>;
  popoverPapperClassName?: string;
  children?: Children;
  allowOverflow?: boolean;
  multiline?: boolean;
  MenuBoxComponentC?: FCC;
  itemWithoutPadding?: boolean;
  fullWidthItem?: boolean;
  itemHeight?: MenuItemHeight;
}

const DefaultMenuBoxComponent: FCC = ({ children }) => {
  return <div>{children}</div>;
};

export const Menu = <V,>({
  className,
  popoverPapperClassName,
  CustomOption = MenuDefaultOption,
  onOptionSelected,
  selectedItem,
  selectedItemTextVariant,
  selectedItemStyleVariant,
  width,
  anchorOriginHorizontal = HorizontalPosition.Center,
  anchorOriginVertical = VerticalPosition.Bottom,
  menuItems = [],
  noOptionsText = "",
  open = false,
  transformOriginHorizontal = HorizontalPosition.Center,
  transformOriginVertical = VerticalPosition.Top,
  children,
  allowOverflow = false,
  multiline = false,
  MenuBoxComponentC = DefaultMenuBoxComponent,
  itemHeight,
  fullWidthItem,
  itemWithoutPadding,
  ...rest
}: MenuProps<V>): ReactElement => {
  const isSelectedItem = (option: V): boolean => {
    return option === selectedItem;
  };
  const paperClasses = usePaperStyle({
    width,
    allowOverflow
  });
  const menuItemClasses = useMenuItemStyle({
    height: itemHeight,
    fullWidth: fullWidthItem,
    withoutPadding: itemWithoutPadding
  });
  const menuItemTextClasses = useMenuItemTextStyle({
    multiline,
    selectedItemTextVariant,
    selectedItemStyleVariant
  });
  return (
    <Popover
      {...rest}
      className={className}
      anchorOrigin={{
        horizontal: anchorOriginHorizontal,
        vertical: anchorOriginVertical
      }}
      open={open}
      PaperProps={{ classes: paperClasses, className: popoverPapperClassName }}
      transformOrigin={{
        horizontal: transformOriginHorizontal,
        vertical: transformOriginVertical
      }}
      onClick={stopPropagAndPrevDef}
    >
      {children}
      <MenuBoxComponentC>
        {menuItems.length > 0 ? (
          menuItems.map(menuItem => (
            <MenuItem
              className={getClassName(
                menuItemClasses.root,
                isSelectedItem(menuItem.value)
                  ? menuItemTextClasses.selectedItem
                  : undefined
              )}
              key={menuItem.itemId}
              onClick={() => {
                onOptionSelected?.(menuItem.value);
                rest.onClose?.();
              }}
              title={menuItem.title ?? menuItem.text}
              disabled={menuItem.disabled}
            >
              <CustomOption
                text={menuItem.text}
                value={menuItem.value}
                textClassName={
                  isSelectedItem(menuItem.value)
                    ? menuItemTextClasses.selectedItem
                    : menuItemTextClasses.text
                }
                Icon={menuItem.Icon}
                color={menuItem.color}
                pendingActions={menuItem.pendingActions}
              />
            </MenuItem>
          ))
        ) : (
          <MenuItem
            classes={menuItemClasses}
            onClick={() => {
              rest.onClose?.();
            }}
            title={noOptionsText}
          >
            <span className={menuItemTextClasses.text}>{noOptionsText}</span>
          </MenuItem>
        )}
      </MenuBoxComponentC>
    </Popover>
  );
};
