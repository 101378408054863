import React, { useMemo, useRef } from "react";
import {
  FC,
  FCC,
  getClassName,
  IconC,
  ReactElement,
  SizeVariant,
  useClientRect,
  withMemo
} from "@laba/react-common";
import { ButtonBase, FormControl, FormHelperText } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import {
  HorizontalPosition,
  Menu,
  MenuItemConfig,
  MenuOptionProps
} from "components/menu/Menu/Menu";
import { Noop, Optional } from "@laba/ts-common";
import { useMuiTheme } from "model/useMuiTheme";
import {
  bindPopover,
  bindTrigger,
  usePopupState
} from "material-ui-popup-state/hooks";
import { StyleVariant, TextVariant, TypeVariant } from "model/themeVariant";
import { BaseIconButton } from "components/buttons/BaseIconButton/BaseIconButton";
import { CloseIcon } from "components/icons";
import { differenceWith, isEmpty, isEqual } from "lodash-es";
import { MenuItemHeight } from "components/menu/MenuStyle";
import {
  useFormControlStyleClasses,
  useFormHelperTextStyle,
  useMenuStyle,
  useSelectInputStyleClasses
} from "./SelectInputStyle";

export type OptionsConfig<V> = MenuItemConfig<V>;

export type SelectInputTypeVariant =
  | TypeVariant.Outlined
  | TypeVariant.Contained;

export interface SelectInputProps<V> {
  className?: string;
  menuClassName?: string;
  disabled?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  noOptionsText?: string;
  onBlur?: Noop;
  onChange?: (value?: V) => void;
  options: OptionsConfig<V>[];
  placeholder?: string;
  popupId?: string;
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  StartIcon?: IconC;
  startIconColor?: string;
  type?: SelectInputTypeVariant;
  value?: V;
  compareValues?: (val1: V, val2: V) => boolean;
  clearValue?: V;
  clearable?: boolean;
  clearText?: string;
  openText?: string;
  closeText?: string;
  excludedValues?: V[];
  getOptionFromValue?: (value: V) => Optional<OptionsConfig<V>>;
  CustomOption?: FC<MenuOptionProps<V>>;
  allowOptionsOverflow?: boolean;
  showRightBorder?: boolean;
  textVariant?: TextVariant;
  allowMultiline?: boolean;
  isMobile?: boolean;
  SelectListBoxComponent?: FCC;
  optionsContainerClassName?: string;
  itemWithoutPadding?: boolean;
  fullWidthItem?: boolean;
  itemHeight?: MenuItemHeight;
  keepOpenOnSelect?: boolean;
  showChevron?: boolean;
}

const SelectInputInt = <V,>({
  className,
  menuClassName,
  errorText,
  helperText,
  id,
  noOptionsText,
  onBlur,
  onChange,
  placeholder,
  StartIcon,
  value,
  clearValue,
  openText,
  closeText,
  clearText,
  getOptionFromValue,
  clearable = false,
  compareValues = (v1: V, v2: V) => v1 === v2,
  disabled = false,
  fullWidth = false,
  options = [],
  popupId = "selectInput",
  showError = true,
  showHelperOrErrorText = true,
  type = TypeVariant.Outlined,
  excludedValues = [],
  CustomOption,
  allowOptionsOverflow,
  showRightBorder = true,
  textVariant = TextVariant.Body2,
  allowMultiline,
  isMobile,
  startIconColor,
  SelectListBoxComponent,
  optionsContainerClassName,
  itemWithoutPadding = false,
  fullWidthItem = true,
  itemHeight = MenuItemHeight.Normal,
  keepOpenOnSelect = false,
  showChevron = true
}: SelectInputProps<V>): ReactElement => {
  const [buttonRect, buttonRef] = useClientRect<HTMLButtonElement>();
  const buttonSize = buttonRect ? buttonRect.width : 0;
  const popupState = usePopupState({
    variant: "popover",
    popupId
  });
  const hasError = showError && Boolean(errorText);
  const hasValue = Boolean(value);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const { isOpen } = popupState;

  const shouldClosePopoverRef = useRef(true);

  const handleChange = (val: V) => {
    const option = options.find(elem => compareValues(elem.value, val));
    if (option !== undefined) {
      onChange?.(option.value);
      if (keepOpenOnSelect) {
        shouldClosePopoverRef.current = false;
      }
    }
  };
  const getOptionText = (val: V): Optional<string> => {
    const option = options.find(elem => compareValues(elem.value, val));
    return option?.text ?? getOptionFromValue?.(val)?.text;
  };
  const textToShow = value ? getOptionText(value) : placeholder;
  const selectInputClassesProps = {
    disabled,
    fullWidth,
    hasError,
    isOpen,
    type,
    clearable,
    hasValue,
    showRightBorder,
    textVariant,
    startIconColor
  };
  const formControlClasses = useFormControlStyleClasses(
    selectInputClassesProps
  );
  const selectInputClasses = useSelectInputStyleClasses(
    selectInputClassesProps
  );
  const menuClasses = useMenuStyle();
  const formHelperTextClasses = useFormHelperTextStyle();
  const theme = useMuiTheme(StyleVariant.Primary);
  const { onClose: bindOnClose, ...bindProps } = bindPopover(popupState);

  const filteredOptions = useMemo(() => {
    if (!isEmpty(excludedValues)) {
      return differenceWith(options, excludedValues, (v1, v2) =>
        isEqual(v1.value, v2)
      );
    }
    return options;
  }, [excludedValues, options]);

  return (
    <ThemeProvider theme={theme}>
      <FormControl
        className={getClassName(className, formControlClasses.root)}
        disabled={disabled}
        error={hasError}
        id={id}
        variant="outlined"
      >
        <ButtonBase
          {...bindTrigger(popupState)}
          className={selectInputClasses.container}
          disabled={disabled}
          ref={buttonRef}
          title={isOpen ? closeText : openText}
        >
          {StartIcon && (
            <StartIcon className={getClassName(selectInputClasses.startIcon)} />
          )}
          <span title={textToShow} className={selectInputClasses.text}>
            {textToShow}
          </span>
          {showChevron ? (
            isOpen ? (
              <ExpandLess className={selectInputClasses.endIcon} />
            ) : (
              <ExpandMore className={selectInputClasses.endIcon} />
            )
          ) : null}
        </ButtonBase>
        {clearable && !disabled && value !== clearValue && (
          <BaseIconButton
            className={selectInputClasses.clearButton}
            title={clearText}
            Icon={CloseIcon}
            onClick={() => onChange?.(clearValue)}
            size={SizeVariant.Small}
          />
        )}
        <Menu
          className={getClassName(menuClassName, menuClasses.menu)}
          menuItems={filteredOptions}
          noOptionsText={noOptionsText}
          onOptionSelected={handleChange}
          selectedItem={value}
          width={buttonSize}
          itemWithoutPadding={itemWithoutPadding}
          fullWidthItem={fullWidthItem}
          itemHeight={itemHeight}
          allowOverflow={allowOptionsOverflow}
          multiline={allowMultiline}
          MenuBoxComponentC={SelectListBoxComponent}
          popoverPapperClassName={optionsContainerClassName}
          {...bindProps}
          onClose={() => {
            if (shouldClosePopoverRef.current) {
              onBlur?.();
              bindOnClose();
            }
            shouldClosePopoverRef.current = true;
          }}
          CustomOption={CustomOption}
          transformOriginHorizontal={
            !isMobile ? HorizontalPosition.Left : undefined
          }
          anchorOriginHorizontal={
            !isMobile ? HorizontalPosition.Left : undefined
          }
        />
        {showHelperOrErrorText && (
          <FormHelperText classes={formHelperTextClasses} margin="dense">
            {showableHelperText}
          </FormHelperText>
        )}
      </FormControl>
    </ThemeProvider>
  );
};

export const SelectInput = withMemo(SelectInputInt) as typeof SelectInputInt;
