import {
  borderMixin,
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";
import {
  getThemeHeaderRadius,
  getThemeStyleColor,
  themeTextMixin
} from "model/themeUtils";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";
import { CardVariant } from "components/card/cardVariants";
import { isUndefined } from "lodash-es";

export interface CardIconStyleProps {
  style: StyleVariant;
  cardVariant?: CardVariant;
}

const generatedStyleKeyTemplate = (style: StyleVariant) =>
  `generatedStyle${style}`;

interface MuiIconsClassNames {
  root?: string;
}

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(StyleVariant).forEach(styleVariant => {
    classNameObject[generatedStyleKeyTemplate(styleVariant)] = {
      color: getThemeStyleColor(theme, styleVariant)
    };
  });
  return classNameObject;
};

const useBaseStyle = createStyle(
  (theme: Theme) => ({
    ...generateDynamicStylesClassNames(theme)
  }),
  "CardBase"
);

const useMuiLeftIconCardBaseStaticStyle = createStyle(
  (_theme: Theme) => ({
    rootStaticVertical: {
      width: pxToRem(40),
      height: pxToRem(40),
      ...marginMixin({
        left: 0,
        right: 12
      })
    },
    rootStaticHorizontal: {
      width: pxToRem(40),
      height: pxToRem(40),
      ...marginMixin({
        left: 8,
        right: 4
      })
    }
  }),
  "MuiLeftIconCardBaseStatic"
);

export const useMuiLeftIconStyleClasses = (
  props: CardIconStyleProps
): MuiIconsClassNames => {
  const classes = useBaseStyle();
  const staticClasses = useMuiLeftIconCardBaseStaticStyle();

  return {
    root: getClassName(
      classes[generatedStyleKeyTemplate(props.style)],
      props.cardVariant === CardVariant.Horizontal
        ? staticClasses.rootStaticHorizontal
        : staticClasses.rootStaticVertical
    )
  };
};

const useMuiRightIconCardBaseStaticStyle = createStyle(
  (_theme: Theme) => ({
    root: {
      width: pxToRem(24),
      height: pxToRem(24)
    }
  }),
  "MuiRightIconCardBaseStatic"
);

export const useMuiRightIconStyleClasses = (
  props: CardIconStyleProps
): MuiIconsClassNames => {
  const classes = useBaseStyle();
  const staticClasses = useMuiRightIconCardBaseStaticStyle();

  return {
    root: getClassName(
      classes[generatedStyleKeyTemplate(props.style)],
      staticClasses.root
    )
  };
};

export interface GenericCardHeaderStyleProps {
  cardVariant: CardVariant;
  headerBorderColor?: string;
  style: StyleVariant;
  fullWidth?: boolean;
  headerTitleTextVariant?: TextVariant;
  isMobile?: boolean;
}

interface CardGenericHeaderClassNames {
  root?: string;
  header?: string;
  headerColor?: string;
  headerTitle?: string;
  rightIconContainer?: string;
  textsAndIconsWrapper?: string;
  textsWrapper?: string;
  titleAndTags: string;
  dataWrapper: string;
}

const generateCardGenericHeaderDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(StyleVariant).forEach(styleVariant => {
    classNameObject[generatedStyleKeyTemplate(styleVariant)] = {
      color: getThemeStyleColor(
        theme,
        styleVariant,
        ThemeStyleColorVariant.GrayDark
      )
    };
  });
  return classNameObject;
};

const useCardGenericHeaderStyle = createStyle(
  (theme: Theme) => ({
    ...generateCardGenericHeaderDynamicStylesClassNames(theme)
  }),
  "CardBase"
);

const useCardGenericHeaderBaseStaticStyle = createStyle(
  (theme: Theme) => ({
    textsAndIconsWrapper: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.FlexStart,
        gap: 16
      }),
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    },
    textsWrapper: { ...flexColumnMixin({ gap: 4 }) },
    header: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Contrast
      )
    },
    headerStaticHorizontal: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...paddingMixin({
        right: 16,
        top: 16,
        bottom: 16,
        left: 24
      }),
      width: "100%",
      minHeight: pxToRem(64)
    },
    headerMobilePadding: {
      ...paddingMixin({
        right: 16,
        top: 8,
        bottom: 8,
        left: 16
      })
    },
    headerStaticVertical: {
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        justifyContent: FlexJustifyContent.FlexStart
      }),
      ...paddingMixin({
        right: 16
      }),
      width: "100%",
      minHeight: pxToRem(64)
    },
    rightIconContainer: {
      ...flexRowMixin({
        gap: 18
      }),
      ...flexItemMixin({ shrink: 0 }),
      ...marginMixin({
        left: "auto"
      })
    },
    titleAndTags: {
      ...flexRowMixin({ gap: 8 }),
      ...flexItemMixin({ grow: 1, shrink: 1, basis: "0%" })
    },
    dataWrapper: {
      ...flexColumnMixin({ gap: 8 }),
      width: "100%"
    }
  }),
  "CardGenericHeaderBaseStatic"
);

const useCardGenericHeaderBaseDynamicStyle = createStyle(
  (theme: Theme) => ({
    headerBorder: (props: GenericCardHeaderStyleProps) => ({
      ...borderMixin({
        style: "solid",
        color: props.headerBorderColor,
        side: { bottom: 0, top: 0, right: 0, left: 8 },
        radius: { top: getThemeHeaderRadius(theme) }
      })
    }),
    root: (props: GenericCardHeaderStyleProps) => ({
      ...flexColumnMixin({}),
      width: props.fullWidth ? "100%" : undefined
    }),
    headerTitle: (props: GenericCardHeaderStyleProps) => ({
      ...themeTextMixin(theme, props.headerTitleTextVariant ?? TextVariant.H6)
    })
  }),
  "CardGenericHeaderBaseDynamic"
);

export const useCardGenericHeaderStyleClasses = (
  props: GenericCardHeaderStyleProps
): CardGenericHeaderClassNames => {
  const classes = useCardGenericHeaderStyle();
  const staticClasses = useCardGenericHeaderBaseStaticStyle();
  const dynamicClasses = useCardGenericHeaderBaseDynamicStyle(props);

  const { cardVariant, style, headerBorderColor, isMobile } = props;

  return {
    header: getClassName(
      staticClasses.header,
      getClassName(
        cardVariant === CardVariant.Horizontal
          ? staticClasses.headerStaticHorizontal
          : staticClasses.headerStaticVertical,
        !isUndefined(headerBorderColor)
          ? dynamicClasses.headerBorder
          : undefined
      ),
      isMobile ? staticClasses.headerMobilePadding : undefined
    ),
    headerTitle: getClassName(
      classes[generatedStyleKeyTemplate(style)],
      dynamicClasses.headerTitle
    ),
    textsWrapper: staticClasses.textsWrapper,
    rightIconContainer: staticClasses.rightIconContainer,
    root: dynamicClasses.root,
    textsAndIconsWrapper: staticClasses.textsAndIconsWrapper,
    titleAndTags: staticClasses.titleAndTags,
    dataWrapper: staticClasses.dataWrapper
  };
};
