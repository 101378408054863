import React from "react";
import { FC, ReactElement, useI18n } from "@laba/react-common";
import {
  CodeSystemGroup,
  MDRProvidedByPatientFieldPropertyTypeList,
  MedicalRequestDefinition,
  MedicalRequestDefinitionFieldType,
  MedicationGenericType,
  ModelId,
  MRDCheckboxFieldPropertyTypeList,
  MRDCheckboxListFieldPropertyTypeList,
  MRDDateFieldPropertyTypeList,
  MRDDateTimeFieldPropertyTypeList,
  MRDDeviceSelectorFieldPropertyTypeList,
  MRDDiagnosticSelectorFieldPropertyTypeList,
  MRDDilutionSelectorFieldPropertyTypeList,
  MRDDoseSelectorFieldPropertyTypeList,
  MRDDurationSelectorFieldPropertyTypeList,
  MRDDurationSelectorFieldPropertyTypeTypes,
  MRDFieldKey,
  MRDFieldPropertyBase,
  MRDFieldPropertyKey,
  MRDFieldPropertyType,
  MRDFrequencySelectorFieldPropertyTypeList,
  MRDInsulineTableFieldPropertyTypeList,
  MRDMedicationListSelectorFieldPropertyTypeList,
  MRDMedicationPresentationSelectorFieldPropertyTypeList,
  MRDMedicationSelectorFieldPropertyTypeList,
  MRDMedicationSelectorFieldPropertyTypeTypes,
  MRDNumericFieldPropertyTypeList,
  MRDObservationsFieldPropertyTypeList,
  MRDPhpMedicationSelectorFieldPropertyTypeList,
  MRDPrescriptionListFieldPropertyTypeList,
  MRDProcedureReportFieldPropertyTypeList,
  MRDPropertyTypeTypes,
  MRDRateSelectorFieldPropertyTypeList,
  MRDRouteSelectorFieldPropertyTypeList,
  MRDSearchableListFieldPropertyTypeList,
  MRDSelectorFieldPropertyTypeList,
  MRDTextFieldPropertyTypeList,
  ResourceType
} from "@laba/nexup-api";
import { getBooleanFromStrOrUndefined } from "@laba/ts-common";
import { tkCP } from "translation/i18n";
import { TextInput } from "components/generic/TextInput/TextInput";
import { ArrayInput } from "components/generic/ArrayInput/ArrayInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";
import { NumberInput } from "components/generic/NumberInput/TextInput";
import { FormDataConsumer } from "components/generic/FormDataConsumer/FormDataConsumer";
import { CodeSystemSelectorInput } from "components/generic/CodeSystemSelectorInput/CodeSystemSelectorInput";

export const tk = tkCP.adminPage[ResourceType.MedicalRequestDefinition];

const getFieldTypePropertyTypeList = (
  fieldType?: MedicalRequestDefinitionFieldType
): MRDFieldPropertyType[] => {
  switch (fieldType) {
    case MedicalRequestDefinitionFieldType.Numeric:
      return MRDNumericFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.Selector:
      return MRDSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.Checkbox:
      return MRDCheckboxFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.CheckboxList:
      return MRDCheckboxListFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.Date:
      return MRDDateFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DateTime:
      return MRDDateTimeFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.Text:
      return MRDTextFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.SearchableList:
      return MRDSearchableListFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DeviceSelector:
      return MRDDeviceSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.MedicationSelector:
      return MRDMedicationSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.MedicationPresentationSelector:
      return MRDMedicationPresentationSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DoseSelector:
      return MRDDoseSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.RouteSelector:
      return MRDRouteSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.FrequencySelector:
      return MRDFrequencySelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DilutionSelector:
      return MRDDilutionSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.RateSelector:
      return MRDRateSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DurationSelector:
      return MRDDurationSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.ProvidedByPatient:
      return MDRProvidedByPatientFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.Observations:
      return MRDObservationsFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.MedicationListSelector:
      return MRDMedicationListSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.PhpMedicationSelector:
      return MRDPhpMedicationSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.InsulineTable:
      return MRDInsulineTableFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.PrescriptionList:
      return MRDPrescriptionListFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.DiagnosticSelector:
      return MRDDiagnosticSelectorFieldPropertyTypeList;
    case MedicalRequestDefinitionFieldType.ProcedureSelector:
      return MRDProcedureReportFieldPropertyTypeList;
    default:
      return Object.values(MRDFieldPropertyType);
  }
};

interface PropertyValueProps {
  propertyType?: MRDFieldPropertyType;
  fieldType?: MedicalRequestDefinitionFieldType;
  getSource: (source: string) => string;
  medicationCharacteristicCodeSystem?: CodeSystemGroup;
  requestAsNeededEventCodeSystem?: CodeSystemGroup;
  routeCodeSystem?: CodeSystemGroup;
  systemSystem?: CodeSystemGroup;
  frequencyTypeSystem?: CodeSystemGroup;
  medicalDeviceTagSystem?: CodeSystemGroup;
  unitSystem?: CodeSystemGroup;
  procedureReportCategorySystem?: CodeSystemGroup;
  organizationId?: ModelId;
}

const PropertyValue = ({
  propertyType,
  fieldType,
  getSource,
  medicationCharacteristicCodeSystem,
  requestAsNeededEventCodeSystem,
  routeCodeSystem,
  systemSystem,
  frequencyTypeSystem,
  medicalDeviceTagSystem,
  unitSystem,
  procedureReportCategorySystem,
  organizationId
}: PropertyValueProps): ReactElement => {
  const { t } = useI18n();
  const valueSource = getSource(MRDFieldPropertyKey.value);
  const textSource = getSource(MRDFieldPropertyKey.text);
  switch (propertyType) {
    case MRDFieldPropertyType.AdditiveCharacteristic:
    case MRDFieldPropertyType.Characteristic:
    case MRDFieldPropertyType.DilutionCharacteristic:
    case MRDFieldPropertyType.SolventCharacteristic:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={medicationCharacteristicCodeSystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.Event:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={requestAsNeededEventCodeSystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.IsEndDate:
    case MRDFieldPropertyType.IsStartDate:
    case MRDFieldPropertyType.Required:
    case MRDFieldPropertyType.WithCalculator:
    case MRDFieldPropertyType.DefaultWarning:
    case MRDFieldPropertyType.HideExtraDoseNow:
    case MRDFieldPropertyType.HideDose:
    case MRDFieldPropertyType.HidePresentation:
    case MRDFieldPropertyType.PresentationRequired:
    case MRDFieldPropertyType.NotModifiable:
    case MRDFieldPropertyType.NotModifiableDose:
    case MRDFieldPropertyType.NotModifiablePresentation:
      return (
        <BooleanInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          format={(formValue?: string) =>
            getBooleanFromStrOrUndefined(formValue)
          }
          parse={selectValue => (selectValue === true ? "true" : "false")}
        />
      );
    case MRDFieldPropertyType.DefaultQuantity:
    case MRDFieldPropertyType.MinElements:
    case MRDFieldPropertyType.MaxElements:
    case MRDFieldPropertyType.FlaskSize:
      return (
        <NumberInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          min={0}
          step={1}
        />
      );
    case MRDFieldPropertyType.AdditiveOption:
    case MRDFieldPropertyType.PresentationOption:
    case MRDFieldPropertyType.Option: {
      switch (fieldType) {
        case MedicalRequestDefinitionFieldType.RouteSelector:
          return (
            <CodeSystemSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              withCreate
              codeSystem={routeCodeSystem}
              organizationId={organizationId}
            />
          );
        default:
          return (
            <>
              <TextInput
                source={valueSource}
                label={t(tk.fields.fieldPropertyValue)}
                required
              />
              <TextInput
                source={textSource}
                label={t(tk.fields.fieldPropertyText)}
                required
              />
            </>
          );
      }
    }
    case MRDFieldPropertyType.RequestType: {
      switch (fieldType) {
        case MedicalRequestDefinitionFieldType.Checkbox:
          return (
            <>
              <TextInput
                source={valueSource}
                label={t(tk.fields.fieldPropertyValue)}
                required
              />
              <TextInput
                source={textSource}
                label={t(tk.fields.fieldPropertyText)}
                required
              />
            </>
          );
        default:
          return (
            <TextInput
              source={valueSource}
              required
              label={t(tk.fields.fieldPropertyValue)}
            />
          );
      }
    }
    case MRDFieldPropertyType.Placeholder:
    case MRDFieldPropertyType.Title:
      return (
        <TextInput
          source={textSource}
          label={t(tk.fields.fieldPropertyText)}
          multiline
        />
      );
    case MRDFieldPropertyType.Type: {
      switch (fieldType) {
        case MedicalRequestDefinitionFieldType.DeviceSelector:
        case MedicalRequestDefinitionFieldType.MedicationSelector:
        case MedicalRequestDefinitionFieldType.MedicationListSelector:
          return (
            <EnumSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              enumValue={MRDMedicationSelectorFieldPropertyTypeTypes}
            />
          );
        case MedicalRequestDefinitionFieldType.DoseSelector:
        case MedicalRequestDefinitionFieldType.MedicationPresentationSelector:
        case MedicalRequestDefinitionFieldType.DilutionSelector:
        case MedicalRequestDefinitionFieldType.RateSelector:
          return (
            <EnumSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              enumValue={MRDPropertyTypeTypes}
            />
          );
        case MedicalRequestDefinitionFieldType.RouteSelector:
          return (
            <EnumSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              enumValue={MRDPropertyTypeTypes}
            />
          );
        case MedicalRequestDefinitionFieldType.FrequencySelector:
          return (
            <CodeSystemSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              withCreate
              codeSystem={frequencyTypeSystem}
              organizationId={organizationId}
            />
          );
        case MedicalRequestDefinitionFieldType.DurationSelector:
          return (
            <EnumSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              enumValue={MRDDurationSelectorFieldPropertyTypeTypes}
            />
          );
        default:
          return (
            <>
              <TextInput
                source={valueSource}
                label={t(tk.fields.fieldPropertyValue)}
                required
              />
              <TextInput
                source={textSource}
                label={t(tk.fields.fieldPropertyText)}
              />
            </>
          );
      }
    }
    case MRDFieldPropertyType.Tags:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={medicalDeviceTagSystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.Unit:
    case MRDFieldPropertyType.AdditiveUnit:
    case MRDFieldPropertyType.DefaultUnit:
    case MRDFieldPropertyType.DoseUnit:
    case MRDFieldPropertyType.FlaskUnit:
    case MRDFieldPropertyType.FlaskFractionUnit:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={unitSystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.AdditiveUnitSystem:
    case MRDFieldPropertyType.OptionSystem:
    case MRDFieldPropertyType.PresentationOptionSystem:
    case MRDFieldPropertyType.UnitSystem:
    case MRDFieldPropertyType.ProblemSystem:
    case MRDFieldPropertyType.DoseUnitSystem:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={systemSystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.Category:
      return (
        <CodeSystemSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          withCreate
          codeSystem={procedureReportCategorySystem}
          organizationId={organizationId}
        />
      );
    case MRDFieldPropertyType.GenericType:
      return (
        <EnumSelectorInput
          source={valueSource}
          label={t(tk.fields.fieldPropertyValue)}
          required
          enumValue={MedicationGenericType}
        />
      );
    case MRDFieldPropertyType.PresentationType: {
      switch (fieldType) {
        case MedicalRequestDefinitionFieldType.MedicationListSelector:
          return (
            <EnumSelectorInput
              source={valueSource}
              label={t(tk.fields.fieldPropertyValue)}
              required
              enumValue={MRDPropertyTypeTypes}
            />
          );
        default:
          return (
            <>
              <TextInput
                source={valueSource}
                label={t(tk.fields.fieldPropertyValue)}
                required
              />
              <TextInput
                source={textSource}
                label={t(tk.fields.fieldPropertyText)}
              />
            </>
          );
      }
    }
    case MRDFieldPropertyType.Condition:
    default:
      return (
        <>
          <TextInput
            source={valueSource}
            label={t(tk.fields.fieldPropertyValue)}
          />
          <TextInput
            source={textSource}
            label={t(tk.fields.fieldPropertyText)}
          />
        </>
      );
  }
};

export interface MedicalRequestDefinitionFieldFieldsProps {
  fieldType?: MedicalRequestDefinitionFieldType;
  getSource?: (source: string) => string;
  withProps?: boolean;
  medicationCharacteristicCodeSystem?: CodeSystemGroup;
  requestAsNeededEventCodeSystem?: CodeSystemGroup;
  routeCodeSystem?: CodeSystemGroup;
  systemSystem?: CodeSystemGroup;
  frequencyTypeSystem?: CodeSystemGroup;
  medicalDeviceTagSystem?: CodeSystemGroup;
  procedureReportCategorySystem?: CodeSystemGroup;
  unitSystem?: CodeSystemGroup;
  organizationId?: ModelId;
}

export const MedicalRequestDefinitionFieldFields: FC<
  MedicalRequestDefinitionFieldFieldsProps
> = ({
  getSource = value => value,
  fieldType,
  withProps = true,
  medicationCharacteristicCodeSystem,
  requestAsNeededEventCodeSystem,
  routeCodeSystem,
  systemSystem,
  frequencyTypeSystem,
  medicalDeviceTagSystem,
  procedureReportCategorySystem,
  unitSystem,
  organizationId
}) => {
  const { t } = useI18n();
  return (
    <>
      <EnumSelectorInput
        source={getSource(MRDFieldKey.type)}
        label={t(tk.fields.fieldType)}
        enumValue={MedicalRequestDefinitionFieldType}
        required
      />
      <NumberInput
        source={getSource(MRDFieldKey.column)}
        label={t(tk.fields.fieldColumn)}
        required
        min={0}
        step={1}
        max={3}
      />
      {withProps && (
        <ArrayInput
          source={getSource(MRDFieldKey.properties)}
          label={t(tk.fields.fieldProperties)}
        >
          <EnumSelectorInput
            source={MRDFieldPropertyKey.type}
            label={t(tk.fields.fieldPropertyType)}
            enumValue={MRDFieldPropertyType}
            optionValue={getFieldTypePropertyTypeList(fieldType)}
            withCreate
          />
          <FormDataConsumer<
            MedicalRequestDefinition,
            MRDFieldPropertyBase<MRDFieldPropertyType>
          >>
            {({ getSource: getPropertySource, scopedFormData: property }) => {
              return (
                <PropertyValue
                  propertyType={property?.type}
                  fieldType={fieldType}
                  getSource={getPropertySource}
                  frequencyTypeSystem={frequencyTypeSystem}
                  medicalDeviceTagSystem={medicalDeviceTagSystem}
                  medicationCharacteristicCodeSystem={
                    medicationCharacteristicCodeSystem
                  }
                  requestAsNeededEventCodeSystem={
                    requestAsNeededEventCodeSystem
                  }
                  systemSystem={systemSystem}
                  routeCodeSystem={routeCodeSystem}
                  unitSystem={unitSystem}
                  procedureReportCategorySystem={procedureReportCategorySystem}
                  organizationId={organizationId}
                />
              );
            }}
          </FormDataConsumer>
        </ArrayInput>
      )}
    </>
  );
};
