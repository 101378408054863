import { StyleVariant, TextVariant, TypeVariant } from "model/themeVariant";
import {
  getThemeRadius,
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";
import { RadiusVariant, Theme, ThemeStyleColorVariant } from "model/theme";
import {
  borderMixin,
  borderMixinRadiusAll,
  createStyle,
  FlexAlignItems,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  marginMixin,
  paddingMixin,
  pxToRem,
  TextAlign
} from "@laba/react-common";
import { ClassNameDynamicGeneratedRecord } from "components/utils/getGenericButtonColors";

export interface TextInputStyleProps {
  disabled: boolean;
  multiline: boolean;
  fullHeight: boolean;
  variant: TypeVariant;
  disableAllOutlines: boolean;
  noPadding: boolean;
  textAlign: TextAlign;
  showLeftBorder: boolean;
}

interface OutlinedInputClassNames {
  root?: string;
  adornedEnd?: string;
  input?: string;
  notchedOutline?: string;
  multiline?: string;
  focused?: string;
}

const generatedStyleKeyTemplate = (
  prefix: DynamicClassNameKeys,
  props: TextInputStyleProps
) =>
  `${prefix}generatedStyle${props.variant}${props.textAlign}${
    props.multiline ? "Multiline" : "SingleLine"
  }${props.fullHeight ? "FullHeight" : "NotFullHeight"}${
    props.disabled ? "Disabled" : "NotDisabled"
  }${
    props.disableAllOutlines ? "DisableAllOutlines" : "NotDisableAllOutlines"
  }${props.noPadding ? "NoPadding" : "WithPadding"}${
    props.showLeftBorder ? "withoutLeftBorder" : "withLeftBorder"
  }`;

enum DynamicClassNameKeys {
  Root = "root",
  Input = "input",
  NotchedOutline = "notchedOutline",
  Focused = "focused"
}

const generateDynamicStylesClassNames = (
  theme: Theme
): ClassNameDynamicGeneratedRecord => {
  const classNameObject: ClassNameDynamicGeneratedRecord = {};
  Object.values(TypeVariant).forEach(typeVariant => {
    Object.values([true, false]).forEach(multiline => {
      [true, false].forEach(fullHeight => {
        [true, false].forEach(disabled => {
          [true, false].forEach(disableAllOutlines => {
            [true, false].forEach(noPadding => {
              [true, false].forEach(showLeftBorder => {
                Object.values(TextAlign).forEach(textAlign => {
                  classNameObject[
                    generatedStyleKeyTemplate(DynamicClassNameKeys.Root, {
                      disabled,
                      multiline,
                      fullHeight,
                      variant: typeVariant,
                      disableAllOutlines,
                      noPadding,
                      textAlign,
                      showLeftBorder
                    })
                  ] = {
                    ...(fullHeight && flexItemMixin({ grow: 1 })),
                    ...(typeVariant === TypeVariant.Contained && {
                      backgroundColor: getThemeStyleColor(
                        theme,
                        StyleVariant.Primary,
                        ThemeStyleColorVariant.Contrast
                      )
                    })
                  };
                  classNameObject[
                    generatedStyleKeyTemplate(DynamicClassNameKeys.Input, {
                      disabled,
                      multiline,
                      fullHeight,
                      variant: typeVariant,
                      disableAllOutlines,
                      noPadding,
                      textAlign,
                      showLeftBorder
                    })
                  ] = {
                    ...(!multiline &&
                      paddingMixin({
                        leftRight: !noPadding ? 16 : 0
                      })),
                    color: disabled
                      ? getThemeStyleState(theme, StyleVariant.Primary).disabled
                      : getThemeStyleColor(
                          theme,
                          StyleVariant.Primary,
                          ThemeStyleColorVariant.GrayDark
                        ),
                    minHeight: multiline ? pxToRem(19) : pxToRem(40),
                    textAlign
                  };
                  classNameObject[
                    generatedStyleKeyTemplate(
                      DynamicClassNameKeys.NotchedOutline,
                      {
                        disabled,
                        multiline,
                        fullHeight,
                        variant: typeVariant,
                        disableAllOutlines,
                        noPadding,
                        textAlign,
                        showLeftBorder
                      }
                    )
                  ] = {
                    ...borderMixin({
                      color: getThemeStyleColor(
                        theme,
                        StyleVariant.Primary,
                        ThemeStyleColorVariant.GrayMedium
                      ),
                      side: {
                        all:
                          typeVariant === TypeVariant.Contained ? 0 : undefined,
                        left:
                          showLeftBorder &&
                          !(typeVariant === TypeVariant.Contained)
                            ? 1
                            : 0
                      }
                    }),
                    ...(disableAllOutlines && { border: "none" })
                  };
                  classNameObject[
                    generatedStyleKeyTemplate(DynamicClassNameKeys.Focused, {
                      disabled,
                      multiline,
                      fullHeight,
                      variant: typeVariant,
                      disableAllOutlines,
                      noPadding,
                      textAlign,
                      showLeftBorder
                    })
                  ] = {
                    "&:focused :notchedOutline": {
                      ...borderMixin({
                        side: {
                          all: typeVariant === TypeVariant.Contained ? 0 : 1,
                          left:
                            showLeftBorder &&
                            !(typeVariant === TypeVariant.Contained)
                              ? 1
                              : 0
                        }
                      })
                    }
                  };
                });
              });
            });
          });
        });
      });
    });
  });
  return classNameObject;
};

const useOutlinedInputStyle = createStyle((theme: Theme) => {
  return generateDynamicStylesClassNames(theme);
}, "OutlinedInput");

const useOutlinedInputStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...borderMixinRadiusAll(getThemeRadius(theme, RadiusVariant.Small))
    },
    adornedEnd: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    input: {
      ...themeTextMixin(theme, TextVariant.Body2),
      overflow: "auto",
      boxSizing: "border-box",
      "&:-internal-autofill-selected": {
        backgroundColor: getThemeStyleColor(
          theme,
          StyleVariant.Primary,
          ThemeStyleColorVariant.Contrast
        ),
        color: "inherit"
      },
      "&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active":
        {
          boxShadow: `0 0 0 30px ${getThemeStyleColor(
            theme,
            StyleVariant.Primary,
            ThemeStyleColorVariant.Contrast
          )} inset`
        }
    },
    notchedOutline: {
      ...themeTextMixin(theme, TextVariant.Body2)
    },
    multiline: {
      ...paddingMixin({
        leftRight: 16
      })
    }
  }),
  "StaticOutlinedInput"
);

export const useInputLabelStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      width: "calc(100% - 32px)"
    },
    focused: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      ),

      width: "100%"
    }
  }),
  "InputLabel"
);

export const useNumericStyle = createStyle(
  (_theme: Theme) => ({
    input: {
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        {
          display: "none"
        },
      "& input[type=number]": {
        MozAppearance: "textfield"
      }
    }
  }),
  "Numeric"
);

export const useInputAdornmentStyle = createStyle(
  (theme: Theme) => ({
    root: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    }
  }),
  "InputAdornment"
);

export const useOutlinedInputStyleClasses = (
  props: TextInputStyleProps
): OutlinedInputClassNames => {
  const staticClasses = useOutlinedInputStaticStyle();
  const dynamicClasses = useOutlinedInputStyle();
  return {
    root: getClassName(
      dynamicClasses[
        generatedStyleKeyTemplate(DynamicClassNameKeys.Root, props)
      ],
      staticClasses.root
    ),
    adornedEnd: staticClasses.adornedEnd,
    input: getClassName(
      dynamicClasses[
        generatedStyleKeyTemplate(DynamicClassNameKeys.Input, props)
      ],
      staticClasses.input
    ),
    notchedOutline: getClassName(
      dynamicClasses[
        generatedStyleKeyTemplate(DynamicClassNameKeys.NotchedOutline, props)
      ],
      staticClasses.notchedOutline
    ),
    multiline: staticClasses.multiline,
    focused:
      dynamicClasses[
        generatedStyleKeyTemplate(DynamicClassNameKeys.Focused, props)
      ]
  };
};

interface CommonTextInputStyles {
  charsLimit: string;
  container: string;
  formHelperTextContainer: string;
  showableHelperText: string;
}

const commonTextInputStaticStyles = createStyle((theme: Theme) => {
  return {
    container: {
      ...flexColumnMixin({})
    },
    containerFullWidth: {
      width: "100%"
    },
    containerWidth: {
      maxWidth: pxToRem(256)
    },
    charsLimit: {
      ...marginMixin({ left: "auto" }),
      ...themeTextMixin(theme, TextVariant.Caption),
      ...flexItemMixin({ shrink: 0, align: FlexAlignSelf.FlexStart })
    },
    errorTextColorDanger: {
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    errorTextColorGrayDark: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    formHelperTextContainer: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center,
        gap: 2
      }),
      ...marginMixin({ leftRight: 14 })
    },
    showableHelperText: {
      width: "100%",
      ...themeTextMixin(theme, TextVariant.Caption)
    }
  };
}, "commonTextInputStaticStyles");

interface CommonTextInputStylesProps {
  hasError?: boolean;
  fullWidth?: boolean;
  valueHasReachedCharLimit?: boolean;
}

export const commonTextInputStyles = ({
  hasError,
  fullWidth,
  valueHasReachedCharLimit
}: CommonTextInputStylesProps): CommonTextInputStyles => {
  const classes = commonTextInputStaticStyles();
  return {
    charsLimit: classes.charsLimit,
    container: getClassName(
      classes.container,
      fullWidth ? classes.containerFullWidth : classes.containerWidth
    ),
    formHelperTextContainer: getClassName(
      classes.formHelperTextContainer,
      hasError || valueHasReachedCharLimit
        ? classes.errorTextColorDanger
        : classes.errorTextColorGrayDark
    ),
    showableHelperText: classes.showableHelperText
  };
};
