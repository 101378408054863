import { RaResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import {
  CodeSystem,
  KnownConceptPropertyUse,
  KnownSpecialEffectPropertyCode
} from "@laba/nexup-api";
import { getEnumOrUndefined, notUndefined, Optional } from "@laba/ts-common";
import { isEmpty, toLower, uniq } from "lodash-es";

export const adminPanelAppConfigPrefix = "admin_panel";

export enum AdminPanelSpecificPermissions {
  PractitionerUser = "practitioner_user",
  PractitionerPractitionerRole = "practitioner_practitioner_role"
}

export enum AdminPanelSpecialPermissions {
  Admin = "admin",
  AllResources = "all_resource",
  AllDashboard = "all_dashboard",
  Dosuba = "dosuba",
  WorkspaceAdministration = "workspace_administration",
  AlfabetaProduct = "alfabeta_product",
  AlfabetaMedication = "alfabeta_medication",
  AlfabetaContract = "alfabeta_contract",
  ShowAllRoleCodes = "show_all_role_codes"
}

export type AdminPanelPermissions =
  | RaResourceType
  | AdminPanelSpecialPermissions
  | AdminPanelSpecificPermissions;

export const getAdminPanelAppConfigValue = (
  permission: AdminPanelPermissions
): string => toLower(`${adminPanelAppConfigPrefix}_${permission}`);

export const getAdminPanelPermission = (
  appConfig: string
): Optional<AdminPanelPermissions> => {
  const appConfigPermission = toLower(appConfig);
  if (appConfigPermission === adminPanelAppConfigPrefix)
    return AdminPanelSpecialPermissions.Admin;
  if (appConfigPermission.startsWith(`${adminPanelAppConfigPrefix}_`))
    return appConfigPermission.substring(
      `${adminPanelAppConfigPrefix}_`.length
    ) as AdminPanelPermissions;
  return getEnumOrUndefined(AdminPanelSpecialPermissions)(appConfigPermission);
};

export const getCompleteRolList = (
  directRoles: string[],
  roleCodeSystem: CodeSystem,
  otherRoles: string[] = []
): string[] => {
  const completeRoleList = [...otherRoles, ...directRoles];
  const childrenRoles =
    roleCodeSystem.concept
      ?.filter(concept => directRoles.includes(concept.code))
      .flatMap(concept =>
        concept.property
          .filter(
            property =>
              property.use === KnownConceptPropertyUse.ParentRole &&
              property.code &&
              !completeRoleList.includes(property.code)
          )
          .map(property => property.code)
      )
      .filter(notUndefined) || [];
  if (isEmpty(childrenRoles)) {
    return uniq(completeRoleList);
  }
  return getCompleteRolList(childrenRoles, roleCodeSystem, completeRoleList);
};

export const getAdminPanelPermissionsFromPractitionerRoles = (
  roles: string[],
  roleCodeSystem: CodeSystem
): AdminPanelPermissions[] => {
  const completeRoleList = getCompleteRolList(roles, roleCodeSystem);
  const isSystemAdmin = completeRoleList.some(role =>
    roleCodeSystem.concept
      ?.find(concept => concept.code === role)
      ?.property.some(
        property =>
          property.use === KnownConceptPropertyUse.SpecialEffect &&
          property.code === KnownSpecialEffectPropertyCode.AdminRole
      )
  );
  const result = uniq(
    completeRoleList
      .flatMap(role =>
        roleCodeSystem.concept
          ?.find(concept => concept.code === role)
          ?.property.filter(
            property =>
              property.use === KnownConceptPropertyUse.AdminPanelAppConfig
          )
          .map(property =>
            property.code ? getAdminPanelPermission(property.code) : undefined
          )
      )
      .filter(notUndefined)
      .concat(isSystemAdmin ? [AdminPanelSpecialPermissions.Admin] : [])
  );
  return result;
};

export const hasPermission = (
  permission: AdminPanelPermissions,
  permissionList?: AdminPanelPermissions[]
): boolean =>
  permissionList?.map(toLower).includes(toLower(permission)) ?? false;
