import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { isEmpty } from "lodash-es";
import { useHelperTextStyles } from "./useHelperTextStyles";

interface HelperTextProps {
  hide?: boolean;
  text?: string;
  className?: string;
}
export const HelperText: FC<HelperTextProps> = ({ hide, text, className }) => {
  const classes = useHelperTextStyles();
  const show = !hide && !isEmpty(text);
  return show ? (
    <p className={getClassName(classes.text, className)} title={text}>
      {text}
    </p>
  ) : null;
};
