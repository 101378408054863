import {
  ApiDate,
  getBooleanFromStr,
  getKeyObj,
  Model,
  notUndefined,
  OpenCode,
  Optional
} from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { Identifier as IdentifierModel } from "model/primitives/identifier";
import { head, isEqual, isUndefined } from "lodash-es";
import { ConceptToCodeSystemData } from "api/entities";
import { ResourceModel, ResourceType } from "../../primitives/resourceModel";
import { ModelReference } from "../../primitives/modelReference/modelReference";
import { Organization } from "./organization/organization";

export type Code = string;

export enum CodeSystemStatus {
  Active = "active",
  Draft = "draft",
  Retired = "retired",
  Unknown = "unknown"
}

export enum KnownConceptPropertyUse {
  Permission = "Permission",
  SpecialEffect = "SpecialEffect",
  AppConfig = "AppConfig",
  ParentRole = "ParentRole",
  DirectoryType = "DirectoryType",
  AdminPanelAppConfig = "AdminPanelAppConfig",
  Color = "Color",
  SubProcedure = "SubProcedure",
  ProcedureCategory = "ProcedureCategory",
  Abbreviation = "Abbreviation",
  ProcedureReportStatus = "ProcedureReportStatus",
  MedicalArea = "MedicalArea",
  ProcedureCodeTag = "ProcedureCodeTag",
  Active = "Active",
  Comment = "Comment",
  BodyRegion = "BodyRegion",
  SubBodyRegion = "SubBodyRegion",
  Speciality = "Speciality",
  Specimen = "Specimen",
  ProcedureCode = "ProcedureCode",
  OrganizationPayerCode = "OrganizationPayerCode",
  OrganizationPayerName = "OrganizationPayerName",
  RolPlan = "RolPlan",
  ProductCategory = "ProductCategory",
  ConsumptionPaymentDebtType = "ConsumptionPaymentDebtType",
  HoverText = "HoverText",
  FilterCode = "FilterCode",
  TagText = "TagText",
  ShowDebt = "ShowDebt",
  DeleteEnabled = "DeleteEnabled",
  RejectedInvoice = "RejectedInvoice",
  PayedInvoice = "PayedInvoice",
  OpenInvoice = "OpenInvoice",
  Status = "Status",
  Default = "Default",
  ProductStock = "ProductStock",
  Hide = "Hide",
  Day = "Day",
  Month = "Month",
  Year = "Year",
  RolePlan = "RolePlan",
  Description = "Description",
  ParentRoleForPlan = "ParentRoleForPlan",
  Place = "Place",
  EhrTabSystem = "EhrTabSystem",
  WindowStart = "WindowStart",
  WindowEnd = "WindowEnd",
  DefaultOnAction = "DefaultOnAction",
  ShowDateInput = "ShowDateInput",
  ShowSwabExpireTag = "ShowSwabExpireTag",
  DaysToExpire = "DaysToExpire",
  Reversible = "Reversible",
  CancellationReasonTitle = "CancellationReasonTitle",
  BuildingRequired = "BuildingRequired",
  Priority = "Priority",
  Installment = "Installment",
  StatusFilterCode = "StatusFilterCode"
}
export type ConceptPropertyUse = OpenCode<KnownConceptPropertyUse>;

export enum KnownSpecialEffectPropertyCode {
  PatientRegistered = "PatientRegistered",
  EtlRole = "EtlRole",
  AdminRole = "AdminRole",
  TemporalPatientRegistered = "TemporalPatientRegistered",
  PractitionerRegistered = "PractitionerRegistered",
  WorkspaceRegistered = "WorkspaceRegistered",
  PractitionerInvitation = "PractitionerInvitation",
  ChargeableRole = "ChargeableRole",
  AdminPanel = "AdminPanel"
}

export type ConceptPropertyCode = OpenCode<KnownSpecialEffectPropertyCode>;

export interface ConceptProperty {
  use: ConceptPropertyUse;
  code?: ConceptPropertyCode;
  valueCoding?: Coding;
  valueString?: string;
  valueInteger?: number;
  valueBoolean?: boolean;
  valueDateTime?: ApiDate;
  valueDecimal?: number;
}

export const CodeSystemConceptPropertyKey = getKeyObj<ConceptProperty>(
  createHydratedMock<ConceptProperty>()
);

export enum CodeSystemDesignationCode {
  Synonymous = "Synonymous",
  PreferredTerm = "PreferredTerm"
}

export type CodeSystemDesignationUse = OpenCode<CodeSystemDesignationCode>;

export interface ConceptDesignation {
  language?: string;
  use?: CodeSystemDesignationUse;
  value?: string;
}

export interface Concept {
  code: Code;
  display?: string;
  property: ConceptProperty[];
  identifier?: IdentifierModel[];
  designation?: ConceptDesignation[];
}

export const CodeSystemConceptKey = getKeyObj<Concept>(
  createHydratedMock<Concept>()
);

export enum KnownCodeSystemSystem {
  ConditionList = "http://platform.lab-a.com.ar/fhir/StructureDefinition/condition-code",
  AllergyList = "http://platform.lab-a.com.ar/fhir/StructureDefinition/allergyintolerance-code",
  PatientClassificationSC = "http://platform.lab-a.com.ar/fhir/StructureDefinition/patient-classification-sc",
  EncounterPractitionerType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/encounter-participant-type",
  EncounterConsultationReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/encounter-consultation-reason",
  EncounterCancellationReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/encounter-cancellation-reason",
  EncounterType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/encounter-type",
  LocationDirectoryType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/location-directory-type",
  OrganizationPayerPlan = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-payer-plan",
  OrganizationDirectorySpeciality = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-directory-speciality",
  OrganizationDirectoryCity = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-directory-city",
  OrganizationDirectoryState = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-directory-state",
  OrganizationDirectoryCountry = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-directory-country",
  PractitionerRole = "http://platform.lab-a.com.ar/fhir/StructureDefinition/PractitionerRole",
  PatientRole = "http://platform.lab-a.com.ar/fhir/StructureDefinition/patient-role-codes",
  PractitionerRegistrationType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/practitioner-registration-type",
  RegionCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/region-code",
  PractitionerProfession = "http://platform.lab-a.com.ar/fhir/StructureDefinition/practitioner-profession",
  CodeSystemSystem = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-system",
  CodeSystemPermissionProperty = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-property-permission",
  CodeSystemAppConfigProperty = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-property-app-config",
  HealthcareServiceCategory = "http://terminology.hl7.org/CodeSystem/service-category",
  ObservationPatientTag = "http://platform.lab-a.com.ar/fhir/StructureDefinition/observation-tags",
  ServiceType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/service-type",
  DiagnosisRole = "http://terminology.hl7.org/CodeSystem/diagnosis-role",
  QuestionnaireCategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/questionnaire-category",
  MedicalDeviceType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/device-type",
  MedicalDeviceTag = "http://platform.lab-a.com.ar/fhir/StructureDefinition/device-property-tag",
  MedicationCharacteristic = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medication-characteristic-code",
  MedicationForm = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medication-form-codes",
  MedicationPresentationUnit = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medication-presentation-unit-codes",
  MedicationRoute = "http://platform.lab-a.com.ar/fhir/StructureDefinition/route-codes",
  MedicationFunctionalCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medication-function-codes",
  MedicationUnit = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medication-amount-unit",
  MedicalRequestCategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medical-request-category",
  MedicalRequestType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medical-request-type",
  MedicalRequestFrequencyType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/request-frequency-type",
  MedicalRequestAsNeededEvent = "http://platform.lab-a.com.ar/fhir/StructureDefinition/request-as-needed-event",
  ObservationCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/observation-codes",
  PersonIdentifierSystem = "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-identifier",
  Identifier = "http://platform.lab-a.com.ar/fhir/StructureDefinition/identifier-system",
  ProcedureReportStatus = "http://hl7.org/fhir/diagnostic-report-status",
  ProcedureReportStatusReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-report-status-reason",
  ProcedureReportCategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-report-category",
  ProcedureReportPerformerRole = "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-report-performer-role",
  ProcedureReportCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-report-code",
  LocationCategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/location-category",
  ScheduleType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/schedule-type",
  ScheduleSpeciality = "http://platform.lab-a.com.ar/fhir/StructureDefinition/schedule-speciality",
  AppointmentTag = "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-tag",
  AppointmentCancellationReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-cancellation-reason",
  CodeSystemConceptMedicalArea = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-medical-area",
  CodeSystemConceptProcedureCodeTag = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-procedure-code-tag",
  CodeSystemConceptPreferredTerm = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-preferred-term",
  CodeSystemConceptSynonymous = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-synonymous",
  CodeSystemConceptComment = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-comment",
  CodeSystemConceptBodyRegion = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-body-region",
  CodeSystemConceptSubBodyRegion = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-sub-body-region",
  CodeSystemConceptSpecimen = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-specimen",
  CodeSystemConceptSpeciality = "http://platform.lab-a.com.ar/fhir/StructureDefinition/code-system-concept-speciality",
  BatteryProcedureConcept = "http://platform.lab-a.com.ar/fhir/StructureDefinition/procedure-battery",
  PersonLegalIdentifierSystem = "http://platform.lab-a.com.ar/fhir/StructureDefinition/person-legal-identifier-system",
  NexupClientPlan = "http://platform.lab-a.com.ar/fhir/StructureDefinition/nexup-client-plan",
  AppointmentReminderNotifyMinutes = "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-reminder-notify-minutes",
  ProductCategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/product-category",
  ProductSubcategory = "http://platform.lab-a.com.ar/fhir/StructureDefinition/product-subcategory",
  ProductTag = "http://platform.lab-a.com.ar/fhir/StructureDefinition/product-tag",
  OrganizationCurrency = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-currency",
  AppointmentDuration = "http://platform.lab-a.com.ar/fhir/StructureDefinition/appointment-duration",
  ConsumptionPaymentMethod = "http://platform.lab-a.com.ar/fhir/StructureDefinition/consumption-payment-method",
  ConsumptionCoveragePaymentStatus = "http://platform.lab-a.com.ar/fhir/StructureDefinition/consumption-coverage-payment-status",
  ConsumptionPatientPaymentStatus = "http://platform.lab-a.com.ar/fhir/StructureDefinition/consumption-patient-payment-status",
  ConsumptionPaymentDebtType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/consumption-payment-debt-type",
  InvoiceStatus = "http://hl7.org/fhir/invoice-status",
  InvoiceStatusReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/invoice-status-reason",
  ChargeItemCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/charge-item-code",
  TaxType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/tax-type",
  OdontogramToothDiagnostic = "http://platform.lab-a.com.ar/fhir/StructureDefinition/odontogram-tooth-diagnostic",
  EhrPatientTab = "http://platform.lab-a.com.ar/fhir/StructureDefinition/ehr-patient-tab",
  InvoiceIssuer = "http://platform.lab-a.com.ar/fhir/StructureDefinition/invoice-issuer",
  TaxIdentifierSystem = "http://platform.lab-a.com.ar/fhir/StructureDefinition/tax-identifier-system",
  OrganizationSpeciality = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-speciality",
  OrganizationInterest = "http://platform.lab-a.com.ar/fhir/StructureDefinition/organization-interest",
  QualificationRegistrationType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/qualification-registration-type",
  QualificationSpecialityType = "http://platform.lab-a.com.ar/fhir/StructureDefinition/qualification-speciality-type",
  OrganizationTimezoneIana = "https://www.iana.org/time-zones",
  OrganizationCountry = "urn:iso:std:iso:3166",
  OrganizationSubscriptionStatus = "http://platform.lab-a.com.ar/fhir/StructureDefinition/subscription-status",
  NationalHoliday = "http://platform.lab-a.com.ar/fhir/StructureDefinition/national-holiday",
  HospitalizationDischargeDisposition = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-discharge-disposition",
  HospitalizationAdmitSource = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-admit-source",
  ScheduleConfirmationWindow = "http://platform.lab-a.com.ar/fhir/StructureDefinition/schedule-confirmation-window",
  ScheduleCancellationWindow = "http://platform.lab-a.com.ar/fhir/StructureDefinition/schedule-cancellation-window",
  HospitalizationSwabStatus = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-swab-status",
  HospitalizationStatusReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-status-reason",
  HospitalizationCancellationReason = "http://platform.lab-a.com.ar/fhir/StructureDefinition/hospitalization-cancellation-reason",
  MedicalPractice = "http://platform.lab-a.com.ar/fhir/StructureDefinition/medical-practice",
  AccountPaymentMethod = "http://platform.lab-a.com.ar/fhir/StructureDefinition/account-payment-method",
  PaymentInstallmentCode = "http://platform.lab-a.com.ar/fhir/StructureDefinition/payment-installment-code"
}

export type CodeSystemSystem = OpenCode<KnownCodeSystemSystem>;

export interface ConceptToCodeSystemDataAndCodeSystemGroup {
  concept: ConceptToCodeSystemData;
  codeSystemGroup: CodeSystemGroup;
}

export interface Coding {
  code: Code;
  system: CodeSystemSystem;
}

export const CodingKey = getKeyObj<Coding>(createHydratedMock<Coding>());

export interface CodeSystemCode<
  System extends CodeSystemSystem = CodeSystemSystem,
  SystemCode extends Code = Code
> {
  code?: SystemCode;
  name?: string;
  system?: System;
}

export interface CodeSystemGroup {
  system: CodeSystemSystem;
  concept?: Concept[];
}

export interface CodeSystem
  extends CodeSystemGroup,
    ResourceModel<ResourceType.CodeSystem> {
  status: CodeSystemStatus;
  organization: ModelReference<Organization>;
  public?: boolean;
}

export const CodeSystemKey = getKeyObj<CodeSystem>(
  createHydratedMock<CodeSystem>()
);

export interface SystemdownloadStatus {
  isDownloading?: boolean;
  downloadFailed?: boolean;
}

export interface CodeSystemWithStatus {
  system?: CodeSystem | CodeSystemGroup;
  downloadStatus: SystemdownloadStatus;
}

export type CodeSystemsRecord = Record<CodeSystemSystem, CodeSystemWithStatus>;
export type OrganizationCodeSystemRecord = Record<string, CodeSystemsRecord>;

export interface ResourceConcept extends Model {
  display: string;
}

export const getCodeSystemCodeString = (
  code: Optional<CodeSystemCode>
): Optional<string> => code?.name || code?.code || undefined;

export const getCodeSystemGroupCodesFromCodeSystem = (
  codeSystemGroup?: CodeSystemGroup,
  filterFn?: (c: Concept) => boolean
): CodeSystemCode[] => {
  const filterConcepts = Boolean(filterFn);
  const concepts = filterConcepts
    ? codeSystemGroup?.concept?.filter(c => filterFn?.(c))
    : codeSystemGroup?.concept;
  return (
    concepts?.map(c => {
      return {
        code: c.code,
        name: c.display || c.code,
        system: codeSystemGroup?.system
      };
    }) ?? []
  );
};
export const findConceptPropertyList = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): ConceptProperty[] => {
  return (
    concept?.property.filter(property => propertyUse === property.use) ?? []
  );
};

export const findConceptProperty = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): Optional<ConceptProperty> => {
  return head(findConceptPropertyList(propertyUse, concept));
};

const findDesignationConcept = (
  designationUse: CodeSystemDesignationCode,
  concept?: Concept
): Optional<ConceptDesignation> => {
  return concept?.designation?.find(
    designation => designationUse === designation.use
  );
};

export const getConceptDesignationValue = (
  designationUse: CodeSystemDesignationCode,
  concept?: Concept
): Optional<string> => {
  return findDesignationConcept(designationUse, concept)?.value;
};

export const getConceptDesignationValueList = (
  designationUse: CodeSystemDesignationCode,
  concept?: Concept
): string[] => {
  return (
    concept?.designation
      ?.filter(d => d.use === designationUse)
      .map(d => d.value)
      .filter(notUndefined) ?? []
  );
};

export const getConceptPropertyCode = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): Optional<ConceptPropertyCode> => {
  return findConceptProperty(propertyUse, concept)?.code;
};

export const getConceptBooleanProperty = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): boolean => {
  const conceptProperty = findConceptProperty(propertyUse, concept)?.code;
  return isUndefined(conceptProperty)
    ? false
    : getBooleanFromStr(conceptProperty);
};

export const getConceptPropertyCodeList = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): ConceptPropertyCode[] => {
  return (
    concept?.property
      .filter(p => p.use === propertyUse)
      .map(p => p.code)
      .filter(notUndefined) ?? []
  );
};

export const getConceptPropertyValueBoolean = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): boolean => {
  return findConceptProperty(propertyUse, concept)?.valueBoolean ?? false;
};

export const getConceptPropertyValueString = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): Optional<string> => {
  return findConceptProperty(propertyUse, concept)?.valueString;
};

export const getConceptPropertyValueInteger = (
  propertyUse: KnownConceptPropertyUse,
  concept?: Concept
): Optional<number> => {
  return findConceptProperty(propertyUse, concept)?.valueInteger;
};

export const getConceptListSpecificProperty = (
  property: KnownConceptPropertyUse,
  conceptList: Concept[],
  code?: Code
): Optional<ConceptPropertyCode> => {
  const status = head(conceptList.filter(concept => concept.code === code));
  return getConceptPropertyCode(property, status);
};

export const conceptListHasSpecificProperty = (
  property: KnownConceptPropertyUse,
  conceptList: Concept[],
  code?: Code
): boolean => {
  return !isUndefined(
    getConceptListSpecificProperty(property, conceptList, code)
  );
};

export const conceptHasSpecificProperty = (
  property: KnownConceptPropertyUse,
  concept: Concept,
  code: Code
): boolean => isEqual(getConceptPropertyCode(property, concept), code);
