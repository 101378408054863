import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  FlexJustifyContent,
  flexRowMixin,
  getClassName,
  paddingMixin,
  Theme
} from "@laba/react-common";
import { getThemeStyleColor, getThemeStyleState } from "model/themeUtils";
import { StyleVariant } from "model/themeVariant";
import { ThemeStyleColorVariant } from "model/theme";

interface AutocompleteOptionDynamicStylesProps {
  disabled?: boolean;
  hasBackgroundColor?: boolean;
  fullWidth?: boolean;
  isSelectedOption?: boolean;
  isMobile?: boolean;
  endChildrenPosition?: boolean;
  withPadding?: boolean;
}
interface AutocompleteOptionClassNames {
  root: string;
  mobileRow: string;
}

const getRootFlex = (endChildren?: boolean) => {
  return flexRowMixin({
    justifyContent: endChildren
      ? FlexJustifyContent.SpaceBetween
      : FlexJustifyContent.FlexStart,
    alignItems: FlexAlignItems.Center,
    gap: 12
  });
};

const useBaseAutocompleteOptionStaticStyles = createStyle(
  (theme: Theme) => ({
    mobileRoot: { ...flexColumnMixin({ gap: 8 }) },
    mobileRow: {
      ...flexRowMixin({
        justifyContent: FlexJustifyContent.SpaceBetween,
        alignItems: FlexAlignItems.Center,
        gap: 8
      })
    },
    rootPadding: { ...paddingMixin({ topBottom: 8, leftRight: 12 }) },
    mobileRootPadding: { ...paddingMixin({ all: 8 }) },
    fullWidthContainer: {
      width: "100%"
    },
    withBackgroundColor: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    },
    disabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    withPadding: {
      ...paddingMixin({ topBottom: 8, leftRight: 16 })
    },
    root: {
      ...getRootFlex(false),
      width: "100%"
    },
    rootEndChildren: {
      ...getRootFlex(true),
      width: "100%"
    }
  }),
  "BaseAutocompleteOptionStatic"
);

export const useAutocompleteOptionStyles = (
  props: AutocompleteOptionDynamicStylesProps
): AutocompleteOptionClassNames => {
  const staticClasses = useBaseAutocompleteOptionStaticStyles();

  return {
    root: getClassName(
      props.isMobile
        ? staticClasses.mobileRoot
        : props.endChildrenPosition
        ? staticClasses.rootEndChildren
        : staticClasses.root,
      props.disabled ? staticClasses.disabled : undefined,
      props.hasBackgroundColor ? staticClasses.withBackgroundColor : undefined,
      props.fullWidth ? staticClasses.fullWidthContainer : undefined,
      props.isSelectedOption
        ? props.isMobile
          ? staticClasses.mobileRootPadding
          : staticClasses.rootPadding
        : undefined,
      props.withPadding ? staticClasses.withPadding : undefined
    ),
    mobileRow: staticClasses.mobileRow
  };
};
