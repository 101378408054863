import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod } from "@laba/ts-common";
import { ModelId, OpenCode } from "model/primitives/model/model";
import { createHydratedMock } from "ts-auto-mock";
import {
  KnownScheduleType,
  RescheduleSchedule,
  Schedule
} from "model/resource/schedule/schedule";
import { Code } from "model/resource/entities/codeSystem";

export interface ScheduleQueryParams {
  withLocation?: boolean;
  withPractitioner?: boolean;
  withProduct?: boolean;
  withDefinition?: boolean;
}

export interface ScheduleListQueryParams
  extends ListQueryParams,
    ScheduleQueryParams {
  active?: boolean;
  content?: string;
  location?: ModelId;
  organization?: ModelId;
  practitioner?: ModelId;
  speciality?: Code;
  type?: OpenCode<KnownScheduleType>;
  onlineAppointmentEnabled?: boolean;
}

export const ScheduleListQueryParamsKey = getKeyObj<ScheduleListQueryParams>(
  createHydratedMock<ScheduleListQueryParams>()
);

export const ScheduleParamsKey = getKeyObj(createHydratedMock<Schedule>());

export const scheduleBasePath = "/schedule";

export const getScheduleList = async (
  params?: ScheduleListQueryParams
): Promise<ApiPageRequestResponse<Schedule>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(scheduleBasePath),
    params
  });

export const cancelSchedule = (
  id: ModelId
): Promise<ApiRequestResponse<Schedule>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${scheduleBasePath}/${id}/cancel`)
  });

export const getSchedule = async (
  id: ModelId,
  params?: ScheduleQueryParams
): Promise<ApiRequestResponse<Schedule>> =>
  request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(`${scheduleBasePath}/${id}/`),
    params
  });

export const createSchedule = (
  data: Schedule
): Promise<ApiRequestResponse<Schedule>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(scheduleBasePath),
    data
  });
};

export const editSchedule = (
  data: Schedule
): Promise<ApiRequestResponse<Schedule>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(`${scheduleBasePath}/${data.id}`),
    data
  });
};

export const rescheduleSchedule = (
  oldSchedule: Schedule,
  newSchedule: Schedule
): Promise<ApiRequestResponse<RescheduleSchedule>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(`${scheduleBasePath}/reschedule`),
    data: { oldSchedule, newSchedule }
  });
};
