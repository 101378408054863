import { ApiDate, HttpMethod, ModelId } from "@laba/ts-common";
import { AppointmentStatus } from "model/resource";
import { PatientAppointmentStatistics } from "model/resource/person/patient/patientAppointmentStatistics";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiRequestResponse } from "request/types";
import { urlJoin } from "url-join-ts";

export interface PatientAppointmentStatisticsQueryParams {
  withAppointment?: boolean;
  withPatientDebt?: boolean;
  withSchedule?: boolean;
  withPractitioner?: boolean;
  appointmentEndStartDate?: ApiDate;
  appointmentStartDate?: ApiDate;
  appointmentStatus?: AppointmentStatus[];
  maxAppointment?: number;
}

const patientBasePath = "/patient";
const patientAppointmentStatisticsBasePath = "/appointment-statistics";

export const getPatientAppointmentStatistics = (
  id: ModelId,
  params?: PatientAppointmentStatisticsQueryParams
): Promise<ApiRequestResponse<PatientAppointmentStatistics>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(
      urlJoin(patientBasePath, id, patientAppointmentStatisticsBasePath)
    ),
    params
  });
};
