import { ResourceType } from "model/primitives/resourceModel";
import { Model } from "model/primitives/model/model";
import { ModelReference } from "model/primitives/modelReference/modelReference";
import { BasePerson } from "model/resource/person/person";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";
import { User } from "model/resource/person/user";
import { PractitionerQualification } from "model/resource/person/practitioner/practitionerQualification";
import { Organization } from "model/resource/entities/organization";

export interface Practitioner extends BasePerson<ResourceType.Practitioner> {
  healthcareProfessionalData?: {
    profession?: string;
    registrationQualification?: PractitionerQualification[];
    specialityQualification?: PractitionerQualification[];
  };
  organization: ModelReference<Organization>[];
  user?: ModelReference<User>;
  selfAdministered?: boolean;
}

export interface PractitionerContainer extends Model {
  practitioner: ModelReference<Practitioner>;
}

export const PractitionerKey = getKeyObj(createHydratedMock<Practitioner>());
