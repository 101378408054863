import React from "react";
import { ReactElement } from "@laba/react-common";
import { BaseAutocompleteOption } from "../BaseAutocompleteOption/BaseAutocompleteOption";
import { AutocompleteOptionWrapperProps } from "./types";

export const AutocompleteTitleOnlyOptionWrapper = <V,>({
  children,
  option,
  onRemoveSelectedOption,
  removeIconStyle,
  disabled,
  hasBackgroundColor,
  fullWidth,
  isSelectedOption,
  isMobile,
  actionControlComponent,
  withPadding
}: AutocompleteOptionWrapperProps<V>): ReactElement => {
  const { text: titleText, title, value, Icon } = option;
  return (
    <BaseAutocompleteOption
      optionValue={value}
      onRemoveSelectedOption={onRemoveSelectedOption}
      removeIconStyle={removeIconStyle}
      disabled={disabled}
      hasBackgroundColor={hasBackgroundColor}
      fullWidth={fullWidth}
      isSelectedOption={isSelectedOption}
      isMobile={isMobile}
      title={title}
      titleText={titleText}
      actionControlComponent={actionControlComponent}
      Icon={Icon}
      withPadding={withPadding}
    >
      {children}
    </BaseAutocompleteOption>
  );
};
