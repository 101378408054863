import { getKeyObj } from "@laba/ts-common";
import { Money } from "model/primitives";
import { Appointment } from "model/resource/appointment";
import { createHydratedMock } from "ts-auto-mock";

export interface PatientAppointmentStatistics {
  patientDebt: Money[];
  appointmentList: Appointment[];
}

export const PatientAppointmentStatisticsParamsKey = getKeyObj(
  createHydratedMock<PatientAppointmentStatistics>()
);
