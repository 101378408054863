import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { getKeyObj, HttpMethod, ModelId } from "@laba/ts-common";
import { AccountStatus, Code, ProviderAccount } from "model/resource";
import { createHydratedMock } from "ts-auto-mock";
import { urlJoin } from "url-join-ts";

const ProviderAccountPath = "/provider-account";

export enum ProviderAccountListFilterOrder {
  CreationDate = "creationDate",
  LastUpdateDate = "lastUpdateDate",
  Status = "status"
}

export interface ProviderAccountListQueryParams extends ListQueryParams {
  content?: string;
  organization?: ModelId[];
  paymentMethod?: Code[];
  status?: AccountStatus[];
  order?: ProviderAccountListFilterOrder;
  withOrganization?: boolean;
  withPractitioner?: boolean;
}

export const ProviderAccountListQueryParamsKey = getKeyObj(
  createHydratedMock<ProviderAccountListQueryParams>()
);

export const getProviderAccountList = (
  params?: ProviderAccountListQueryParams
): Promise<ApiPageRequestResponse<ProviderAccount>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(ProviderAccountPath),
    params
  });
};

export const getProviderAccount = (
  id: ModelId
): Promise<ApiRequestResponse<ProviderAccount>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(urlJoin(ProviderAccountPath, id))
  });
};

export const createProviderAccount = (
  data: ProviderAccount
): Promise<ApiRequestResponse<ProviderAccount>> => {
  return request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(ProviderAccountPath),
    data
  });
};

export const editProviderAccount = (
  data: ProviderAccount
): Promise<ApiRequestResponse<ProviderAccount>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(urlJoin(ProviderAccountPath, data.id)),
    data
  });
};
