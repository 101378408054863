import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { Theme } from "model/theme";
import {
  createStyle,
  flexColumnMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";

interface TitleContentDataVerticalHorizontalItemStyleProps {
  showLineBreaks: boolean;
  isVertical: boolean;
  principalTextVariant?: TextVariant;
  secondaryTextVariant?: TextVariant;
  maxLines?: number;
  textColorVariant?: ThemeStyleColorVariant;
}

interface TitleContentDataVerticalHorizontalItemStyleClassNames {
  root: string;
  principalTextItemList: string;
  secondaryTextItemList: string;
}

const useTitleContentDataHorizontalItemStyleStatic = createStyle(
  (theme: Theme) => ({
    root: {
      ...themeTextMixin(theme, TextVariant.Body2),
      textOverflow: "ellipsis"
    },
    rootVertical: {
      ...flexColumnMixin({})
    }
  }),
  "TitleContentDataVerticalHorizontalItem"
);

const useTitleContentDataHorizontalItemDynamicStyle = createStyle(
  (theme: Theme) => ({
    principalText: (
      props: TitleContentDataVerticalHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        props.principalTextVariant ?? TextVariant.Subtitle2,
        props.maxLines
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.textColorVariant
      )
    }),
    secondaryText: (
      props: TitleContentDataVerticalHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        props.secondaryTextVariant ?? TextVariant.Body2,
        props.maxLines
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.textColorVariant
      )
    }),
    principalTextItemList: (
      props: TitleContentDataVerticalHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        props.principalTextVariant ?? TextVariant.Subtitle2,
        props.maxLines,
        props.showLineBreaks
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.textColorVariant
      )
    }),
    secondaryTextItemList: (
      props: TitleContentDataVerticalHorizontalItemStyleProps
    ) => ({
      ...themeTextMixin(
        theme,
        props.secondaryTextVariant ?? TextVariant.Body2,
        props.maxLines,
        props.showLineBreaks
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.textColorVariant
      )
    })
  }),
  "TitleContentDataVerticalHorizontalItemDynamicStyle"
);

export const useTitleContentDataVerticalHorizontalItemStyle = (
  props: TitleContentDataVerticalHorizontalItemStyleProps
): TitleContentDataVerticalHorizontalItemStyleClassNames => {
  const staticClasses = useTitleContentDataHorizontalItemStyleStatic();
  const dynamicClasses = useTitleContentDataHorizontalItemDynamicStyle(props);

  return {
    root: getClassName(
      staticClasses.root,
      props.isVertical ? staticClasses.rootVertical : undefined
    ),
    principalTextItemList: getClassName(dynamicClasses.principalTextItemList),
    secondaryTextItemList: getClassName(dynamicClasses.secondaryTextItemList)
  };
};
