import { getMapValueBuilder, SizeVariant } from "@laba/ts-common";

enum IconSize {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

export const getIconSize = (size: SizeVariant): IconSize => {
  const sizes = getMapValueBuilder({
    [SizeVariant.ExtraSmall]: IconSize.Small,
    [SizeVariant.Small]: IconSize.Small,
    [SizeVariant.Medium]: IconSize.Medium,
    [SizeVariant.Large]: IconSize.Medium
  });
  return sizes(size);
};
