import { ResourceType } from "model/primitives";
import {
  AppointmentCancellationReasonCode,
  AppointmentStatus
} from "model/resource/appointment/appointment";
import { PatientAppointmentStatistics } from "../patient";

export const patientAppointmentStatisticsMock: PatientAppointmentStatistics = {
  appointmentList: [
    {
      id: "139230",
      resourceType: ResourceType.Appointment,
      creationDate: "2025-01-07T10:14:36.000-03",
      status: AppointmentStatus.Cancelled,
      cancelDate: "2025-02-28T18:31:20.000Z",
      cancellationReason: AppointmentCancellationReasonCode.EncounterCancelled,
      startDate: "2025-02-26T05:00:00.000Z",
      endDate: "2025-02-26T05:30:00.000Z",
      patient: "105206",
      identifier: [],
      schedule: "91214",
      admissionDate: "2025-02-27T15:08:52.000Z",
      product: [
        { product: "86511", quantity: 1 },
        { product: "88553", quantity: 4 }
      ]
    },
    {
      id: "139199",
      resourceType: ResourceType.Appointment,
      creationDate: "2025-02-26T18:43:26.315Z",
      status: AppointmentStatus.Cancelled,
      cancelDate: "2025-02-28T18:23:35.000Z",
      cancellationReason: AppointmentCancellationReasonCode.EncounterCancelled,
      startDate: "2025-02-26T04:20:00.000Z",
      endDate: "2025-02-26T04:40:00.000Z",
      patient: "88968",
      identifier: [],
      schedule: "92691",
      product: []
    }
  ],
  patientDebt: [
    {
      currency: "ARS",
      value: 10000
    }
  ]
};
