import { BaseEvent, ThunkDispatch } from "store/types";
import {
  DefaultError,
  isModel,
  ModelId,
  Optional,
  RequestFailureResponse,
  RequestFailureStatus,
  RequestResponse
} from "@laba/ts-common";
import { CancelElementStateActions } from "state/commonCancelElementState/getCancelElementState";
import { CastedEventThunkAction } from "state/types";
import { ReturnCancelElementSelectors } from "state/commonCancelElementState/getCancelElementSelectors";

const defaultIdGetter = <T>(element?: T) => {
  return isModel(element) ? element.id : undefined;
};

export interface CancelElementReturnEvents<
  RootState,
  AppDispatch extends ThunkDispatch<RootState>,
  Element
> {
  onCloseElementCancelConfirmPopup: () => BaseEvent<
    void,
    RootState,
    AppDispatch
  >;
  onOpenElementCancelConfirmPopup: () => BaseEvent<
    void,
    RootState,
    AppDispatch
  >;
  onActionCancelElement: (
    element?: Element
  ) => BaseEvent<void, RootState, AppDispatch>;
  onCancelElement: (
    element?: Element
  ) => BaseEvent<void, RootState, AppDispatch>;
}

export const getCancelElementEvents = <
  RootState,
  AppDispatch extends ThunkDispatch<RootState>,
  Element,
  Error extends DefaultError
>(
  cancelElementSliceStateActions: CancelElementStateActions<Element>,
  cancelElementSliceSelectors: ReturnCancelElementSelectors<Element, RootState>,
  onRequestError: (
    errorResponse: RequestFailureResponse<Error>,
    dispatch: AppDispatch
  ) => Promise<void>,
  cancelElementRequest: (
    id: ModelId,
    data?: Element
  ) => Promise<RequestResponse<Element, Error>>,
  onAfterCancel?: () => BaseEvent<void, RootState, AppDispatch>,
  getIdFromElement: (element?: Element) => Optional<ModelId> = defaultIdGetter
): CancelElementReturnEvents<RootState, AppDispatch, Element> => {
  const onStopCancellingElement =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      dispatch(cancelElementSliceStateActions.setIsCancellingElement(false));
    };

  const onStartCancellingElement =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      dispatch(cancelElementSliceStateActions.setIsCancellingElement(true));
    };

  const onCloseElementCancelConfirmPopup =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      dispatch(
        cancelElementSliceStateActions.setIsCancelConfirmElementPopupOpen(false)
      );
      dispatch(cancelElementSliceStateActions.setSelectedElement(undefined));
    };

  const onOpenElementCancelConfirmPopup =
    (): BaseEvent<void, RootState, AppDispatch> => async dispatch => {
      dispatch(
        cancelElementSliceStateActions.setIsCancelConfirmElementPopupOpen(true)
      );
    };

  const onActionCancelElement =
    (element?: Element): BaseEvent<void, RootState, AppDispatch> =>
    async dispatch => {
      dispatch(cancelElementSliceStateActions.setSelectedElement(element));
      await dispatch(
        onOpenElementCancelConfirmPopup() as unknown as CastedEventThunkAction<RootState>
      );
    };

  const onCancelElement =
    (): BaseEvent<void, RootState, AppDispatch> =>
    async (dispatch, getState) => {
      await dispatch(
        onStartCancellingElement() as unknown as CastedEventThunkAction<RootState>
      );
      const element = cancelElementSliceSelectors.cancelElementSelector(
        getState()
      );

      const elementId = getIdFromElement(element);

      if (elementId === undefined) return;
      const cancelElementResponse = await cancelElementRequest(
        elementId,
        element
      );

      if (
        cancelElementResponse.failureStatus === RequestFailureStatus.Failure
      ) {
        await dispatch(
          onStopCancellingElement() as unknown as CastedEventThunkAction<RootState>
        );
        await dispatch(
          onCloseElementCancelConfirmPopup() as unknown as CastedEventThunkAction<RootState>
        );
        return onRequestError(cancelElementResponse, dispatch);
      }
      onAfterCancel &&
        (await dispatch(
          onAfterCancel() as unknown as CastedEventThunkAction<RootState>
        ));
      await dispatch(
        onCloseElementCancelConfirmPopup() as unknown as CastedEventThunkAction<RootState>
      );
      await dispatch(
        onStopCancellingElement() as unknown as CastedEventThunkAction<RootState>
      );
    };

  return {
    onCancelElement,
    onActionCancelElement,
    onCloseElementCancelConfirmPopup,
    onOpenElementCancelConfirmPopup
  };
};
