import {
  DateTime,
  dateTimeFromApiDateOrUndefined,
  ModelId,
  Optional
} from "@laba/ts-common";
import { isEmpty } from "lodash-es";
import { getModelOrUndefined, Money, ResourceType } from "model/primitives";
import { getFullNameFromPerson } from "model/resource/person";
import { Account } from "./account";
import { ProviderAccount } from "./providerAccount";
import {
  KnownProviderAccountTransactionStatus,
  KnownProviderAccountTransactionType,
  ProviderAccountTransaction
} from "./providerAccountTransaction";

export const PrivateAccount = "Paciente Particular";

export const getAccountPayerNameOrUndefined = (
  account?: Account
): Optional<string> => {
  return getModelOrUndefined(account?.payer)?.name;
};

export const getAccountPatientNameOrUndefined = (
  account?: Account
): Optional<string> => {
  const fullName = getFullNameFromPerson(getModelOrUndefined(account?.patient));
  return !isEmpty(fullName) ? fullName : undefined;
};

export const getAccountNameOrUndefined = (
  account?: Account
): Optional<string> => {
  return account?.name;
};

export const isAccountPrivateAccount = (account?: Account): boolean => {
  return account?.name === PrivateAccount;
};

export const createBaseEmptyProviderAccountTransaction = (
  organization?: ModelId,
  account?: ProviderAccount,
  amount?: Money,
  providerAccountTransactionType?: KnownProviderAccountTransactionType
): ProviderAccountTransaction => {
  return {
    resourceType: ResourceType.ProviderAccountTransaction,
    status: KnownProviderAccountTransactionStatus.Active,
    organization,
    account,
    amount,
    type: providerAccountTransactionType
  };
};

export const isProviderAccountTransactionPaymentType = (
  transaction?: ProviderAccountTransaction
): boolean => transaction?.type === KnownProviderAccountTransactionType.Payment;

export const getProviderAccountTransactionPaymentDate = (
  value?: ProviderAccountTransaction
): Optional<DateTime> => dateTimeFromApiDateOrUndefined(value?.paymentDate);
