import { ApiDate } from "@laba/ts-common";
import { Practitioner, Organization } from "model/resource";
import {
  Identifier,
  ModelReference,
  Money,
  ResourceModel,
  ResourceType
} from "model/primitives";

export enum AccountStatus {
  Active = "active",
  Inactive = "inactive"
}

export enum AccountType {
  Patient = "Patient",
  Payer = "Payer",
  PrivatePatient = "PrivatePatient",
  Provider = "Provider"
}

type AccountResourceType = ResourceType.Account | ResourceType.ProviderAccount;

export interface AccountBalance {
  amount?: Money;
  calculatedAt?: ApiDate;
}

export interface BaseAccount<RType extends ResourceType = AccountResourceType>
  extends ResourceModel<RType> {
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  organization?: ModelReference<Organization>;
  status?: AccountStatus;
  identifier?: Identifier[];
  name?: string;
  type?: AccountType;
  balance?: AccountBalance[];
}
