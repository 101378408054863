import { ApiRequestResponse } from "request/types";
import {
  WhiteLabelBrandData,
  WhiteLabelConfig
} from "model/resource/app/whiteLabelConfig";
import {
  HttpMethod,
  mergeObjects,
  RequestFailureStatus
} from "@laba/ts-common";
import { getFrontPublicApiUrl, requestAnon } from "request/nexupRequest";
import { BackendError } from "model/resource/error/error";
import produce from "immer";
import { defaultsDeep } from "lodash-es";

interface WhiteLabelConfigQueryParams {
  domainUrl: string;
}

const defaultHisBrandData: WhiteLabelBrandData = {
  browserTitle: "Nexup",
  name: "Nexup",
  loginTitle: "Bienvenido a Nexup",
  loginText: "Ingrese su usuario y contraseña",
  latitude: -34.60932719247859,
  longitude: -58.363887795102144,
  browserIcon: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  headerIcon: "https://storage.googleapis.com/nexup/static/nexup_logo.png",
  loginIcon: "https://storage.googleapis.com/nexup/static/nexup_logo.png",
  shareTitle: "Nexup",
  shareDescription:
    "Con Nexup optimizamos tus tiempos y accedes de forma fácil, rápida y segura a tu información médica",
  shareUrl: "https://nexup.com.ar/",
  shareImage: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  metadataTitle: "Nexup",
  metadataDescription:
    "Con Nexup optimizamos tus tiempos y accedes de forma fácil, rápida y segura a tu información médica",
  installName: "Nexup",
  installShortName: "Nexup",
  installDescription: "Nexup",
  installIconBig: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  installIconSmall: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  installBackground: "#3D81DB",
  showInstallAppBanner: true,
  showInstallAppBannerText:
    "Descarga Nexup en tu teléfono haciendo click en instalar",
  disableLogin: false
};

const defaultPatientBrandData: WhiteLabelBrandData = {
  browserTitle: "Nexup",
  name: "Nexup",
  loginTitle: "Bienvenido a Nexup",
  loginText: "Ingrese su usuario y contraseña",
  latitude: -34.60932719247859,
  longitude: -58.363887795102144,
  browserIcon: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  headerIcon: "https://storage.googleapis.com/nexup/static/nexup_logo.png",
  loginIcon: "https://storage.googleapis.com/nexup/static/nexup_logo.png",
  shareTitle: "Nexup",
  shareDescription:
    "Con Nexup accedes de forma fácil, rápida y segura a tu información médica",
  shareUrl: "https://patient.nexup.com.ar/",
  shareImage: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  metadataTitle: "Nexup",
  metadataDescription:
    "Con Nexup accedes de forma fácil, rápida y segura a tu información médica",
  installName: "Nexup",
  installShortName: "Nexup",
  installDescription: "Nexup",
  installIconBig: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  installIconSmall: "https://storage.googleapis.com/nexup/static/Iso_Nexup.jpg",
  installBackground: "#3D81DB",
  showInstallAppBanner: true,
  showInstallAppBannerText:
    "Descarga Nexup en tu teléfono haciendo click en instalar",
  disableLogin: false
};

const processHisWhiteLabelConfigBrandData = (
  whitelabelConfigBrandData?: WhiteLabelBrandData
): WhiteLabelBrandData => {
  return mergeObjects(defaultHisBrandData, whitelabelConfigBrandData);
};

const processPatientWhiteLabelConfigBrandData = (
  whitelabelBrandData?: WhiteLabelBrandData
): WhiteLabelBrandData => {
  return mergeObjects(defaultPatientBrandData, whitelabelBrandData);
};

export const getProcessedWhiteLabelConfigWithDefaultValues = (
  whiteLabelConfig: WhiteLabelConfig
): WhiteLabelConfig => {
  return produce(whiteLabelConfig, draft => {
    draft.hisBrandData = processHisWhiteLabelConfigBrandData(
      defaultsDeep({}, draft.hisBrandData, draft.brandData)
    );
    draft.patientBrandData = processPatientWhiteLabelConfigBrandData(
      defaultsDeep({}, draft.patientBrandData, draft.brandData)
    );
  });
};

export const getWhiteLabelConfig = async (
  params?: WhiteLabelConfigQueryParams
): Promise<ApiRequestResponse<WhiteLabelConfig>> => {
  const response = await requestAnon<WhiteLabelConfig, BackendError>({
    method: HttpMethod.GET,
    url: getFrontPublicApiUrl("/config"),
    params
  });

  if (response.failureStatus === RequestFailureStatus.Failure) {
    return response;
  }

  const whiteLabelConfig = response.data;

  return produce(response, draft => {
    draft.data =
      getProcessedWhiteLabelConfigWithDefaultValues(whiteLabelConfig);
  });
};
