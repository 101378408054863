import { Code } from "model/resource";
import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export interface Money {
  value?: number;
  currency?: Code;
}

export const MoneyKey = getKeyObj<Money>(createHydratedMock<Money>());
