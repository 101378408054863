import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  hoverMixin,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor } from "model/themeUtils";
import { TextVariant, StyleVariant } from "model/themeVariant";
import { useUrlLinkTextStyle } from "./useUrlLinkTextStyle";

interface UrlLinkDynamicStylesProps {
  textVariant: TextVariant;
  disabled: boolean;
  multiline: boolean;
  textUnderline: boolean;
  textStyleVariant?: StyleVariant;
}

interface UrlLinkClassNames {
  root: string;
  text: string;
  underlinedOnHover: string;
  icon?: string;
}

const useUrlLinkStaticStyles = createStyle(
  (theme: Theme) => ({
    underlinedOnHover: {
      ...hoverMixin({
        textDecoration: "underline"
      })
    },
    iconDisabled: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayLight
      )
    },
    textUnderline: {
      textDecoration: "underline"
    }
  }),
  "UrlLinkStatic"
);

const useUrlLinkDynamicStyles = createStyle(
  (theme: Theme) => ({
    root: (props: UrlLinkDynamicStylesProps) => ({
      ...flexRowMixin({
        gap: 4,
        alignItems: props.multiline
          ? FlexAlignItems.FlexStart
          : FlexAlignItems.Center
      }),
      maxWidth: "100%",
      color: getThemeStyleColor(theme, StyleVariant.Primary),
      cursor: "pointer",
      textAlign: "left"
    })
  }),
  "UrlLinkDynamic"
);

export const useUrlLinkStyle = (
  props: UrlLinkDynamicStylesProps
): UrlLinkClassNames => {
  const staticClasses = useUrlLinkStaticStyles();
  const dynamicClasses = useUrlLinkDynamicStyles(props);
  const textClasses = useUrlLinkTextStyle({
    textVariant: props.textVariant,
    disabled: props.disabled,
    multiline: props.multiline,
    textUnderline: props.textUnderline,
    textStyleVariant: props.textStyleVariant
  });

  return {
    root: dynamicClasses.root,
    underlinedOnHover: staticClasses.underlinedOnHover,
    text: textClasses.text,
    icon: props.disabled ? staticClasses.iconDisabled : undefined
  };
};
