import { ApiDate, getKeyObj, ModelId } from "@laba/ts-common";
import {
  Attachment,
  ModelReference,
  Money,
  Quantity,
  ResourceModel,
  ResourceType,
  SourceResource
} from "model/primitives";
import { Encounter } from "model/resource/entities/encounter/encounter";
import { Patient, TaxType } from "model/resource/person/patient/patient";
import { Location } from "model/resource/entities/location/location";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Organization } from "model/resource/entities/organization/organization";
import { Code } from "model/resource/entities/codeSystem";
import { KnownDomainResourceLogicTag } from "model/primitives/domainResource";
import { createHydratedMock } from "ts-auto-mock";
import {
  BillingContract,
  Product,
  ProviderAccountTransaction
} from "model/resource";
import { Invoice } from "../invoice";

export interface ConsumptionPerformer {
  practitioner: ModelReference<Practitioner>;
  id?: ModelId;
}

export enum ConsumptionPaymentDebtType {
  None = "None",
  Partial = "Partial",
  All = "All"
}

export enum ChargeItemStatus {
  billable = "billable",
  aborted = "aborted",
  planned = "planned"
}

export enum ChargeItemCode {
  ConsumptionGroup = "ConsumptionGroup",
  ConsumptionItem = "ConsumptionItem"
}

export enum ConsumptionCreationType {
  Consumption = "Consumption",
  Budget = "Budget"
}

export interface Consumption<
  RType extends ResourceType =
    | ResourceType.Consumption
    | ResourceType.ConsumptionGroup
    | ResourceType.ConsumptionItem
> extends ResourceModel<RType> {
  status?: ChargeItemStatus;
  code?: string;
  patient?: ModelReference<Patient>;
  occurrence?: ApiDate;
  performer?: ConsumptionPerformer[];
  payer?: ModelReference<Organization>;
  location?: ModelReference<Location>;
  lastEditor?: ModelReference<Practitioner>;
  originalPractitioner?: ModelReference<Practitioner>;
  creationDate?: ApiDate;
  lastEditDate?: ApiDate;
  encounter?: ModelReference<Encounter>;
  plan?: string;
  taxType?: TaxType;
  observation?: string;
  billingContract?: ModelReference<BillingContract>;
  validityPeriodEndDate?: ApiDate;
  metaTagList?: KnownDomainResourceLogicTag[];
}

export interface ConsumptionGroup
  extends Consumption<ResourceType.ConsumptionGroup> {
  coveragePaymentStatus?: Code;
  patientPaymentStatus?: Code;
  patientDebt?: Money;
  coverageDebt?: Money;
  patientPaymentMethod?: Code;
  invoice?: Attachment[];
  receipt?: Attachment[];
  sourceResource?: SourceResource;
  patientInstallmentCode?: Code;
}

export interface ConsumptionItem
  extends Consumption<ResourceType.ConsumptionItem> {
  patientPayment?: Money;
  coveragePayment?: Money;
  patientDiscount?: number;
  product?: ModelReference<Product>;
  partOf?: ModelReference<ConsumptionGroup>;
  quantity?: Quantity;
  billingContractTermId?: ModelId;
  breakdown?: boolean;
}

export interface CurrentConsumptionGroupWithExtraData {
  current: ConsumptionGroupWithExtraData;
}

export interface ConsumptionGroupWithExtraData {
  consumptionGroup?: ConsumptionGroup;
  consumptionItemList?: ConsumptionItem[];
  coverageInvoice?: ModelReference<Invoice>;
  patientInvoice?: ModelReference<Invoice>;
  accountTransactionList?: ProviderAccountTransaction[];
}

export const ConsumptionGroupWithExtraDataParamsKey =
  getKeyObj<ConsumptionGroupWithExtraData>(
    createHydratedMock<ConsumptionGroupWithExtraData>()
  );

export const ConsumptionItemParamsKey = getKeyObj<ConsumptionItem>(
  createHydratedMock<ConsumptionItem>()
);
