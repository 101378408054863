import { ApiDate, getKeyObj, KeyObj, Model } from "@laba/ts-common";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Period } from "model/resource/utils/availableTime";
import { Location } from "model/resource/entities/location/location";
import { Code } from "model/resource/entities/codeSystem";
import {
  Identifier,
  Attachment,
  ModelReference,
  Money,
  ResourceModel,
  ResourceType
} from "model/primitives";
import { Organization } from "model/resource/entities/organization/organization";
import { HospitalizationType } from "api/medical/hospitalization";
import { ConsumptionGroup } from "model/resource/finance/consumption";
import { Account } from "model/resource/finance/account/account";
import {
  Patient as ApiPatient,
  TaxType
} from "model/resource/person/patient/patient";
import { createHydratedMock } from "ts-auto-mock";

export enum InvoiceStatus {
  Cancelled = "cancelled",
  Draft = "draft",
  Issued = "issued",
  Balanced = "balanced"
}

export enum InvoiceType {
  Patient = "patient",
  Payer = "payer"
}

export interface InvoiceLocation extends Model {
  location: ModelReference<Location>;
}

export interface Invoice extends ResourceModel<ResourceType.Invoice> {
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  status?: InvoiceStatus;
  period: Period;
  location?: InvoiceLocation[];
  issuerCode?: Code;
  plan?: Code[];
  invoiceDate?: ApiDate;
  invoiceFile?: Attachment[];
  statusReason?: Code;
  cancelledReason?: string;
  creditNote?: Attachment[];
  debt?: Money;
  receipt?: Attachment[];
  total?: Money;
  organization?: ModelReference<Organization>;
  taxType?: TaxType;
  hospitalizationType?: HospitalizationType;
  identifier?: Identifier[];
  consumptionGroupList?: ConsumptionGroup[];
  account?: ModelReference<Account>;
  preInvoiceFile?: Attachment;
  type?: InvoiceType;
  paymentMethod?: Code;
  patient?: ModelReference<ApiPatient>[];
}

export const InvoiceParamsKey: KeyObj<Invoice> = getKeyObj(
  createHydratedMock<Invoice>()
);

export const isPayerInvoice = (invoice?: Invoice): boolean =>
  invoice?.type === InvoiceType.Payer;

export const isPatientInvoice = (invoice?: Invoice): boolean =>
  invoice?.type === InvoiceType.Patient;
