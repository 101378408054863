import { ApiDate, getKeyObj, KeyObj } from "@laba/ts-common";
import {
  Identifier,
  ModelReference,
  Money,
  ResourceModel,
  ResourceType
} from "model/primitives";
import { Practitioner } from "model/resource/person/practitioner/practitioner";
import { Organization } from "model/resource/entities/organization/organization";
import { ProviderAccount } from "model/resource/finance/account";
import { Code } from "model/resource/entities";
import { ConsumptionGroup } from "model/resource/finance/consumption";
import { createHydratedMock } from "ts-auto-mock";

export enum KnownPaymentReconciliationKind {
  AccountTransaction = "AccountTransaction"
}

export enum KnownProviderAccountTransactionStatus {
  Active = "active",
  Cancelled = "cancelled"
}

export enum KnownProviderAccountTransactionType {
  Payment = "Payment",
  Withdraw = "Withdraw",
  Deposit = "Deposit"
}

export interface ProviderAccountTransaction
  extends ResourceModel<ResourceType.ProviderAccountTransaction> {
  kind?: KnownPaymentReconciliationKind;
  originalPractitioner?: ModelReference<Practitioner>;
  lastEditor?: ModelReference<Practitioner>;
  status?: KnownProviderAccountTransactionStatus;
  account?: ModelReference<ProviderAccount>;
  amount?: Money;
  details?: string;
  paymentMethod?: Code;
  type?: KnownProviderAccountTransactionType;
  organization?: ModelReference<Organization>;
  paymentDate?: ApiDate;
  consumption?: ModelReference<ConsumptionGroup>;
  identifier?: Identifier[];
  exchangeRate?: Money;
  convertedAmount?: Money;
  summary?: string;
}

export const ProviderAccountTransactionParamsKey: KeyObj<ProviderAccountTransaction> =
  getKeyObj(createHydratedMock<ProviderAccountTransaction>());
