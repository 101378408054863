import { FORM_ERROR } from "final-form";
import { I18nT } from "translation/i18nT";
import { isArray, Optional } from "@laba/ts-common";

export { FORM_ERROR } from "final-form";

// eslint-disable-next-line @typescript-eslint/ban-types
type Obj = object;

export interface FormErrorBaseData {
  [FORM_ERROR]?: string;
}

export type FormErrorData<T> = Partial<{
  [k in keyof T]: T[k] extends Obj ? FormErrorData<T[k]> : Optional<string>;
}> &
  FormErrorBaseData;

export type FormValidator<FormData> = (
  t: I18nT,
  data: FormData
) => Optional<FormErrorData<FormData>>;

export type FormSubmitValidator<Error, FormData> = (
  t: I18nT,
  error?: Error
) => Optional<FormErrorData<FormData>>;

export const isFormErrorData = <T>(
  value: unknown
): value is FormErrorData<T> => {
  if (typeof value !== "object" || value === null) {
    return false;
  }

  if (
    Object.prototype.hasOwnProperty.call(value, FORM_ERROR) &&
    typeof (value as FormErrorBaseData)[FORM_ERROR] !== "string"
  ) {
    return false;
  }

  return Object.keys(value).every(key => {
    const val = (value as Record<string, unknown>)[key];
    return (
      val === undefined ||
      typeof val === "string" ||
      (typeof val === "object" && !isArray(val) && isFormErrorData(val))
    );
  });
};
