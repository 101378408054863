import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  createStyle,
  FlexAlignItems,
  flexColumnMixin,
  flexRowMixin,
  getClassName,
  pxToRem,
  StyleVariant,
  TextVariant
} from "@laba/react-common";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";

interface Props {
  maxLines?: number;
  fullWidth?: boolean;
  titleVariant?: TextVariant;
  secondaryTextVariant?: TextVariant;
  columnGap: number;
  titleColorVariant: ThemeStyleColorVariant;
  textColorVariant: ThemeStyleColorVariant;
  reverseOrder: boolean;
}

interface TitleContentDataStyleClassNames {
  root: string;
  title: string;
  defaultSecondaryText: string;
  icon: string;
  titleIconContainer: string;
}

const useTitleContentDataStyleStatic = createStyle(
  (theme: Theme) => ({
    secondaryTextVerticalAlign: {
      ...flexColumnMixin({
        wrapContent: true
      })
    },
    fullWidth: {
      width: "100%"
    },
    icon: {
      height: pxToRem(20),
      width: pxToRem(20),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayMedium
      )
    },
    titleIconContainer: {
      ...flexRowMixin({ gap: 2, alignItems: FlexAlignItems.Center })
    }
  }),
  "TitleContentDataStatic"
);

const useTitleContentDataStyleDynamic = createStyle(
  (theme: Theme) => ({
    root: (props: Props) => ({
      ...flexColumnMixin({
        gap: props.columnGap,
        reverseOrder: props.reverseOrder
      })
    }),
    title: (props: Props) => ({
      ...themeTextMixin(theme, props.titleVariant ?? TextVariant.Caption),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.titleColorVariant
      )
    }),
    defaultSecondaryText: (props: Props) => ({
      ...themeTextMixin(
        theme,
        props.secondaryTextVariant ?? TextVariant.Body2,
        props.maxLines
      ),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        props.textColorVariant
      )
    })
  }),
  "TitleContentDataDynamic"
);

export const useTitleContentDataStyle = (
  props: Props
): TitleContentDataStyleClassNames => {
  const staticClasses = useTitleContentDataStyleStatic();
  const dynamicClasses = useTitleContentDataStyleDynamic(props);

  return {
    root: getClassName(
      dynamicClasses.root,
      props.fullWidth ? staticClasses.fullWidth : undefined
    ),
    title: dynamicClasses.title,
    defaultSecondaryText: dynamicClasses.defaultSecondaryText,
    icon: staticClasses.icon,
    titleIconContainer: staticClasses.titleIconContainer
  };
};
