import { Optional } from "@laba/ts-common";
import {
  getResourceListText,
  getResourceListTitleText
} from "model/resource/utils";
import { Product } from "./product";

const getProductTitle = (product: Product): string => product.title ?? "";

export const getProductListText = (productList: Product[]): Optional<string> =>
  getResourceListText(productList, getProductTitle);

export const getProductListTitleText = (
  productList: Product[]
): Optional<string> => getResourceListTitleText(productList, getProductTitle);
