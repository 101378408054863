import { ResourceType } from "model/primitives/resourceModel";
import {
  AccountStatus,
  AccountType,
  ProviderAccount
} from "model/resource/finance/account";

export const providerAccountMock: ProviderAccount = {
  resourceType: ResourceType.ProviderAccount,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "27",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  balance: [
    {
      amount: {
        currency: "USD",
        value: 10000
      }
    },
    {
      amount: {
        currency: "ARS",
        value: 10000000
      }
    }
  ],
  name: "Caja 1",
  type: AccountType.Provider,
  status: AccountStatus.Active,
  paymentMethod: ["Efectivo", "Transferencia"]
};

export const providerAccountMock2: ProviderAccount = {
  resourceType: ResourceType.ProviderAccount,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "26",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  balance: [
    {
      amount: {
        currency: "ARS",
        value: 2000000
      }
    }
  ],
  name: "Caja 2",
  type: AccountType.Provider,
  status: AccountStatus.Inactive,
  paymentMethod: ["Transferencia"]
};

export const providerAccountMock3: ProviderAccount = {
  resourceType: ResourceType.ProviderAccount,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "28",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  balance: [
    {
      amount: {
        currency: "USD",
        value: 500
      }
    }
  ],
  name: "Caja 3",
  type: AccountType.Provider,
  status: AccountStatus.Active,
  paymentMethod: ["Payoneer"]
};

export const providerAccountMock4: ProviderAccount = {
  resourceType: ResourceType.ProviderAccount,
  creationDate: "2023-12-22T23:59:59.999-03",
  id: "29",
  lastEditDate: "2023-12-22T23:59:59.999-03",
  balance: [
    {
      amount: {
        currency: "USD",
        value: 20000
      }
    },
    {
      amount: {
        currency: "ARS",
        value: 20000000
      }
    }
  ],
  name: "Caja 4",
  type: AccountType.Provider,
  status: AccountStatus.Active,
  paymentMethod: ["Mercado Pago"]
};
