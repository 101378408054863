import {
  createStyle,
  GridAutoFlow,
  gridContainerMixin,
  gridItemMixin,
  Theme
} from "@laba/react-common";

interface CheckboxGroupDynamicStylesProps {
  gridColumns: number;
  rowGap?: number;
  columnGap?: number;
}

interface PrescriptionItemClassNames {
  root?: string;
  lastChild?: string;
  itemsWrapper?: string;
}

const useCheckboxGroupStaticStyles = createStyle(
  (_theme: Theme) => ({
    root: { width: "100%" },
    lastChild: {
      ...gridItemMixin({
        columnSpan: 2
      })
    }
  }),
  "CheckboxGroupStatic"
);

const useCheckboxGroupDynamicStyles = createStyle(
  (_theme: Theme) => ({
    itemsWrapper: (props: CheckboxGroupDynamicStylesProps) => ({
      ...gridContainerMixin({
        templateColumn: [`repeat(${props.gridColumns}, minmax(0, 1fr))`],
        autoFlow: GridAutoFlow.Row,
        rowGap: props.rowGap,
        columnGap: props.columnGap
      })
    })
  }),
  "CheckboxGroupDynamic"
);

export const useCheckboxGroupStyles = (
  props: CheckboxGroupDynamicStylesProps
): PrescriptionItemClassNames => {
  const staticClasses = useCheckboxGroupStaticStyles();
  const dynamicClasses = useCheckboxGroupDynamicStyles(props);

  return {
    root: staticClasses.root,
    lastChild: staticClasses.lastChild,
    itemsWrapper: dynamicClasses.itemsWrapper
  };
};
