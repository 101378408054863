import { ReactElementOrNull } from "@laba/react-common";
import { QuantitySelector, QuantitySelectorProps } from "components/inputs";
import React from "react";
import {
  AutocompleteOption,
  AutocompleteOptionProps
} from "../AutocompleteOption/AutocompleteOption";

export interface AutocompleteOptionWithQuantitySelectorProps<T>
  extends AutocompleteOptionProps<T>,
    QuantitySelectorProps {}

export const AutocompleteOptionWithQuantitySelector = <T,>({
  option,
  quantity,
  decreaseQuantityButtonTitle,
  increaseQuantityButtonTitle,
  onChangeQuantity,
  onDeleteTrashIcon,
  showTrashIcon,
  maxValue,
  minValue,
  ...rest
}: AutocompleteOptionWithQuantitySelectorProps<T>): ReactElementOrNull => {
  return (
    <AutocompleteOption {...rest} option={option}>
      <QuantitySelector
        quantity={quantity}
        decreaseQuantityButtonTitle={decreaseQuantityButtonTitle}
        increaseQuantityButtonTitle={increaseQuantityButtonTitle}
        onChangeQuantity={onChangeQuantity}
        onDeleteTrashIcon={onDeleteTrashIcon}
        showTrashIcon={showTrashIcon}
        maxValue={maxValue}
        minValue={minValue}
      />
    </AutocompleteOption>
  );
};
