import React from "react";
import { FC, useI18n } from "@laba/react-common";
import {
  getFullNameFromPerson,
  HospitalizationStatus,
  KnownIdentifierSystem,
  Location,
  LocationListQueryParamsKey,
  OrganizationCombinedType,
  OrganizationKey,
  OrganizationListQueryParamsKey,
  Patient,
  PatientKey,
  PatientListQueryParamsKey,
  PatientRoleKey,
  ResourceType,
  UserKey,
  ValidationStatus
} from "@laba/nexup-api";
import { tkCP } from "translation/i18n";
import { ReferenceResourceInput } from "components/generic/ReferenceResourceInput/ReferenceResourceInput";
import { EnumSelectorInput } from "components/generic/EnumSelectorInput/EnumSelectorInput";
import { TextField } from "components/generic/TextField/TextField";
import { FunctionField } from "components/generic/FunctionField/FunctionField";
import { ReferenceResourceField } from "components/generic/ReferenceResourceField/ReferenceResourceField";
import { UserResourceType } from "providers/dataProvider/resourceProvider/utils/resourceProviderGetter";
import { DateField } from "components/generic/DateField/DateField";
import { List } from "components/generic/List/List";
import { Datagrid } from "components/generic/Datagrid/Datagrid";
import { patientJsonToModel } from "components/pages/NexupAdmin/resources/patient/PatientForm";
import { ArrayField, ChipField, SingleFieldList } from "react-admin";
import { useSelector } from "react-redux";
import { workspaceOrganizationIdSelector } from "store/workspace/selectors";
import { locationOptionText } from "components/generic/ReferenceResourceInput/optionText";
import { useListExportedFileName } from "components/hook/useExportedFileName";
import { useHasPermission } from "components/hook/UseHasPermission";
import { OrganizationReferenceInput } from "components/generic/OrganizationReferenceInput/OrganizationReferenceInput";
import { BooleanInput } from "components/generic/BooleanInput/BooleanInput";

const tk = tkCP.adminPage[ResourceType.Patient];

export const PatientList: FC = () => {
  const { t } = useI18n();
  const workspaceOrganizationId = useSelector(workspaceOrganizationIdSelector);
  const exportedFileName = useListExportedFileName(t(tk.title));
  const hasUserPermissions = useHasPermission(UserResourceType);

  return (
    <List
      resource={ResourceType.Patient}
      exportedFileName={exportedFileName}
      jsonToModel={patientJsonToModel}
      filterDefaultValues={{
        [PatientListQueryParamsKey.active]: true
      }}
      extraFilters={[
        workspaceOrganizationId === undefined ? (
          <OrganizationReferenceInput
            label={t(tk.filters.organization)}
            source={PatientListQueryParamsKey.organization}
            alwaysOn
            type={OrganizationCombinedType.ProviderAndGroup}
            extraFilters={{
              [OrganizationListQueryParamsKey.active]: true
            }}
          />
        ) : undefined,
        <ReferenceResourceInput<Location>
          resourceType={ResourceType.Location}
          label={t(tk.filters.currentLocation)}
          source={PatientListQueryParamsKey.currentLocation}
          optionTextKey={locationOptionText}
          alwaysOn
          extraFilters={{
            [LocationListQueryParamsKey.organization]: workspaceOrganizationId
          }}
        />,
        <EnumSelectorInput
          source={PatientListQueryParamsKey.hospitalizationStatus}
          label={t(tk.filters.hospitalizationStatus)}
          enumValue={HospitalizationStatus}
          alwaysOn
          resettable
        />,
        <EnumSelectorInput
          source={PatientListQueryParamsKey.validationStatus}
          label={t(tk.filters.validationStatus)}
          enumValue={ValidationStatus}
          alwaysOn
          resettable
        />,
        <BooleanInput
          source={PatientListQueryParamsKey.active}
          label={t(tk.fields.active)}
          alwaysOn
        />
      ]}
    >
      <Datagrid>
        <TextField source={PatientKey.id} label={t(tk.fields.id)} />
        <FunctionField<Patient>
          label={t(tk.fields.name)}
          render={getFullNameFromPerson}
        />
        <DateField
          label={t(tk.fields.birthDate)}
          source={PatientKey.personalData.birthDate}
        />
        <FunctionField<Patient>
          label={t(tk.fields.identifier)}
          render={record => {
            const identifierList = record?.personalData.identifierList ?? [];
            const dni = identifierList.find(
              i => i.system === KnownIdentifierSystem.Dni
            )?.value;
            if (dni != null) return t(tk.fields.identifierDni, { value: dni });
            const passport = identifierList.find(
              i => i.system === KnownIdentifierSystem.Passport
            )?.value;
            if (passport != null)
              return t(tk.fields.identifierPassport, { value: passport });
            return t(tk.fields.identifierNull);
          }}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.organization)}
          source={PatientKey.organization}
          optionTextKey={OrganizationKey.name}
        />
        <ReferenceResourceField
          resourceType={ResourceType.Organization}
          label={t(tk.fields.healthcarePayer)}
          source={PatientKey.patientData.healthcarePayer.provider}
          optionTextKey={OrganizationKey.name}
        />
        <FunctionField<Patient>
          label={t(tk.fields.healthcarePayerId)}
          render={record => {
            const data = record?.patientData?.healthcarePayer;
            const id = data?.credentialNumber;
            const plan = data?.plan;
            if (id && plan) return `${id} (${plan})`;
            if (id) return id;
            return "";
          }}
        />
        {hasUserPermissions && (
          <ReferenceResourceField
            resourceType={UserResourceType}
            label={t(tk.fields.userName)}
            source={PatientKey.user}
            optionTextKey={UserKey.username}
          />
        )}
        {hasUserPermissions && (
          <ArrayField source={PatientKey.roleList} label={t(tk.fields.role)}>
            <SingleFieldList>
              <ChipField source={PatientRoleKey.role} />
            </SingleFieldList>
          </ArrayField>
        )}
      </Datagrid>
    </List>
  );
};
