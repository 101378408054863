import {
  FCC,
  getClassName,
  IconC,
  TextVariant,
  ThemeStyleColorVariant
} from "@laba/react-common";
import { NexupIconButton } from "components/buttons/IconButton/IconButton";
import { RemoveIcon } from "components/icons";
import { TypeInvertedVariant } from "components/utils/getGenericButtonColors";
import React, { useMemo } from "react";
import { isEmpty } from "lodash-es";
import { Noop, StyleVariant } from "@laba/ts-common";
import { useGenericFieldWrapperStyles } from "./useGenericFieldWrapperStyles";
import { formatFieldTitle } from "./utils";
import {
  GenericFieldWrapperTitleComponent,
  GenericFieldWrapperTitleComponentProps
} from "./GenericFieldWrapperTitleComponent";

export enum FieldWrapperTitleComponentPosition {
  Top = "Top",
  Bottom = "Bottom"
}

const getFormattedTitle = (
  title?: string,
  required?: boolean,
  showEmptyTitle?: boolean
) => {
  const formattedTitle = formatFieldTitle(title, required);
  // TODO HIS-14946 Evitar tener que usar el caracter especial "U+200E"
  return !isEmpty(formattedTitle)
    ? formattedTitle
    : showEmptyTitle
    ? "‎"
    : formattedTitle;
};

export interface GenericFieldWrapperProps {
  title?: string;
  subTitle?: string;
  className?: string;
  titleVariant?: TextVariant;
  titleStyleVariant?: StyleVariant;
  titleColorVariant?: ThemeStyleColorVariant;
  subTitleVariant?: TextVariant;
  subTitleColorVariant?: ThemeStyleColorVariant;
  gap?: number;
  isMobile?: boolean;

  withIconButton?: boolean;
  icon?: IconC;
  iconTypeInvertedVariant?: TypeInvertedVariant;
  onIconButtonClick?: Noop;
  iconButtonTitle?: string;
  disableIconButton?: boolean;

  showEmptyTitle?: boolean;
  hide?: boolean;

  headerComponentProps?: GenericFieldWrapperTitleComponentProps;
  headerTitleComponentPosition?: FieldWrapperTitleComponentPosition;

  withTitle?: boolean;
  required?: boolean;
  fullWidth?: boolean;

  titleMaxLines?: number;
}

export const GenericFieldWrapper: FCC<GenericFieldWrapperProps> = ({
  title,
  subTitle,
  children,
  className,
  titleVariant,
  titleColorVariant = ThemeStyleColorVariant.GrayDark,
  titleStyleVariant = StyleVariant.Primary,
  subTitleVariant,
  subTitleColorVariant = ThemeStyleColorVariant.GrayLight,
  gap,
  isMobile,
  withIconButton = false,
  icon = RemoveIcon,
  iconTypeInvertedVariant = TypeInvertedVariant.Outlined,
  onIconButtonClick,
  iconButtonTitle,
  disableIconButton,
  showEmptyTitle = false,
  hide = false,
  withTitle = true,
  headerComponentProps,
  headerTitleComponentPosition = FieldWrapperTitleComponentPosition.Top,
  required,
  fullWidth = false,
  titleMaxLines = 1
}) => {
  const classes = useGenericFieldWrapperStyles({
    titleVariant,
    titleStyleVariant,
    titleColorVariant,
    subTitleVariant: subTitleVariant ?? titleVariant,
    subTitleColorVariant,
    gap,
    isMobile,
    fullWidth,
    titleMaxLines
  });

  const fixedTitle = getFormattedTitle(title, required, showEmptyTitle);

  const isHeaderTitleComponentTop =
    headerTitleComponentPosition === FieldWrapperTitleComponentPosition.Top;

  const HeaderTitleComponentMemo = useMemo(
    () => (
      <GenericFieldWrapperTitleComponent
        {...headerComponentProps}
        className={classes.titleComponent}
      />
    ),
    [headerComponentProps, classes.titleComponent]
  );

  return !hide ? (
    withTitle ? (
      <div className={getClassName(classes.root, className)}>
        {(fixedTitle || subTitle || withIconButton) && (
          <div className={classes.fieldHeading}>
            <div className={classes.titleContainer}>
              {fixedTitle && (
                <p className={classes.title} title={fixedTitle}>
                  {fixedTitle}
                </p>
              )}
              {subTitle && (
                <p className={classes.subTitle} title={subTitle}>
                  {subTitle}
                </p>
              )}
            </div>
            {withIconButton && (
              <NexupIconButton
                Icon={icon}
                type={iconTypeInvertedVariant}
                onClick={onIconButtonClick}
                title={iconButtonTitle}
                disabled={disableIconButton}
              />
            )}
            {isHeaderTitleComponentTop && HeaderTitleComponentMemo}
          </div>
        )}
        {children}
        {!isHeaderTitleComponentTop && HeaderTitleComponentMemo}
      </div>
    ) : (
      <div className={getClassName(classes.root, className)}>{children}</div>
    )
  ) : null;
};
