import { getKeyObj, KeyObj } from "@laba/ts-common";
import { ModelReference, ResourceType } from "model/primitives";
import { createHydratedMock } from "ts-auto-mock";
import {
  BaseAccount,
  AccountType,
  AccountStatus,
  Code,
  Organization
} from "model/resource";

export interface ProviderAccount
  extends BaseAccount<ResourceType.ProviderAccount> {
  paymentMethod?: Code[];
}

export const ProviderAccountParamsKey: KeyObj<ProviderAccount> = getKeyObj(
  createHydratedMock<ProviderAccount>()
);

interface CreateBaseProviderAccountProps {
  organization?: ModelReference<Organization>;
  name?: string;
}

export const createBaseProviderAccount = (
  props: CreateBaseProviderAccountProps
): ProviderAccount => ({
  resourceType: ResourceType.ProviderAccount,
  status: AccountStatus.Active,
  name: props.name,
  organization: props.organization,
  type: AccountType.Provider
});
