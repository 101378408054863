import {
  ApiPageRequestResponse,
  ApiRequestResponse,
  ListQueryParams
} from "request/types";
import { getFrontApiUrl, request } from "request/nexupRequest";
import { ApiDate, getKeyObj, HttpMethod, ModelId } from "@laba/ts-common";
import {
  ProviderAccountTransaction,
  KnownProviderAccountTransactionStatus,
  KnownProviderAccountTransactionType
} from "model/resource/finance/account/providerAccountTransaction";
import { createHydratedMock } from "ts-auto-mock";
import { Code } from "model/resource/entities";
import { urlJoin } from "url-join-ts";

const ProviderAccountTransactionPath = "/provider-account-transaction";

export enum ProviderAccountTransactionListOrder {
  PaymentDate = "paymentDate",
  CreationDate = "creationDate",
  LastUpdateDate = "lastUpdateDate"
}

export interface ProviderAccountTransactionListQueryParams
  extends ListQueryParams {
  account?: ModelId[];
  consumption?: ModelId[];
  content?: string;
  endDate?: ApiDate;
  lastEditor?: ModelId;
  organization?: ModelId[];
  originalPractitioner?: ModelId;
  paymentMethod?: Code[];
  startDate?: ApiDate;
  status?: KnownProviderAccountTransactionStatus;
  type?: KnownProviderAccountTransactionType;
  withAccount?: boolean;
  withConsumption?: boolean;
  withOrganization?: boolean;
  withPractitioner?: boolean;
  order?: ProviderAccountTransactionListOrder;
}

export const ProviderAccountTransactionListQueryParamsKey = getKeyObj(
  createHydratedMock<ProviderAccountTransactionListQueryParams>()
);

export const getProviderAccountTransaction = (
  id: ModelId
): Promise<ApiRequestResponse<ProviderAccountTransaction>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(urlJoin(ProviderAccountTransactionPath, id))
  });
};

export const getProviderAccountTransactionList = (
  params?: ProviderAccountTransactionListQueryParams
): Promise<ApiPageRequestResponse<ProviderAccountTransaction>> => {
  return request({
    method: HttpMethod.GET,
    url: getFrontApiUrl(ProviderAccountTransactionPath),
    params
  });
};

export const createProviderAccountTransaction = (
  data: ProviderAccountTransaction
): Promise<ApiRequestResponse<ProviderAccountTransaction>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(ProviderAccountTransactionPath),
    data
  });

export const editProviderAccountTransaction = (
  data: ProviderAccountTransaction
): Promise<ApiRequestResponse<ProviderAccountTransaction>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(urlJoin(ProviderAccountTransactionPath, data.id)),
    data
  });
};

export const cancelProviderAccountTransaction = (
  id: ModelId,
  data?: ProviderAccountTransaction
): Promise<ApiRequestResponse<ProviderAccountTransaction>> => {
  return request({
    method: HttpMethod.PUT,
    url: getFrontApiUrl(urlJoin(ProviderAccountTransactionPath, id)),
    data: { ...data, status: KnownProviderAccountTransactionStatus.Cancelled }
  });
};

export interface ProviderAccountTransactionExportQueryParams {
  email?: string;
  organization?: ModelId;
  account?: ModelId;
  paymentMethod?: Code;
  type?: KnownProviderAccountTransactionType;
  startDate?: ApiDate;
  endDate?: ApiDate;
}

export const exportProviderAccountTransaction = (
  data?: ProviderAccountTransactionExportQueryParams
): Promise<ApiRequestResponse<Blob>> =>
  request({
    method: HttpMethod.POST,
    url: getFrontApiUrl(urlJoin(ProviderAccountTransactionPath, "export")),
    data,
    responseType: "blob"
  });
