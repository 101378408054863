import React, { useCallback, useMemo, useState } from "react";
import {
  Color,
  FC,
  getClassName,
  IconC,
  OnBlurEvent,
  ReactElement
} from "@laba/react-common";
import {
  Autocomplete as MuiAutocomplete,
  createFilterOptions
} from "@material-ui/lab";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { useMuiTheme } from "model/useMuiTheme";
import { StyleVariant, TypeVariant } from "model/themeVariant";
import { KeyboardArrowDown } from "components/icons";
import { Noop, notUndefined, Optional } from "@laba/ts-common";
import { differenceWith, isEmpty, isEqual, size } from "lodash-es";
import {
  AutocompleteOption,
  AutocompleteOptionProps
} from "components/autocomplete/Autocomplete/AutocompleteOption/AutocompleteOption";
import { ButtonBase } from "components/buttons/ButtonBase/ButtonBase";
import { DefaultCustomOptionWithIcon } from "components/autocomplete/Autocomplete/DefaultCustomOptionWithIcon/DefaultCustomOptionWithIcon";
import {
  useAutocompleteEndIconStyle,
  useAutocompleteFormHelperTextStyle,
  useAutocompleteInputLabelStyle,
  useAutocompleteInputStyle,
  useAutocompleteStyles,
  useCustomClickeableOptionStyle
} from "./AutocompleteStyle";

export type AutoCompleteTypeVariant =
  | TypeVariant.Outlined
  | TypeVariant.Contained;

export interface AutocompleteOptionConfig<V> {
  text?: string;
  title?: string;
  value: V;
  primaryText?: string;
  subtext?: string;
  disabled?: boolean;
  Icon?: IconC;
  iconTextColor?: Color;
  iconText?: string;
}

interface WrappedCustomClickableOptionProps {
  wrappedCustomClickableOptionClassName?: string;
}

export type ListBoxComponentMuiProps = React.HTMLAttributes<HTMLElement>;

export type ListBoxComponentMuiType = FC<ListBoxComponentMuiProps>;

export interface AutocompleteProps<V> {
  className?: string;
  clearText?: string;
  closeText: string;
  disabled?: boolean;
  EndIcon?: IconC;
  endIconShouldNotRotate?: boolean;
  errorText?: string;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  inputValue?: string;
  label?: string;
  loading?: boolean;
  loadingText: string;
  noOptionsText: string;
  onBlur?: OnBlurEvent;
  onChange?: (val?: V) => void;
  onInputChange?: (text: string, optionChanged: boolean) => void;
  openText: string;
  options: AutocompleteOptionConfig<V>[];
  showError?: boolean;
  showHelperOrErrorText?: boolean;
  value?: V;
  StartIcon?: IconC;
  compareValues?: (v1: V, v2: V) => boolean;
  variant?: AutoCompleteTypeVariant;
  getOptionFromValue?: (value: V) => AutocompleteOptionConfig<V>;
  filterOptions?: (options: V[]) => V[];
  excludedValues?: V[];
  localSearch?: boolean;
  clearable?: boolean;
  clearOnBlur?: boolean;
  maxOptionsLimit?: number;
  AutocompleteOptionC?: FC<AutocompleteOptionProps<V>>;
  ListBoxComponentC?: ListBoxComponentMuiType;
  disableCloseOnSelect?: boolean;
  onClickCustomClickableOption?: Noop;
  CustomClickableOption?: ReactElement;
  customOptionText?: string;
  showCustomOption?: boolean;
  withoutOptionClassName?: boolean;
  EndIconClassName?: string;
  customClickableOptionClassName?: string;
  freeSolo?: boolean;
  openOptionList?: boolean;
  onClick?: Noop;
  onOpenOptionList?: Noop;
  onCloseOptionList?: Noop;
  onlyClickEvent?: boolean;
  textInputReadOnly?: boolean;
  excludeValuesWithCompareValues?: boolean;
  minCharsToShowCustomOption?: number;
  moreCharsText?: string;
  customOptionOptionsSizeMaxLimit?: number;
}

export const Autocomplete = <V,>({
  className,
  clearText = "",
  closeText,
  errorText,
  helperText,
  id,
  inputValue,
  label,
  loading,
  loadingText,
  noOptionsText,
  onBlur,
  onChange,
  onInputChange,
  openText,
  options,
  value,
  StartIcon,
  getOptionFromValue,
  ListBoxComponentC,
  disableCloseOnSelect,
  onClickCustomClickableOption,
  customOptionText,
  EndIconClassName,
  showCustomOption = false,
  excludedValues = [],
  withoutOptionClassName = false,
  AutocompleteOptionC = AutocompleteOption,
  EndIcon = KeyboardArrowDown,
  endIconShouldNotRotate = false,
  disabled = false,
  fullWidth = false,
  showError = true,
  showHelperOrErrorText = true,
  compareValues = (v1, v2) => v1 === v2,
  variant = TypeVariant.Outlined,
  localSearch = true,
  clearable = true,
  clearOnBlur = false,
  maxOptionsLimit = 50,
  excludeValuesWithCompareValues = false,
  CustomClickableOption = (
    <DefaultCustomOptionWithIcon text={customOptionText} />
  ),
  customClickableOptionClassName,
  filterOptions,
  freeSolo,
  openOptionList,
  onClick,
  onOpenOptionList,
  onCloseOptionList,
  onlyClickEvent = false,
  textInputReadOnly,
  minCharsToShowCustomOption = 3,
  moreCharsText,
  customOptionOptionsSizeMaxLimit = 50
}: AutocompleteProps<V>): ReactElement => {
  const [noOptions, setNoOptions] = useState(false);
  const [internalInputValue, setInternalInputValue] = useState("");
  const filteredOptions = useMemo(() => {
    const auxOptions =
      value !== undefined && !options.some(v => compareValues(v.value, value))
        ? [...options, getOptionFromValue?.(value)].filter(notUndefined)
        : options;

    if (!isEmpty(excludedValues)) {
      return differenceWith(auxOptions, excludedValues, (v1, v2) => {
        if (excludeValuesWithCompareValues) {
          return compareValues(v1.value, v2);
        }
        return isEqual(v1.value, v2);
      });
    }
    return auxOptions;
  }, [
    value,
    options,
    getOptionFromValue,
    excludedValues,
    compareValues,
    excludeValuesWithCompareValues
  ]);

  const findOptionWithValue = (val: V): Optional<AutocompleteOptionConfig<V>> =>
    filteredOptions.find(opt => compareValues(opt.value, val));

  const hasError = showError && Boolean(errorText);
  const showableHelperText = (hasError ? errorText : helperText) || "";
  const getOptionLabel = (val: V): string => {
    const option = findOptionWithValue(val);
    return option ? option.text ?? "" : getOptionFromValue?.(val).text ?? "";
  };
  const getOptionDisabled = (val: V) => {
    const option = findOptionWithValue(val);
    return option?.disabled ?? false;
  };
  const hasEndIcon = Boolean(EndIcon);
  const showClearable = value !== undefined;
  const endIconClasses = useAutocompleteEndIconStyle();
  const autocompleteClasses = useAutocompleteStyles({
    endIconShouldNotRotate,
    hasError,
    showClearable,
    withoutOptionClassName
  });
  const inputClasses = useAutocompleteInputStyle({
    disabled,
    hasError,
    fullWidth,
    variant
  });
  const inputLabelClasses = useAutocompleteInputLabelStyle();
  const formHelperTextClasses = useAutocompleteFormHelperTextStyle();
  const theme = useMuiTheme(StyleVariant.Primary);
  const customClickableOptionClasses = useCustomClickeableOptionStyle();

  const sizeOptions = size(options);

  const showMinCharsCustomOption =
    size(internalInputValue) >= minCharsToShowCustomOption ||
    (sizeOptions !== 0 && sizeOptions < customOptionOptionsSizeMaxLimit);

  const hasToShowCustomOption = showMinCharsCustomOption && showCustomOption;

  // eslint-disable-next-line react/no-unstable-nested-components
  const WrappedCustomClickableOption: FC<WrappedCustomClickableOptionProps> = ({
    wrappedCustomClickableOptionClassName
  }) => {
    return (
      <ButtonBase
        disableRipple
        className={getClassName(
          autocompleteClasses.option,
          wrappedCustomClickableOptionClassName
        )}
        // we use onMouseDown instead of onClick because onClick doesn't get called when
        // this button is the only option in the popover
        // see: https://stackoverflow.com/questions/60864259/material-ui-autocomplete-popper-custom-closes-on-click
        onMouseDown={onClickCustomClickableOption}
      >
        {CustomClickableOption}
      </ButtonBase>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const ListBoxComponent: ListBoxComponentMuiType = props => {
    const { children, className: ListBoxComponentClassName, ...rest } = props;
    return ListBoxComponentC === undefined ? (
      <div {...rest} className={ListBoxComponentClassName}>
        {children}
        {hasToShowCustomOption && (
          <WrappedCustomClickableOption
            wrappedCustomClickableOptionClassName={getClassName(
              customClickableOptionClasses.option,
              customClickableOptionClassName
            )}
          />
        )}
      </div>
    ) : (
      <ListBoxComponentC {...rest}>
        {children}
        {hasToShowCustomOption && (
          <WrappedCustomClickableOption
            wrappedCustomClickableOptionClassName={getClassName(
              customClickableOptionClasses.option,
              customClickableOptionClassName
            )}
          />
        )}
      </ListBoxComponentC>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const PaperComponent = (
    props: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>
  ) => {
    return (
      <div {...props}>
        <WrappedCustomClickableOption
          wrappedCustomClickableOptionClassName={
            customClickableOptionClasses.noOptions
          }
        />
      </div>
    );
  };

  const localFilterOptionFn = useCallback(
    (internalOptions: V[], _) => {
      if (localSearch) {
        const muiFilterOptions = createFilterOptions<V>({
          limit: maxOptionsLimit
        });
        const muiFilteredOptions = muiFilterOptions(internalOptions, _);
        const sizeMuiFilteredOptions = size(muiFilteredOptions);
        setNoOptions(sizeMuiFilteredOptions === 0);
        return muiFilteredOptions;
      }
      const localFilterOptions = internalOptions.slice(0, maxOptionsLimit);
      const sizeLocalFilterOptions = size(localFilterOptions);
      setNoOptions(sizeLocalFilterOptions === 0);
      return localFilterOptions;
    },
    [maxOptionsLimit, setNoOptions, localSearch]
  );

  const noOptionsTextProp =
    size(internalInputValue) < minCharsToShowCustomOption
      ? moreCharsText || noOptionsText
      : noOptionsText;

  return (
    <ThemeProvider theme={theme}>
      <FormControl className={className} error={hasError} fullWidth={fullWidth}>
        <MuiAutocomplete
          onOpen={onOpenOptionList}
          onClose={onCloseOptionList}
          open={openOptionList}
          disableClearable={!clearable}
          classes={autocompleteClasses}
          clearText={clearText}
          closeText={closeText}
          disabled={disabled}
          id={id}
          inputValue={inputValue}
          getOptionLabel={getOptionLabel}
          getOptionDisabled={getOptionDisabled}
          loading={loading}
          loadingText={loadingText}
          noOptionsText={noOptionsTextProp}
          onBlur={onBlur}
          onChange={(event: React.ChangeEvent<unknown>, val, __) => {
            // Esto es por un caso donde se llama al onChange con 'undefined' si se borra el texto de busqueda, y para
            // mi caso de uso me rompia. Creo que deberia hacerse siempre el chequeo, pero por las dudas lo cambio
            // solo para el caso de los tags
            if (onlyClickEvent && event.type !== "click") {
              return;
            }
            const option = filteredOptions.find(elem => elem.value === val);
            onChange?.(option?.value);
          }}
          onInputChange={(_event, searchText, reason) => {
            onInputChange?.(searchText, reason === "reset");
            setInternalInputValue(searchText);
          }}
          openText={openText}
          options={filteredOptions.map(option => option.value)}
          popupIcon={<EndIcon />}
          freeSolo={freeSolo}
          renderInput={params => (
            <>
              <TextField
                {...params}
                placeholder={label}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  classes: inputLabelClasses
                }}
                disabled={disabled}
                error={hasError}
                onClick={onClick}
                InputProps={{
                  ...params.InputProps,
                  classes: inputClasses,
                  ...(StartIcon && {
                    startAdornment: (
                      <InputAdornment classes={endIconClasses} position="start">
                        <StartIcon className={EndIconClassName} />
                      </InputAdornment>
                    )
                  }),
                  readOnly: textInputReadOnly
                }}
                variant="outlined"
                size="small"
              />
              {showHelperOrErrorText && !isEmpty(showableHelperText) && (
                <FormHelperText
                  error={hasError}
                  className={formHelperTextClasses.root}
                  margin="dense"
                >
                  {showableHelperText}
                </FormHelperText>
              )}
            </>
          )}
          renderOption={optionValue => {
            const option = findOptionWithValue(optionValue);
            return option && <AutocompleteOptionC option={option} />;
          }}
          value={value ?? null}
          forcePopupIcon={hasEndIcon}
          getOptionSelected={compareValues}
          clearOnBlur={clearOnBlur}
          // this prop is needed because mui filter the possible options to select
          // doing a fuzzy search with the text input content by default
          filterOptions={filterOptions ?? localFilterOptionFn}
          ListboxComponent={ListBoxComponent}
          disableCloseOnSelect={disableCloseOnSelect}
          PaperComponent={
            noOptions && hasToShowCustomOption ? PaperComponent : undefined
          }
        />
      </FormControl>
    </ThemeProvider>
  );
};
