import React from "react";
import { PopupMenuOpenIcon } from "components/icons/PopupMenuOpenIcon";
import {
  useMenuItemTextStyle,
  usePopupMenuItemStyle
} from "components/menu/MenuStyle";
import { Noop } from "@laba/ts-common";
import { MenuItem } from "@material-ui/core";
import { FC, getClassName } from "@laba/react-common";
import {
  MenuNode,
  MenuParentNode,
  MenuType
} from "components/menu/menuTreeTypes";

interface MenuDropdownItemProps {
  node: MenuNode;
  setSelectedElement: (node: MenuParentNode) => void;
  selected?: boolean;
  onClose?: Noop;
  multiline?: false;
}

export const MenuDropdownItem: FC<MenuDropdownItemProps> = ({
  node,
  setSelectedElement,
  selected,
  onClose,
  multiline = false
}: MenuDropdownItemProps) => {
  const menuItemClasses = usePopupMenuItemStyle();
  const menuItemTextClasses = useMenuItemTextStyle({ multiline });
  const { title } = node;
  const nodeOnClick: React.MouseEventHandler =
    node.type === MenuType.Parent
      ? () => setSelectedElement(node)
      : node.onClick;
  const endIcon =
    node.type === MenuType.Parent ? <PopupMenuOpenIcon /> : undefined;

  const onClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    node.type === MenuType.Leaf && onClose?.();
    return nodeOnClick(e);
  };
  const startIcon = node.type === MenuType.Leaf ? node.icon : undefined;
  return (
    <MenuItem classes={menuItemClasses} onClick={onClick} title={title}>
      {startIcon}
      <span
        className={getClassName(
          menuItemTextClasses.text,
          selected ? menuItemTextClasses.selectedItem : undefined
        )}
      >
        {title}
      </span>
      {endIcon}
    </MenuItem>
  );
};
